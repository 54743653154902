//RecordMainBody

import { Col, Row, Tabs, message } from "antd";
// import ThreeWayTable from "../Chatsummary/ThreeWayMain";
import "../../../../components/Records/CSS/record.scss";
// import "../../CSS/record.SCSS"

import {
  avatarDetails,
  setPinnedStatus,
} from "../../../Slice/reducer/chatReducer";
import { getAvatarDetails, pinnedAvatar } from "../../../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import LatestJdRecords from "./LatestJdRecords";
import { threewayagent } from "../../../api/accountAction";

const LatestThreeWayTable = () => {
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const [avatardetails, setAvatarDetails] = useState([]);
  const dispatch = useDispatch();
  const { pinnedstatus, chatmessage } = useSelector((state) => state.chat);
  const { id, conversationId } = useParams();
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [pinnedavatarstatus, setPinnedAvatarStatus] = useState(false);
  useEffect(() => {
    const input = {
      id: id,
      user_id: userName,
    };
    setPreloaderStatus(true);
    dispatch(getAvatarDetails(input))
      .unwrap()
      .then(({ data }) => {
        dispatch(avatarDetails(JSON.parse(data.aivolvex_control_plane)[0]));
        setPreloaderStatus(false);
        setAvatarDetails(JSON.parse(data.aivolvex_control_plane)[0]);
      })
      .catch((err) => {});
  }, [id]);

  // const defaultitem = [
  //   // {
  //   //   key: "3",
  //   //   label: "Pod",
  //   //   children: <Podpage />,
  //   // },
  //   // {
  //   //   key: "2",
  //   //   label: "Dashboard",
  //   //   children: <Chatbotdashboardmain />,
  //   // },
  //   {
  //     key: "1",
  //     label: "Records",
  //     children: <ThreeWayTable />,
  //   },
  // ];
  const items =[
    {
      key:"1",
      label:"Records",
      children: <LatestJdRecords />
    }
  ]
  // const setPinnedAvatar = () => {
  //   var pin_status = "pin";
  //   if (avatardetails.pinned_status === "pinned") {
  //     pin_status = "unpin";
  //   }
  //   const input = {
  //     avatar_id: id,
  //     company: companyName,
  //     user_id: userName,
  //     pin_status: pin_status,
  //     type: "public",
  //   };

  //   dispatch(pinnedAvatar(input))
  //     .unwrap()
  //     .then(({ data }) => {
  //       dispatch(setPinnedStatus(!pinnedstatus));
  //     })
  //     .catch((err) => {
  //
  //     });
  //   setPinnedAvatarStatus(!pinnedavatarstatus);
  //   // messageApi.open({
  //   //   type: "success",
  //   //   content: "This is a success message",
  //   // });
  //   message.success(
  //     pin_status === "pin" ? "Pinned Successfully" : "Unpinned Successfully",
  //     [10]
  //   );
  // };
  // const setPinnedAvatar = () => {
  //   var pin_status = "pin";
  //   var pinmessage = "Agent Pinned";
  //   if (avatardetails.pinned_status === "pinned") {
  //     pin_status = "unpin";
  //     pinmessage = "Agent Unpinned";
  //   }

  //   const input = {
  //     avatar_id: id,
  //     company: companyName,
  //     user_id: userName,
  //     pin_status: pin_status,
  //     type: "public",
  //   };
  //   getpinnedavatar(dispatch, input, pinnedstatus, pinmessage);
  // };
  return (
    <>
      {/* {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader fh-100">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : ( */}
      <>
        <Row>
          <Col span={24}>
            <Row className="volvex-chat-header">
              <Col span={24}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-start">
                    <div>
                      <img
                        src={avatardetails.avatar_image}
                        alt="avatar-img"
                        className="volvex-chat-header-avatar-logo"
                      />
                    </div>
                    <div className="volvex-chat-avatar-title">
                      <label className="aivolvex-font">
                        {avatardetails.avatar_name}
                      </label>
                      {/* <Icon
                        onClick={() => {
                          setPinnedAvatar();
                        }}
                        icon="fluent-mdl2:pinned"
                        className={
                          avatardetails.pinned_status === "pinned"
                            ? "volvex-chat-header-icon cursor-pointer pinned-color"
                            : "volvex-chat-header-icon cursor-pointer"
                        }
                      /> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="chatdashboard-record-dection chatbot-dashboard-main-body">
          <Col span={24}>
            <Tabs className="record-tab" defaultActiveKey="3" items={items}/>
          </Col> 
        </div>
      </>
      {/* )} */}
    </>
  );
};
export default LatestThreeWayTable;
