import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import DashboardCall from "./DashBoardCallCCI";
import { useDispatch, useSelector } from "react-redux";
import { UpdateWebsocket } from "../../../api/accountAction";
import {setAgentAvailable,
setWebsocketID,
setAcceptConversationID,
setAgentConnectionId,
setCustomerUserName,
setCustomerMobileNumber,
setCustomerEmailID
} from "../../../Slice/reducer/chatReducer";


const ModalPopUpCCI = () => {
    const { AgentAvailable,WebSocketID} = useSelector((state) => state.chat);
    const [callopen, setCallopen] = useState(false);




    const dispatch = useDispatch();
    const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
        ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
        : "";




    const handleCancel = () => {
        setCallopen(false);
    }

    useEffect(() => {
        openWebsocket();
    }, [])

    const InsertConnection = (connectionIdValue) => {
        const input = {
            event_type:"bot_connection",
            connection_id: connectionIdValue,
            email_id: userName,
        };

        dispatch(UpdateWebsocket(input))
            .unwrap()
            .then(({ data }) => {
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const openWebsocket = () => {
        const socket = new WebSocket(
            "wss://wduoawp287.execute-api.ap-south-1.amazonaws.com/production/"
        );
        socket.onopen = (e) => {
            socket.send(JSON.stringify({ type: "connection_init" }));
        };
        socket.onmessage = (e) => {
            const chatmessage = JSON.parse(e.data);
            dispatch(setAgentAvailable(chatmessage.connection_id));
            dispatch(setAgentConnectionId(chatmessage.agent_connection_id))
            dispatch(setAcceptConversationID(chatmessage.conversation_id))       
            dispatch(setCustomerUserName(chatmessage.user_name))       
            dispatch(setCustomerMobileNumber(chatmessage.mobile_number) )        
            dispatch(setCustomerEmailID(chatmessage.email_id))    
           
            // dispatch(setAgentAvailable("87369bnhh"));
            if (chatmessage && chatmessage.message === "Forbidden") {
                dispatch(setWebsocketID(chatmessage.connectionId))

                InsertConnection(chatmessage.connectionId)            
                // HandleRingConnection(receivedData.connectionId);
            } else if (chatmessage) {
                const value = chatmessage;
                return socket;
            }
        };
        socket.onerror = (e) => {
            console.error('WebSocket error:', e);

        };
        socket.onclose = (e) => {
            // console.log('WebSocket connection closed:', e);
            setCallopen(false);
            //   openWebsocket()
        };
        return socket;
    };

    useEffect(() => {
        if (AgentAvailable) {
            setCallopen(true);
        } else {
            setCallopen(false);
        }
    }, [AgentAvailable]);

    return (

        <>
            <div className={callopen ? 'my-custom-modal-mask' : ''}>
                <Modal
                    open={callopen}
                    // open={true}
                    footer={false}
                    className="call-pick"
                    onCancel={handleCancel}
                    width={400}>

                    <DashboardCall           
                    handleCancel={handleCancel} 
                    />
                </Modal>
            </div>

        </>
    )

}
export default ModalPopUpCCI;