import { Select } from "antd";
import Recordtablemain from "../Desktop/Recordtablemain";
import "../CSS/record.scss";
import Chatbotdashboardmain from "../Desktop/Chatbotdashboardmain";
import { useState } from "react";
import Podchatmain from "./Podchatmain";
const OPTIONS = ["Pods", "Dashboard", "Record"];
const Recordmainmobilebody = () => {
  const [tabitem, setTabitem] = useState("Pods");
  const handleChange = (value) => {
    setTabitem(value);
  };
  const filteredOptions = OPTIONS.filter((o) => !tabitem.includes(o));
  return (
    <>
      <div className="d-flex justify-content-center pt-3 public-tab">
        <Select
          defaultValue="Pods"
          style={{
            width: 160,
          }}
          onChange={handleChange}
          options={filteredOptions.map((item) => ({
            value: item,
            label: item,
          }))}
        />
      </div>
      <div className="p-2">
        {tabitem === "Pods" && (
          <>
            <Podchatmain />
          </>
        )}
      </div>
      <div className="p-2">
        {tabitem === "Dashboard" && (
          <>
            {" "}
            <Chatbotdashboardmain />
          </>
        )}
      </div>
      <div className="record-table-content">
        {tabitem === "Record" && (
          <>
            <Recordtablemain />
          </>
        )}
      </div>
    </>
  );
};
export default Recordmainmobilebody;
