import React, { useState } from "react";
import { Input } from "antd";

function TokenInput({ index, months, handleValueChange }) {
  const [tokenValue, setTokenValue] = useState(months[index].total_tokens);

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (/^[1-9]\d*$/.test(newValue)) {
      setTokenValue(newValue);
      handleValueChange(index, newValue);
    } else if (newValue === "") {
      setTokenValue("");
      handleValueChange(index, "");
    }
    // if (/^[1-9]\d*$/.test(newValue)) {
    //           setTokenValue(newValue);
    //           handleValueChange(index, newValue);
    //         }
  };

  return <Input value={tokenValue} onChange={handleInputChange} />;
}

export default TokenInput;
