import { memo, useEffect } from "react";
import { Button, Modal, Form, Input } from "antd";

const ForgotPassword = (props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [props.openforgotpassword]);
  return (
    <>
      <Modal
        title="Forgot Password"
        open={props.openforgotpassword}
        onCancel={() => {
          props.setOpenForgotPassword(false);
        }}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        <Form
          onFinish={props.onFinishForgotPasswordOTP}
          className="mt-2"
          form={form}
        >
          <Form.Item
            name="email"
            label="Email"
            className="lable-white"
            rules={[
              { required: true, message: "Please input your email!" },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              className="plat-login-button"
              loading={props.isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* forgot password change  */}
      <Modal
        title="Forgot Password"
        open={props.formforgotchangestatus}
        onCancel={() => {
          props.setFormForgotChangeStatus(false);
        }}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        <Form onFinish={props.onFinishForgotPasswordSubmit} className="mt-2">
          <Form.Item
            name="verification_code"
            label="Verification Code"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please Enter Verification Code",
              },
            ]}
          >
            <Input
              placeholder="Please Enter Verification Code"
              className="form-control signin border-0"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please Enter Password",
              },
            ]}
          >
            <Input
              type="password"
              placeholder="Please Enter Password"
              className="form-control signin border-0"
            />
          </Form.Item>

          <Form.Item
            name="confirmpassword"
            label="Confirm Password"
            dependencies={["password"]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              props.validatePassword,
            ]}
          >
            <Input
              type="password"
              placeholder="Please Enter Confirm Password"
              className="form-control signin border-0"
            />
          </Form.Item>

          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              className="plat-login-button"
              loading={props.isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ForgotPassword);
