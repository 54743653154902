import { memo, useRef, useState, useEffect } from "react";
import { Avatar, Input, Space, Button, Modal } from "antd";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { postaiassist, formatllm } from "../../../api/accountAction";
import Markdown from "react-markdown";
import { useDispatch } from "react-redux";
import Callhistory from "./Callhistory";

const PostCallAI = (props) => {
  const { conversation_id } = useParams();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [clearInput, setClearInput] = useState("");
  const [loaderstatus, setLoaderStatus] = useState(false);
  const newImageUrl = localStorage.getItem("companyloaderimage");
  const [historyOpen, setHistoryOpen] = useState(false);
  const [pcaconversationid, setPCAConversationID] = useState(generateId());
  const [iconloader, setIconLoader] = useState(false);
  const [NewMessage, setNewMessages] = useState("");

  const handleKeyPressChat = (e) => {
    if (e.key === "Enter") {
    
      // formLLM();
      sendMessage();
    }
  };
  const sendMessage = async () => {
    try {
      if (inputRef.current) {
        setLoaderStatus(true);
        var inputmessage = inputRef.current.input.value;
        inputRef.current.input.value = "";
        const newMessage = {
          type: "agent",
          message: inputmessage,
        };
      

        setClearInput(Math.random());

        setMessages((prev) => [...prev, newMessage]);

        const input = {
          event_type: "transcript_llm",
          conversation_id: conversation_id,
          chat: inputmessage,
          temp_convo_id: pcaconversationid,
        };

        dispatch(postaiassist(input))
          .unwrap()
          .then(({ data }) => {
            const records = JSON.parse(data.aivolvex_control_plane);

            const newMessageresponce = {
              type: "ans",
              message: records.ans,
            };
         

            setMessages((prev) => [...prev, newMessageresponce]);
            setLoaderStatus(false);
            setNewMessages("")
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function generateId(length = 10) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const scrollRef = useRef();

  // useEffect(() => {
  //   if (messages.length > 0) {
  //     const lastMessageEl = document.getElementById(`message-${messages.length - 1}`);
  //     if (lastMessageEl) {
  //       lastMessageEl.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessagekb = document.getElementById(`message-${messages.length - 1}`);
      if (lastMessagekb) {
        lastMessagekb.scrollIntoView({ behavior: 'smooth' });
        void lastMessagekb.offsetHeight;
      }
    }
  }, [messages]);

  const showModal = () => {
    setHistoryOpen(true);
  };
  const handleCancel = () => {
    setHistoryOpen(false);
  };
  //   const handleKB = (text) => {
  //     setKBCopy(text);
  // };
  const formLLM = () => {
    try {
      if (inputRef.current && NewMessage) {
        var inputmessage = inputRef.current.input.value
          ? inputRef.current.input.value
          : NewMessage;
        if (!inputmessage) {
          return;
        }
        setIconLoader(true);
        const input = {
          event_type: "format_llm",
          chat: inputmessage,
        };
       
        // setLoaderStatus1(true);
        setClearInput(Math.random());
        // setLoaderStatus1(true);
        dispatch(formatllm(input))
          .unwrap()
          .then(({ data }) => {
            setNewMessages(JSON.parse(data.aivolvex_control_plane).answer);
            setIconLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setIconLoader(false);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="aivolvex-dashboard-outline full-width">
      <div className="Dashboard_graph_box Dashboard_head_box_shadow postai-height">
        <div className="pca-ai-assist-title-body">
          <div>
            <h4 className="dashboard-credit aivolvex-font font-size-title">
              AI Assist Chatbot
            </h4>
          </div>
          <div className="pca-ai-assist-callhistory">
            <u>
              <span
                role="button"
                className="call-btn"
                onClick={showModal}
                onCancel={handleCancel}
              >
                Call History
              </span>
            </u>
          </div>
        </div>

        <div
          style={{ backgroundImage: `url(${newImageUrl})` }}
          className="pcacall-height pca-ai-assist"
          ref={scrollRef}
        >
          {Array.isArray(messages) ? (
            messages.map((msg, index) => (
              <div key={index}>
                {msg.message ? (
                  msg.type === "ans" ? (
                    <div id={`message-${index}`} className="d-flex justify-content-start mt-2">
                      <Avatar className="pca-chatbot-ai" size="large">
                        AI
                      </Avatar>
                      <div className="pca-ai-message1">
                        <span>{msg.message}</span>
                      </div>
                    </div>
                  ) : (
                    <div id={`message-${index}`} className="d-flex justify-content-end mt-2">
                      <div className="pca-ai-message-replay">
                        <span>
                          <Markdown>{msg.message}</Markdown>
                        </span>
                      </div>
                      <Avatar className="pca-chatbot-ai-replay" size="large">
                        A
                      </Avatar>
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center mt-2">
                    Ask Questions Regarding the Conversation
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center mt-2">
              No messages to display
            </div>
          )}
        </div>


        <div className="" style={{ width: "650px" }}>
          <Space direction="vertical" size="middle" className="">
            <Space.Compact
              style={{ width: "380px", display: "flex", gap: "4px" }}
            >
              <Input
                className=""
                key={clearInput}
                ref={inputRef}
                value={NewMessage}
                onChange={(e) => setNewMessages(e.target.value)}
                onKeyPress={handleKeyPressChat}
                style={{ borderRadius: "4px" }}
              />
              <Button
                className="format-cs"
                onClick={formLLM}
                style={{
                  borderRadius: "4px",
                }}
              >
                {!iconloader ? (
                  <>
                    {" "}
                    <Icon
                      icon="fluent:text-clear-formatting-16-regular"
                      width="1.6em"
                      height="1.6em"
                    />
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                        opacity={0.25}
                      ></path>
                      <path
                        fill="currentColor"
                        d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="0 12 12;360 12 12"
                        ></animateTransform>
                      </path>
                    </svg>
                  </>
                )}
              </Button>
              <Button
                type="primary"
                className=""
                onClick={sendMessage}
                loading={loaderstatus}

              >
                <Icon
                  icon="mdi:send-circle-outline"
                  className="pca-chatbot-send-icon"

                />
              </Button>
            </Space.Compact>{" "}
          </Space>
        </div>
      </div>
      <Modal
        open={historyOpen}
        footer={false}
        // onCancel={props.handleCancel}
        onCancel={handleCancel}
        className="call-history-modal"
        width={1000}
      >
        <Callhistory conversation_id={conversation_id} />
      </Modal>
    </div>
  );
};

export default memo(PostCallAI);