import { memo, useEffect, useState } from "react";
import { Avatar } from "antd";
import { pcaKnowledgeBaseCenter } from "../../../api/accountAction";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const PCATranscript = (props) => {
  const { conversation_id } = useParams();
  const [recordslist, setRecordsList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const input = {
      conversation_id: conversation_id,
    };

    dispatch(pcaKnowledgeBaseCenter(input))
      .unwrap()
      .then(({ data }) => {
        const chat_list = JSON.parse(data.aivolvex_control_plane);
        if (chat_list.length > 0) {
          setRecordsList(chat_list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="aivolvex-dashboard-outline full-width">
        <div className="Dashboard_graph_box Dashboard_head_box_shadow action-item">
          <h4 className="dashboard-credit aivolvex-font font-size-title">
            Knowledge Base Centre
          </h4>
          <div className="transcript-height">
            <div>
              {recordslist.length > 0 && (
                <>
                  {recordslist.map((data) => (
                    <>
                      {data.transcript_type == "ans" ? (
                        <>
                          <div className="d-flex justify-content-end mt-2">
                            <div className="pca-ai-message-replay">
                              <span>{data.transcirpt}</span>
                            </div>
                            <div>
                              <Avatar
                                className="pca-chatbot-ai-replay"
                                size="large"
                              >
                                AI
                              </Avatar>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-start mt-2">
                            <div>
                              <Avatar className="pca-chatbot-ai" size="large">
                                AA
                              </Avatar>
                            </div>
                            <div>
                              <div className="pca-ai-message">
                                <span>{data.transcirpt}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PCATranscript);
