import { memo, useEffect, useState, useRef } from "react";
import { Col, Row, Button, Modal, Input, Upload, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import AudioToText from "../Desktop/AudioToText";
import AWS from "aws-sdk";
import {
  formatCurrentTime,
  getCurrentDayAndTime,
  generateTransactionId,
  htmlRemove,
  generateRandomFourDigitNumber,
  generateUUID,
} from "../../customhook/CustomHoom";
import {
  setChatMessage,
  deleteChatMessage,
  setLibraryQuestion,
  setChatSessionID,
  setTempChatSessionID,
  setPinnedStatus,
} from "../../Slice/reducer/chatReducer";

import { useParams } from "react-router-dom";
import ChatDoc from "../Desktop/ChatDoc";
import {
  startConversation,
  pinnedAvatar,
  getEmbeddingfile,
  getFilecheck,
} from "../../api/accountAction";
import MobileChartBody from "./MobileChartBody";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});

const MobileConversationBody = (props) => {
  const {
    avatardetails,
    libraryquestion,
    chat_session_id,
    temp_chat_session_id,
    pinnedstatus,
  } = useSelector((state) => state.chat);
  const contentRef = props.contentRef;
  const { chatmessage } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const { id } = useParams();
  const childRef = useRef(null);
  const s3 = new AWS.S3();
  const currentTime = formatCurrentTime();
  const [message, setMessage] = useState("");
  const [fileupload, setFileUpload] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [messageprocessstatus, setMessageProcessStatus] = useState(true);
  const [micvisiblestatus, setMicVisibleStatus] = useState(true);
  const [docviewstatus, setDocViewStatus] = useState(false);
  const [parentState, setParentState] = useState(false);
  const handleClick = (label) => {
    props.setdrawerbottomOpen(true);
    props.setInfoname(label);
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handledocClick = (label) => {
    setDocViewStatus(true);
    setIsDropdownOpen(!isDropdownOpen);
  };
  const getStartConversation = () => {
    if (messageprocessstatus && message.trim() !== "") {
      var chatsessionid;
      var temp_chatsesssion_id = temp_chat_session_id;
      setMessageProcessStatus(false);
      if (chat_session_id !== "") {
        chatsessionid = chat_session_id;
      } else {
        chatsessionid = generateTransactionId();

        dispatch(setChatSessionID(chatsessionid));
      }

      var temp_chatsesssion_id;
      if (temp_chat_session_id !== "") {
        temp_chatsesssion_id = temp_chat_session_id;
      } else {
        temp_chatsesssion_id = generateTransactionId();
        dispatch(setTempChatSessionID(temp_chatsesssion_id));
      }

      const chatinput = htmlRemove(message);
      var chatmessage_message = chatinput;
      setMessage("");
      dispatch(setLibraryQuestion(""));
      //user question
      const old_record_lenght = chatmessage.length;
      const avatar_chat_details_user = {
        id: +old_record_lenght + +1,
        text: chatinput,
        user: true,
        date: getCurrentDayAndTime(),
        type: "message",
      };
      dispatch(setChatMessage(avatar_chat_details_user));
      //bot ans
      const new_message_id = +old_record_lenght + +2;
      const avatar_chat_details = {
        id: new_message_id,
        text: "",
        user: false,
        date: getCurrentDayAndTime(),
        type: "message",
        message_list: [],
        source: true,
        image: avatardetails.avatar_image,
        avatar_name: avatardetails.avatar_name,
        conversection_id: 0,
      };
      dispatch(setChatMessage(avatar_chat_details));
      const streaming_unique_id = generateUUID();
      childRef.current.openWebsocket(
        new_message_id,
        streaming_unique_id,
        generateUUID()
      );

      // const avatar_chat_details_loader = {
      //   text: "",
      //   user: false,
      //   date: getCurrentDayAndTime(),
      //   type: "loader",
      // };
      // dispatch(setChatMessage(avatar_chat_details_loader));

      const input = {
        user_session: chatsessionid,
        avatar_id: id,
        chat: chatmessage_message,
        schema: companyName,
        user_name: userName,
        temp_session_id: temp_chatsesssion_id,
        avatar_name: avatardetails.avatar_name,
        pod_name: avatardetails.pod,
        streaming_id: streaming_unique_id,
        jwt_token: localStorage.getItem("_token"),
      };
      //

      dispatch(startConversation(input))
        .unwrap()
        .then(({ data }) => {
          // console.log(data);
          // dispatch(deleteChatMessage());
          // const result = JSON.parse(data.aivolvex_control_plane);
          //
          // const responce = result.answer;
          // setMessageProcessStatus(true);
        })
        .catch((err) => {
          // dispatch(deleteChatMessage());
          // const avatar_chat_details = {
          //   text: "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?",
          //   user: false,
          //   date: getCurrentDayAndTime(),
          //   type: "message",
          //   image: avatardetails.avatar_image,
          // };
          // dispatch(setChatMessage(avatar_chat_details));
          // setMessageProcessStatus(true);
        });
    }
  };
  // function generateTransactionId() {
  //     const timestamp = Date.now().toString();
  //     const randomNum = Math.floor(Math.random() * 10000)
  //         .toString()
  //         .padStart(4, "0");
  //     return `${timestamp}-${randomNum}`;
  // }
  const handleKeyPress = (event) => {
    if (message.length > 0) {
      if (event.key === "Enter") {
        event.preventDefault();
        getStartConversation();
      }
    }
  };
  useEffect(() => {
    setMessage(libraryquestion);
  }, [libraryquestion]);
  const items = [
    {
      key: "1",
      label: (
        <>
          <div onClick={() => handleClick("info")}>
            <div>
              <Icon icon="ph:info" style={{ fontSize: "24px" }} />
            </div>
            <div>Info</div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleClick("library")}>
          <div>
            <Icon icon="bi:bookmark" style={{ fontSize: "24px" }} />
          </div>
          <div>Library</div>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={() => handleClick("trending")}>
          <div>
            <Icon
              icon="streamline:interface-content-fire-lit-flame-torch-trending"
              style={{ fontSize: "24px" }}
            />
          </div>
          <div>Trending</div>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div onClick={() => handleClick("history")}>
          <div>
            <Icon icon="solar:history-bold" style={{ fontSize: "24px" }} />
          </div>
          <div>History</div>
        </div>
      ),
    },
    // {
    //     key: '5',
    //     label: (
    //         <>
    //             <div onClick={() => handleClick('share')}><Icon icon="material-symbols:share-outline" style={{ fontSize: '24px' }} /></div>
    //             <div>
    //                 Share
    //             </div>
    //         </>
    //     ),
    // },
    {
      key: "6",
      label: (
        <div onClick={() => handledocClick("document")}>
          {avatardetails.type === "kendra" ||
          avatardetails.type === "image" ||
          avatardetails.type === "prometheus" ||
          avatardetails.type === "pgvector" ? (
            <div>
              <div>
                <Icon icon="ph:folder-duotone" style={{ fontSize: "24px" }} />
              </div>
              <div>Doc</div>
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  function generateUniqueID() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const randomFourDigitNumber = generateRandomFourDigitNumber();
    return `${year}${month}${day}-${randomFourDigitNumber}`;
  }
  let timer;
  const handleCancel = () => {
    setFileModalOpen(false);
    setSelectedFiles([]);
  };

  const handleDropdownVisibleChange = (visible) => {
    setIsDropdownOpen(visible);
  };
  const stopTimer = () => {
    clearInterval(timer);
  };
  // const handleFileUpload = (event) => {
  //   const files = selectedFiles.map((fileInfo) => ({
  //     file: fileInfo,
  //   }));
  //   const selectedFile = files;
  //   setFileUpload(selectedFile);
  //   handlefileOk(selectedFile, temp_chat_session_id);
  //   setFileModalOpen(false);
  // };
  const handleNoResponse = (uniqueID) => {
    let timerId;
    timerId = setInterval(async () => {
      try {
        const input = {
          user_session: temp_chat_session_id,
          company: companyName,
          unique_id: uniqueID,
        };
        const { data } = await dispatch(getFilecheck(input)).unwrap();
        if (data) {
          dispatch(deleteChatMessage());
          const avatar_chat_details = {
            text: "File analysis is complete! 'You can now inquire about the file's contents...",
            user: false,
            date: getCurrentDayAndTime(),
            type: "message",
            image: avatardetails.avatar_image,
            message_list: [],
          };
          setMessageProcessStatus(true);
          dispatch(setChatMessage(avatar_chat_details));
          clearInterval(timerId);
        }
      } catch (error) {
        console.error("Error checking file status:", error);
      }
    }, 15000);
  };
  const handleFileUpload = (event) => {
    const files = selectedFiles.map((fileInfo) => ({
      file: fileInfo,
    }));
    const selectedFile = files;
    setFileUpload(selectedFile);
    handlefileOk(selectedFile, temp_chat_session_id);
    setFileModalOpen(false);
    setSelectedFiles([]);
  };
  const handlefileOk = (selectedFile, temp_chat_session_id) => {
    const uniqueID = generateUniqueID();
    const params = selectedFile.map((fileDetail) => ({
      // Bucket: "1ch-dev-embedding-input-bucket",
      Bucket: avatardetails.bucket_name,
      Key: `${temp_chat_session_id}/${uniqueID}/${fileDetail.file.name}`,
      Body: fileDetail.file.originFileObj,
    }));
    stopTimer();
    handleAWSFileUpload(params, uniqueID, temp_chat_session_id);
  };
  const handleAWSFileUpload = async (
    params,
    uniqueID,
    temp_chat_session_id
  ) => {
    try {
      const uploadPromises = params.map(async (param) => {
        await s3.upload(param).promise();
      });
      setMessageProcessStatus(false);
      const avatar_chat_details = {
        text: "File Uploaded Successfully! currently processing the file. Please wait while it's being analyzed..",
        user: false,
        date: getCurrentDayAndTime(),
        type: "message",
        image: avatardetails.avatar_image,
        message_list: [],
      };
      dispatch(setChatMessage(avatar_chat_details));

      const avatar_chat_details_loader = {
        text: "",
        user: false,
        date: getCurrentDayAndTime(),
        type: "loader",
      };
      dispatch(setChatMessage(avatar_chat_details_loader));
      const input = {
        user_session: temp_chat_session_id,
        company: companyName,
        unique_id: uniqueID,
        avatar_id: id,
        user_id: userName,
      };
      setTimeout(() => {
        dispatch(getEmbeddingfile(input))
          .unwrap()
          .then(({ data }) => {
            dispatch(deleteChatMessage());
            const value = JSON.parse(data.aivolvex_control_plane);
            const avatar_chat_details = {
              text: "File analysis is complete! 'You can now inquire about the file's contents...",
              user: false,
              date: getCurrentDayAndTime(),
              type: "message",
              image: avatardetails.avatar_image,
              message_list: [],
            };
            setMessageProcessStatus(true);
            dispatch(setChatMessage(avatar_chat_details));
          })
          .catch((err) => {
            handleNoResponse(uniqueID);
          });
      }, 2000);
    } catch (error) {
      console.error("Error uploading files to S3:", error);
    }
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Add a scroll event listener to show/hide the button based on scroll position
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      setIsVisible(scrolled > 300); // You can adjust the threshold as needed
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="conversation-mobile">
        {/* <Row className="chart-message-part-mobile chart-part-mobile"> */}
        <Row>
          <Col span={24}>
            <MobileChartBody
              ref={childRef}
              setMessageProcessStatus={setMessageProcessStatus}
              setParentState={setParentState}
            />
          </Col>
        </Row>

        <div className="chat-fixed-bottom ">
          {id && (
            <>
              <Row
                gutter={[8, 8]}
                className="chat-bottom-input-footer d-flex align-items-end"
              >
                <Col
                  // md={20} sm={17} xs={17}
                  md={23}
                  sm={21}
                  xs={21}
                >
                  <div className="chat-input-type">
                    <Input.TextArea
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      autoSize={{ minRows: 1 }}
                      className="chat-input-group"
                      onKeyPress={handleKeyPress}
                    />
                    {avatardetails.type === "prometheus" ||
                    avatardetails.type === "image" ? (
                      <label
                        htmlFor="file-input"
                        className="custom-file-upload d-flex align-items-center"
                      >
                        <span
                          className="upload-icon volvex-submit-btn-icon"
                          onClick={() => setFileModalOpen(true)}
                        >
                          <Icon icon="subway:upload-2" />
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 35 35"
                            fill="none"
                          >
                            <path
                              d="M9.39779 12.8066H12.9398V23.3141C12.9398 24.3802 13.8042 25.2435 14.8692 25.2435H20.1355C21.2004 25.2435 22.0649 24.3803 22.0649 23.3141V12.8066H25.6057C26.1923 12.8066 26.7196 12.4534 26.9452 11.9113C27.1684 11.3715 27.0442 10.7473 26.6298 10.3329L18.5262 2.22831C17.9602 1.66348 17.0433 1.66348 16.4761 2.22831L8.37391 10.3329C7.95937 10.7473 7.83517 11.3704 8.0596 11.9113C8.28502 12.4524 8.81119 12.8066 9.39779 12.8066Z"
                              fill="white"
                            />
                            <path
                              d="M33.2597 16.7449C32.2995 16.7449 31.5206 17.5239 31.5206 18.4851V25.7617C31.5206 27.9415 29.7461 29.7148 27.5662 29.7148H7.43365C5.25376 29.7148 3.48049 27.9415 3.48049 25.7617V18.4851C3.48049 17.5239 2.70036 16.7449 1.74024 16.7449C0.778989 16.7449 0 17.5239 0 18.4852V25.7618C0 29.8606 3.33467 33.1954 7.43365 33.1954H27.5662C31.6652 33.1954 35 29.8607 35 25.7618V18.4852C35 17.5239 34.2209 16.7449 33.2597 16.7449Z"
                              fill="white"
                            />
                          </svg> */}
                        </span>
                      </label>
                    ) : (
                      <></>
                    )}
                    {micvisiblestatus && (
                      <AudioToText
                        setMessage={setMessage}
                        setMicVisibleStatus={setMicVisibleStatus}
                        micvisiblestatus={micvisiblestatus}
                      />
                    )}
                    <button
                      onClick={() => getStartConversation()}
                      className="chat-input-group"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 41 41"
                        fill="none"
                        className="volvex-submit-btn-icon"
                      >
                        <path
                          d="M3.84375 35.875L37.1562 20.5L3.84375 5.125V17.0833L27.707 20.5L3.84375 23.9167V35.875Z"
                          // fill="white"
                        />
                      </svg>
                    </button>

                    <div> </div>
                  </div>
                </Col>
                {/* <Col md={2} sm={4} xs={4}>
                                <Button size="small" className="chat-cancel-icon chat-bottom-button">
                                    10{" "}
                                    <img
                                        src={aivolvex}
                                        alt="Image" 
                                        className="token-footer-logo aivolvex-font"
                                    />
                                </Button>
                            </Col> */}
                <Col
                  // md={2} sm={3} xs={3}
                  md={1}
                  sm={3}
                  xs={3}
                  className="chat-bottom-drop"
                >
                  {id ? (
                    <>
                      <Dropdown
                        className="chat-bottom-drop"
                        menu={{
                          items,
                        }}
                        placement="topRight"
                        onVisibleChange={handleDropdownVisibleChange}
                        trigger={["click"]}
                      >
                        {isDropdownOpen ? (
                          <Button
                            size="small"
                            className="chat-add-icon-mobile chat-bottom-add chat-bottom-button "
                          >
                            <Icon
                              icon="ph:x"
                              className="chat-icon-inner-button"
                            />
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            className="chat-cancel-icon chat-bottom-add chat-bottom-button "
                          >
                            <Icon
                              icon="octicon:plus-16"
                              className="chat-icon-inner-button"
                            />
                          </Button>
                        )}
                      </Dropdown>
                    </>
                  ) : (
                    <>
                      <Button
                        size="small"
                        className="chat-cancel-icon chat-bottom-add chat-bottom-button "
                      >
                        <Icon
                          icon="octicon:plus-16"
                          className="chat-icon-inner-button"
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>

      <Modal open={fileModalOpen} footer={false} onCancel={handleCancel}>
        <>
          <Upload
            multiple
            fileList={selectedFiles}
            className="upload-file-pdf"
            onChange={(info) => {
              if (info.file.type === "application/pdf") {
                setSelectedFiles(info.fileList);
                setErrorMessage("");
              } else {
                setErrorMessage("Only PDF files are allowed for upload.");
              }
            }}
          >
            <Button className="select-file">
              <Icon icon="line-md:upload-loop" className="upload-file-icon" />
              Select Files
            </Button>
            <br />
          </Upload>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <br />
          {selectedFiles.length > 0 && (
            <Button
              onClick={handleFileUpload}
              type="primary"
              className="upload-file "
            >
              {selectedFiles.length > 1 ? "Upload Files" : "Upload File"}
            </Button>
          )}
        </>
      </Modal>
      <Modal
        title="Document List"
        open={docviewstatus}
        onCancel={() => {
          setDocViewStatus(false);
        }}
        footer={false}
        width="70%"
        className="view-src"
      >
        <ChatDoc />
      </Modal>
    </>
  );
};
export default MobileConversationBody;
