import { Row, Col, Tag, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { capitalizeWords } from "../../customhook/CustomHoom";
import MarkdownToJSX from "markdown-to-jsx";
import MainStyle from "../../../MainStyle.scss";
import PopConfirmJDQa from "./PopConfirmJDQa";
const JDdetails =(props)=>{
    const fontcolor = MainStyle["theme-font-primary"];
  const data = props.records;
    return(
        <>
         <Row style={{display:"flex",justifyContent:"center"}}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Candidate Name:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.name)}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Type:
            </Col>
            <Col span={12} className="white-font question-font">
              <Tag
                color={
                  data.typeofconversation === "email_chat"
                    ? "blue"
                    : data.typeofconversation === "voice"
                    ? "purple"
                    : "default"
                }
              >
                {capitalizeWords("Email")}
              </Tag>
            </Col>
          </Row>
          
            
              <Row className="p-2">
                <Col span={12} className="puple-font question-font">
                 Skill Evalution
                </Col>
                <Col span={12}>
                  <div>
                    {(() => {
                      let iconColor, iconName, textColor;
                      switch (data.selection_status) {
                        case "Rejected":
                          iconColor = "red";
                          iconName = "ion:sad";
                          textColor = "red";
                          break;
                        case "Selected":
                          iconColor = "#0BB852";
                          iconName = "mdi:smiley";
                          textColor = "#0BB852";
                          break;
                        default:
                          iconColor = "#fff";
                          iconName = "ph:smiley-fill";
                      }

                      return (
                        <Popconfirm
                          className="record-dashboard-popup"
                          placement="top"
                          title=""
                          description=<PopConfirmJDQa content={data.qa_content} />
                          footer={false}
                          okText=""
                          cancelText=""
                          cancelButtonProps={{ style: { display: "none" } }}
                          okButtonProps={{ style: { display: "none" } }}
                        >
                          <>
                            <span className="cursor">
                              <Icon
                                icon={iconName}
                                style={{ color: iconColor, fontSize: "24px" }}
                              />
                            </span>
                            <span
                              style={{ color: textColor }}
                              className="smiley-text cursor"
                            >   
                              {capitalizeWords(data.selection_status)}
                            </span>
                          </>
                        </Popconfirm>
                      );
                    })()}
                  </div>
                </Col>
              </Row>
              <Row className="p-2">
            <Col span={12} className="puple-font question-font">    
              Service:
            </Col>
            <Col span={12} className="white-font question-font">
              {data.service}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">
              Service category:
            </Col>
            <Col span={12} className="white-font question-font">
              {capitalizeWords(data.category)}
            </Col>
          </Row>
          <Row className="p-2">
            <Col span={12} className="puple-font question-font">    
              Resume:
            </Col>
            <Col span={12} className="white-font question-font">
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              {data.url ? (`${data.url.slice(0, 15)}...`):("")}
              </a>
            </Col>
          </Row>
        </Col>
        </Row>
        </>
    )
}
export default JDdetails;