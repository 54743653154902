import { Icon } from "@iconify/react";
import { Col, Modal, Popconfirm, Row, Tabs } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import chart_icon from "../../../assets/icons/chart.svg";
import eye_icon from "../../../assets/icons/eye.svg";
import message_icon from "../../../assets/icons/message.svg";
import star_icon from "../../../assets/icons/star.svg";
import token_icon from "../../../assets/icons/token.svg";
import { getAvatarInfoDetails } from "../../api/accountAction";
import ChatDoc from "../Desktop/ChatDoc";
import HistoryList from "./HistoryList";
import LibraryList from "./LibraryList";
import SharedForm from "./SharedForm";
import TrendingList from "./TrendingList";
import { simplifyNumber } from "../../customhook/CustomHoom";

const ChatRightBar = () => {
  const { avatardetails, chatmessage } = useSelector((state) => state.chat);
  const [docviewstatus, setDocViewStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      <Row className="volvex-chat-right-bar">
        {/* <Col span={24} className="text-center cursor-pointer">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopconfirmSharedDescription />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="solar:share-outline" className="about-header-icon" />
            </div>
            <div className="right-bar-font">Share</div>
          </Popconfirm>
        </Col> */}
        {/* <Col span={24} className="text-center cursor-pointer">
          <div className="volvex-chat-right-bar-border"></div>
        </Col> */}
        <Col span={24} className="text-center cursor-pointer mt-1">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopconfirmAboutDescription />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="ph:info" className="about-header-icon" />
            </div>
            <div className="right-bar-font">Agent Info</div>
          </Popconfirm>
        </Col>
        <Col span={24} className="text-center cursor-pointer mt-3">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopConfirmTemplate type="library" />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="bi:bookmark" className="about-header-icon-library" />
            </div>
            <div className="right-bar-font">Library</div>
          </Popconfirm>
        </Col>
        <Col span={24} className="text-center cursor-pointer mt-3">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopConfirmTemplate type="trending" />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon
                icon="streamline:interface-content-fire-lit-flame-torch-trending"
                className="about-header-icon"
              />
            </div>
            <div className="right-bar-font">Trending</div>
          </Popconfirm>
        </Col>
        <Col span={24} className="text-center cursor-pointer mt-3">
          <Popconfirm
            placement="leftTop"
            title=""
            description={<PopConfirmTemplate type="history" />}
            okText=""
            cancelText=""
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            className="volvex-about-popconfirm"
          >
            <div>
              <Icon icon="solar:history-bold" className="about-header-icon" />
            </div>
            <div className="right-bar-font">History</div>
          </Popconfirm>
        </Col>
        {avatardetails.type === "kendra" ||
        avatardetails.type === "image" ||
        avatardetails.type === "prometheus" ||
        avatardetails.type === "pgvector" ? (
          <Col span={24} className="text-center cursor-pointer mt-3">
            {avatardetails.id !== 718 && (
              <div
                onClick={() => {
                  setDocViewStatus(true);
                }}
              >
                <div>
                  <Icon
                    icon="ph:folder-duotone"
                    className="about-header-icon"
                  />
                  {/* <Icon icon="uiw:document" className="about-header-icon" /> */}
                </div>
                <div className="right-bar-font">Folder</div>
              </div>
            )}
          </Col>
        ) : null}

        {/* <Col span={24} className="text-center cursor-pointer mt-3">
          <div>
            <Icon icon="heroicons:users" className="about-header-icon" />
          </div>
          <div className="right-bar-font">Shared</div>
        </Col> */}
      </Row>

      <Modal
        title="Document List"
        open={docviewstatus}
        onCancel={() => {
          setDocViewStatus(false);
          setSearchQuery("");
        }}
        footer={false}
        width="70%"
        // className="view-src"
        className="doc-list"
      >
        <ChatDoc searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Modal>
    </>
  );
};

const PopconfirmSharedDescription = () => {
  return (
    <>
      <div className="volvex-shared-form">
        <Row>
          <Col span={24} className="volvex-shared-form-title-body">
            <label className="volvex-shared-form-title ">
              {" "}
              <Icon
                icon="solar:share-outline"
                className="volvex-info-header-icon"
              />{" "}
              Share With
            </label>
          </Col>
          <Col span={24}>
            {" "}
            <Tabs
              className="aivolvex-bot-tab aivolvex-font"
              items={[
                {
                  key: 1,
                  label: (
                    <>
                      <Icon
                        icon="fluent:tag-20-regular"
                        className="prompt-title-icon"
                      />{" "}
                      Tags
                    </>
                  ),
                  children: <SharedForm type="tag" />,
                  className: "plat-dashboard-link",
                },
                {
                  key: 2,
                  label: (
                    <>
                      <Icon
                        icon="heroicons-outline:user-group"
                        className="prompt-title-icon"
                      />{" "}
                      Members
                    </>
                  ),
                  children: <SharedForm type="member" />,
                  className: "plat-dashboard-link",
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const PopconfirmAboutDescription = () => {
  const [viewallstatus, setViewAllStatus] = useState(true);
  const [agentinfo, setAgentInfo] = useState({
    token: 0,
    rating: 0,
    current_month_prompt: 0,
    total_prompt: 0,
    different_prompt: 0,
  });
  const { avatardetails } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const { id, conversationId } = useParams();
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  useEffect(() => {
    const input = {
      avatar_id: id,
      user_id: userName,
    };
    dispatch(getAvatarInfoDetails(input))
      .unwrap()
      .then(({ data }) => {
        //
        //
        var records = JSON.parse(data.aivolvex_control_plane);
        setAgentInfo({
          token: records.total_tokens ? records.total_tokens : 0,
          rating: records.avg_rating ? records.avg_rating : 0,
          current_month_prompt: records.current_question_count
            ? records.current_question_count
            : 0,
          total_prompt: records.total_question ? records.total_question : 0,
          different_prompt: records.trend ? records.trend : 0,
        });
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [id]);
  return (
    <>
      <div className="volvex-about-body">
        <Row>
          <Col span={24}>
            <div className="volvex-about-avatar-logo-body">
              <img
                src={avatardetails.avatar_image}
                alt="avatar-img"
                className="volvex-about-avatar-logo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="volvex-about-avatar-title">
              {avatardetails.avatar_name}
            </label>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="d-flex justify-content-center">
            <div className="avatar-info-icons">
              <span>
                <img src={token_icon} alt="token" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">
                {simplifyNumber(agentinfo.token)}
              </span>
            </div>
            <div className="avatar-info-icons margin-left-1">
              <span>
                <img src={eye_icon} alt="eye" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">
                {simplifyNumber(agentinfo.current_month_prompt)}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <div
                className={
                  avatardetails.about_us?.length > 250
                    ? viewallstatus
                      ? "side-scroll-container-shadow"
                      : ""
                    : ""
                }
                // className="side-scroll-container-shadow"
              >
                <div class="job-description word-wrap colorSecondary small-text white-color aivolvex-font mt-3 side-scroll-container">
                  {viewallstatus ? (
                    <>
                      {avatardetails.about_us?.length > 250 ? (
                        <>
                          {avatardetails.about_us?.substring(0, 250)}
                          ...{" "}
                        </>
                      ) : (
                        <>{avatardetails.about_us}</>
                      )}
                    </>
                  ) : (
                    <>{avatardetails.about_us}</>
                  )}
                </div>
                <div className="text-right right-padding-2">
                  {viewallstatus && (
                    <>
                      {avatardetails.about_us?.length > 250 && (
                        <>
                          <span
                            className="avatar-view-more"
                            onClick={() => setViewAllStatus(false)}
                          >
                            Read more...
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="volvex-about-token-list">
          <Col span={8}>
            <div className="avatar-info-icons">
              <span>
                <img
                  src={message_icon}
                  alt="message"
                  className="avatar-info-img"
                />
              </span>
              <span className="info-icon-text">
                {simplifyNumber(agentinfo.total_prompt)}
              </span>
            </div>
          </Col>
          <Col span={8}>
            <div className="avatar-info-icons">
              <span>
                <img src={star_icon} alt="star" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">{agentinfo.rating}/5</span>
            </div>
          </Col>
          {/* <Col span={6}>
            <div className="avatar-info-icons">
              <span>
                <img src={price_icon} alt="price" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">$12000</span>
            </div>
          </Col> */}
          <Col span={8}>
            <div className="avatar-info-icons">
              <span>
                <img src={chart_icon} alt="chart" className="avatar-info-img" />
              </span>
              <span className="info-icon-text">
                {agentinfo.different_prompt}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

const PopConfirmTemplate = (props) => {
  return (
    <>
      <div className="volvex-shared-form">
        <Row>
          <Col span={24} className="volvex-shared-form-title-body">
            <label className="volvex-shared-form-title ">
              {props.type === "library" && (
                <>
                  <Icon
                    icon="bi:bookmark"
                    className="volvex-info-header-icon"
                  />
                  Library
                </>
              )}
              {props.type === "trending" && (
                <>
                  <Icon
                    icon="streamline:interface-content-fire-lit-flame-torch-trending"
                    className="volvex-info-header-icon"
                  />
                  Trending
                </>
              )}
              {props.type === "history" && (
                <>
                  <Icon
                    icon="solar:history-bold"
                    className="volvex-info-header-icon"
                  />
                  History
                </>
              )}
            </label>
          </Col>
          <Col span={24}>
            {props.type === "library" && (
              <>
                <LibraryList />
              </>
            )}
            {props.type === "trending" && (
              <>
                <TrendingList />
              </>
            )}
            {props.type === "history" && (
              <>
                <HistoryList />
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(ChatRightBar);
