import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

const SideScroll = (props) => {
  const contentRef = useRef(null);
  const [showUpIcon, setShowUpIcon] = useState(false);
  const [showDownIcon, setShowDownIcon] = useState(false);
  const { chatmessage } = useSelector((state) => state.chat);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setShowUpIcon(scrollTop > 0);
        //
        setShowDownIcon(scrollTop + clientHeight < scrollHeight);
      }
    };
    handleScroll(); // Initial check before any scrolling
    contentRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      contentRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      //
      scrollToBottomSmoothly();
    }
  }, [chatmessage]);

  const scrollToBottomSmoothly = () => {
    //
    if (contentRef.current) {
      const scrollHeight = contentRef.current.scrollHeight;
      const start = contentRef.current.scrollTop;
      const change = scrollHeight - start;
      const duration = 500; // milliseconds for animation
      const startTime = performance.now();
      const animateScroll = (timestamp) => {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        if (contentRef.current) {
          contentRef.current.scrollTop = start + change * progress;
        }
        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  };

  const handleScrollToBottom = () => {
    scrollToBottomSmoothly();
  };

  return (
    <div className="side-scroll-container-chat">
      <div className="side-scroll-icons">
        {showDownIcon && (
          <Icon
            icon="mdi:arrow-down-bold-box-outline"
            className="scroll-icon scroll-icon-down-chat-scroll"
            onClick={handleScrollToBottom}
          />
        )}
      </div>
      <div className="side-scroll-content" ref={contentRef}>
        {props.children}
      </div>
    </div>
  );
};

export default SideScroll;
