import { Col, Row, Tabs } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import preloader from "../../../assets/loader/pre_loader.gif";
import TagMemberConfig from "../Desktop/TagMemberConfig";
import "../CSS/rolehome.scss";
import TagAvatarConfig from "../Desktop/TagAvatarConfig";
import { listTagging, getAvatarList } from "../../api/accountAction";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
const Taghomemobile = () => {
  const [basicActive, setBasicActive] = useState("0");
  const [datachangestatus, setDataChangeStatus] = useState(true);
  const [preloaderstatus, setPreLoaderStatus] = useState(true);
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const [avatarlist, setAvatarList] = useState([]);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const { agentname } = useSelector((state) => state.chat);
  useEffect(() => {
    setPreLoaderStatus(true);
    const input = {
      compnayname: companyName,
    };
    dispatch(listTagging(input))
      .unwrap()
      .then(({ data }) => {
        setPreLoaderStatus(false);
        const responce = JSON.parse(data.aivolvex_control_plane);

        const resultArray = responce.map((item) => ({
          id: item.id,
          title: item.tag_title,
          members_list: splitMembers(item.member_list),
          avatar_list: item.avatar_name.map((email, index) => ({
            avatar_id: index + 1,
            avatar: email,
          })),
        }));
        setRecords(resultArray);
      })
      .catch((err) => {});
  }, [datachangestatus]);

  useEffect(() => {
    const input = {
      companyName: companyName,
    };
    dispatch(getAvatarList(input))
      .unwrap()
      .then(({ data }) => {
        const record = JSON.parse(data.aivolvex_control_plane);
        const newAvatars = record.filter(
          (avatar) => avatar.agent_type == "private"
        );
        setAvatarList(newAvatars);
      })
      .catch((err) => {});
  }, []);

  function splitMembers(members) {
    return members.split(",").map((email, index) => ({
      members_id: index + 1,
      members_email: email,
    }));
  }

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const items = [
    {
      key: 1,
      label: "Members",
      children: (
        <TagMemberConfig
          records={records}
          datachangestatus={datachangestatus}
          setDataChangeStatus={setDataChangeStatus}
          avatarlist={avatarlist}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(1),
      active: basicActive === 1,
    },
    {
      key: 2,
      label: "Agents",
      children: (
        <TagAvatarConfig
          records={records}
          avatarlist={avatarlist}
          datachangestatus={datachangestatus}
          setDataChangeStatus={setDataChangeStatus}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(2),
      active: basicActive === 2,
    },
  ];
  return (
    <>
      <Row>
        <Col span={24}>
          <Navbarmobile />
        </Col>
      </Row>
      <Row className="mobile-background">
        <Col
          span={24}
          className="white-color aivolvex-font d-flex justify-content-center p-1"
        >
          <h4>{agentname}</h4>
        </Col>
      </Row>
      <Row className="recent-mobile-body tag-mobile-body .mobile-background">
        <Col span={24} className="text-tabs">
          {preloaderstatus ? (
            <div className="aivolvex-loader">
              <img src={preloader} width="30%" />
            </div>
          ) : (
            // <Row>
            //     <Col span={24}>
            <Tabs
              className="tag-tabs aivolvex-font"
              items={items}
              onChange={handleBasicClick}
            />
            //     </Col>
            // </Row>
          )}
        </Col>
      </Row>
      <Row className="chat-bottom-section">
        <Col span={24}>
          <SidemenuMobile />
        </Col>
      </Row>
    </>
  );
};
export default Taghomemobile;
