import { Col, Row, Card } from "antd";
import { useEffect, useState } from "react";
import { getAvatarDetails } from "../api/accountAction";
import { useDispatch } from "react-redux";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

const AvatorSingle = (props) => {
  const dispatch = useDispatch();
  const [avatardetails, setAvatarDetails] = useState([]);
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  useEffect(() => {
    const id = props.data.id;
    dispatch(getAvatarDetails(id))
      .unwrap()
      .then(({ data }) => {
        setAvatarDetails(JSON.parse(data.aivolvex_control_plane)[0]);
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [props]);
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="avator-list filterResultCard">
          <div className="filter-avatar-img">
            <img
              src={avatardetails.avatar_image}
              alt=""
              className="companyLogoImage"
            />
          </div>
          <div className="filterResultCard-bg">
            <Row>
              <Col span={24}>
                <div className="avatar-list-token-body">
                  {tag_id === "all" ? (
                    <>
                      <img
                        src={aivolvex}
                        alt="logo"
                        className="token-footer-logo"
                      />{" "}
                      <span className="avatar-list-token aivolvex-font">
                        20
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="token-footer-logo"></span>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className={tag_id === "all" ? "mt-3" : "mt-5"}>
              <Col span={24} className="mt-2">
                <div>
                  <p class="labelStyle-title aivolvex-font">
                    {props.data.avatar_name}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mt-2">
                <div>
                  <p class="labelStyle aivolvex-font">
                    {props.data.about_us.length > 200 ? (
                      <>{props.data.about_us.substring(0, 200)}...</>
                    ) : (
                      <>{props.data.about_us}</>
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-center library-btn-position"
              >
                <button
                  class="button-primary btn btn-primary-new fontSize10"
                  role="button"
                  onClick={() => props.navigateAvatar(props.data.id)}
                >
                  Explore
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      {/* <Col span={12}>
        <Card className="avator-list filterResultCard">
          <Row className="avatar-body-height">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <img
                src={avatardetails.avatar_image}
                alt=""
                className="companyLogoImage"
              />
            </Col>
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
              <p class="labelStyle-title aivolvex-font">
                {props.data.avatar_name}
              </p>
              <p class="labelStyle aivolvex-font">
                {props.data.about_us.length > 250 ? (
                  <>{props.data.about_us.substring(0, 250)}...</>
                ) : (
                  <>{props.data.about_us}</>
                )}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-right">
              <button
                class="button-primary btn btn-primary-new fontSize10"
                role="button"
                onClick={() => props.navigateAvatar(props.data.id)}
              >
                Explore
              </button>
            </Col>
          </Row>
        </Card>
      </Col> */}
    </>
  );
};

export default AvatorSingle;
