import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LibraryList from "../Desktop/LibraryList";
import TrendingList from "../Desktop/TrendingList";
import { Icon } from "@iconify/react";
import HistoryList from "../Desktop/HistoryList";
import ChatDoc from "../Desktop/ChatDoc";
const Mobilebottominfo = (props) => {
  const handleclose = () => {
    props.setdrawerbottomOpen(false);
  };
  const [docviewstatus, setDocViewStatus] = useState(false);
  // const [viewallstatus, setViewAllStatus] = useState(true);
  const { avatardetails } = useSelector((state) => state.chat);
  //
  let content;
  let iconcontent;
  let bodycontent;
  if (props.infoname === "info") {
    content = "Avatar Info";
    iconcontent = "ph:info";
    bodycontent = <Agentinfo />;
  } else if (props.infoname === "library") {
    content = "Library";
    iconcontent = "bi:bookmark";
    bodycontent = <LibraryList handleclose={handleclose} />;
  } else if (props.infoname === "trending") {
    content = "Trending";
    bodycontent = <TrendingList handleclose={handleclose} />;
    iconcontent = "streamline:interface-content-fire-lit-flame-torch-trending";
  } else if (props.infoname === "history") {
    content = "History";
    iconcontent = "solar:history-bold";
    bodycontent = <HistoryList handleclose={handleclose} />;
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row className="chat-header-logo-title chat-info-drawer-row-bottom">
            <Col span={4} className="d-flex align-items-center">
              <Icon icon={iconcontent} style={{ fontSize: "24px" }} />
            </Col>
            <Col span={18} className="d-flex align-items-center">
              {content}
            </Col>
            <Col span={2} className="d-flex align-items-center">
              <Button
                size="small"
                className="chat-add-icon"
                onClick={() => handleclose()}
              >
                <Icon icon="octicon:x-16" className="chat-icon-inner-button" />
              </Button>
            </Col>
          </Row>
          <Row>{bodycontent}</Row>
        </Col>
      </Row>
    </>
  );
};
const Agentinfo = () => {
  const [viewallstatus, setViewAllStatus] = useState(true);
  const { avatardetails } = useSelector((state) => state.chat);
  return (
    <>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <div className="volvex-about-avatar-logo-body">
              <img
                src={avatardetails.avatar_image}
                alt="avatar-img"
                className="volvex-about-avatar-logo-mobile"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="volvex-about-avatar-title">
              {avatardetails.avatar_name}
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <div
                className={viewallstatus ? "side-scroll-container-shadow" : ""}
              >
                <div class="job-description word-wrap colorSecondary small-text white-color aivolvex-font mt-3 side-scroll-container">
                  {viewallstatus ? (
                    <>
                      {avatardetails.about_us?.length > 380 ? (
                        <>
                          {avatardetails.about_us?.substring(0, 380)}
                          ...{" "}
                        </>
                      ) : (
                        <>{avatardetails.about_us}</>
                      )}
                    </>
                  ) : (
                    <>{avatardetails.about_us}</>
                  )}
                </div>
                <div className="text-right right-padding-2">
                  {viewallstatus && (
                    <>
                      <span
                        className="avatar-view-more"
                        onClick={() => setViewAllStatus(false)}
                      >
                        Read more...
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};
export default Mobilebottominfo;
