import React, { useEffect } from "react";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { useDispatch } from "react-redux";
import { generatedQSearchURL } from "../api/accountAction";

const YourReactComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(generatedQSearchURL())
      .unwrap()
      .then(({ data }) => {
        var records = JSON.parse(data.aivolvex_control_plane).result;
        embedQSearchBar(records);
      })
      .catch((err) => {});
  }, []);

  const embedQSearchBar = async (data) => {
    console.log(data);
    try {
      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {},
      });

      const frameOptions = {
        url: data,
        container: "#experience-container",
        height: "100%",
        width: "100%",
        onChange: (changeEvent, metadata) => {
          switch (changeEvent.eventName) {
            case "FRAME_MOUNTED":
              // Do something when the experience frame is mounted.
              console.log("Frame mounted");
              break;
            case "FRAME_LOADED":
              // Do something when the experience frame is loaded.
              console.log("Frame loaded");
              break;
          }
        },
      };

      const contentOptions = {
        hideTopicName: false,
        // theme: "<YOUR_THEME_ID>",
        allowTopicSelection: true,
        onMessage: async (messageEvent, experienceMetadata) => {
          switch (messageEvent.eventName) {
            case "Q_SEARCH_OPENED":
              // Do something when Q Search is opened.
              break;
            case "Q_SEARCH_CLOSED":
              // Do something when Q Search is closed.
              break;
            case "Q_SEARCH_SIZE_CHANGED":
              // Do something when Q Search size is changed.
              break;
            case "CONTENT_LOADED":
              // Do something when content is loaded.
              break;
            case "ERROR_OCCURRED":
              // Do something when an error occurs.
              break;
          }
        },
      };

      const embeddedQSearchBarExperience =
        await embeddingContext.embedQSearchBar(frameOptions, contentOptions);
    } catch (error) {
      console.error("Error embedding Q Search Bar:", error);
    }
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div id="experience-container"></div>
    </div>
  );
};

export default YourReactComponent;
