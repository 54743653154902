import { useEffect, useState } from "react";
import { Table, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { historyrecordviewlist } from "../../api/accountAction";
import { Icon } from "@iconify/react";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { formatDateHistory } from "../../customhook/CustomHoom";
import MainStyle from "../../../MainStyle.scss";

const Handoff = (props) => {
  const fontcolor = MainStyle["theme-font-primary"];
  const user_session = props.record.conversation_id;
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);

  // const formatDate = (dateString) => {
  //     const date = new Date(dateString);
  //
  //     const currentDate = new Date();

  //     // Check if the date is within the current week
  //     const isCurrentWeek = date >= currentDate && date - currentDate <= 7 * 24 * 60 * 60 * 1000 && date.getDay() === currentDate.getDay();

  //     if (isCurrentWeek) {
  //         const options = { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true };
  //         return date.toLocaleDateString('en-US', options);
  //     } else {
  //         const options = { weekday: 'long', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
  //         return date.toLocaleDateString('en-US', options);
  //     }
  // };

  useEffect(() => {
    const input = {
      event_type: "public_agent_user_session",
      user_session: user_session,
    };
    setPreloaderStatus(true);
    dispatch(historyrecordviewlist(input))
      .unwrap()
      .then(({ data }) => {
        console.log(data)
        const value = JSON.parse(data.aivolvex_control_plane);
        const table = value.map((datas) => {
          return {
            date_time: datas.datetime,
            question: datas.question,
            answer: datas.answer,
            handed_off: datas.hand_off,
          };
        });
        setPreloaderStatus(false);
        setTablerecord(table);
      })
      .catch((err) => {setPreloaderStatus(false);});
  }, [user_session]);
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <div>
            {tablerecord.map((item, index) => (
              <Row className="history-tab-row">
                <Col span={19}>
                  <Row>
                    <Col span={1} className="puple-font question-font">
                      Q:
                    </Col>
                    <Col span={23} className="white-font">
                      {" "}
                      {item.question}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={1}></Col>
                    <Col span={23} className="puple-font">
                      {" "}
                      {formatDateHistory(item.date_time)}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={1} className="puple-font question-font">
                      A:
                    </Col>
                    <Col span={23} className="white-font">
                      {" "}
                      {item.answer}
                    </Col>
                  </Row>
                </Col>
                <Col span={5}>
                  <Col span={24} className="text-end">
                    {/* {item.handed_off && (<><span>
                                    <Icon icon='mdi:smiley' style={{ color: '#0BB852', fontSize: "24px" }} /></span>{" "}
                                    <span style={{ color: '#0BB852' }} className="smiley-text">{item.handed_off}</span></>)} */}
                    <div>
                      {(() => {
                        let iconColor, iconName, textColor;

                        switch (item.handed_off) {
                          case "Positive":
                            iconColor = "#0BB852";
                            iconName = "mdi:smiley";
                            textColor = "#0BB852";
                            break;
                          case "Neutral":
                            iconColor = "#FFC400";
                            iconName = "ph:smiley-meh-fill";
                            textColor = "#FFC400";
                            break;
                          case "Negative":
                            iconColor = "#FF2441";
                            iconName = "ion:sad";
                            textColor = "#FF2441";
                            break;
                          default:
                            iconColor = fontcolor;
                            iconName = "ph:smiley-fill";
                        }

                        return (
                          <>
                            <Icon
                              icon={iconName}
                              style={{ color: iconColor, fontSize: "20px" }}
                            />
                            <span
                              style={{ color: iconColor }}
                              className="smiley-text"
                            >
                              {item.handed_off}
                            </span>

                            <div className="history-token">
                              <img
                                src={aivolvex}
                                alt="logo"
                                className="history-token-logo"
                              />
                              1024
                            </div>
                          </>
                        );
                      })()}
                    </div>
                  </Col>
                </Col>
              </Row>
            ))}
          </div>
        </>
      )}
    </>
  );
};
export default Handoff;
