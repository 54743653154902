import { getAvatarDetails, historyrecordlist,historyrecordviewlist,threewayrecordlist } from "../../api/accountAction";
import { message } from "antd";

export const getavatardata = async (dispatch, input, setAvatarDetails) => {
    try {
        const response = await dispatch(getAvatarDetails(input)).unwrap();
        const value = JSON.parse(response.data.aivolvex_control_plane)[0];

        setAvatarDetails(value);

    } catch (error) {
        console.error(error);
    }
};

export const gethistoryrecordlist = async (dispatch, input, setTablerecord) => {
    try {
        const response = await dispatch(historyrecordlist(input)).unwrap();
        const value = JSON.parse(response.data.aivolvex_control_plane);
        console.log(value)
        const table = value.map((datas) => {
            return {
                service: datas.service,
                category: datas.category,
                language: datas.language,
                name: datas.user_name,
                email: datas.email,
                handed_off: datas.handed_off,
                typeofconversation: datas.type_of_conversation,
                handed_off_explanation: datas.handed_off_explanation,
                phone: datas.mobile,
                datetime: datas.date_time,
                sentiment: datas.sentiment,
                lead_explanation: datas.lead_explanation,
                sentiment_explanation: datas.sentiment_explanation,
                conversation_id: datas.conversation_id,
                summary: datas.summary,
                lead: datas.lead,
                action_to_be_taken: datas.action_to_be_taken,
                email_content: datas.email_content,
                url:datas.url,
                job_description:datas.job_description,
                selection_status:datas.selection_status,
                qa_status:datas.qa_status,
                score:datas.score,
                qa_content:datas.qa_content
            };
        });
        setTablerecord(table);

    } catch (error) {
        console.error(error);
    }
};
export const gethistoryrecordviewlist = async (dispatch, input, setTablerecord,setPreloaderStatus) => {
    try {
        const response = await dispatch(historyrecordviewlist(input)).unwrap();
        const value = JSON.parse(response.data.aivolvex_control_plane);
        const table = value.map((datas) => {
            return {
              date_time: datas.datetime,
              question: datas.question,
              answer: datas.answer,
              handed_off: datas.hand_off,
            };
          });
          setPreloaderStatus(false);
          setTablerecord(table);

    } catch (error) {
        console.error(error);
    }
};
//3waybot changes
export const threewaytablerecords = async (dispatch, input, setTablerecord) => {
    try {
        const response = await dispatch(threewayrecordlist(input)).unwrap();
        console.log(response)
        const value = JSON.parse(response.data.aivolvex_control_plane);
        console.log(value)
        const table = value.map((datas) => {
            return {
                Date: datas.Date,
                action_items: datas.action_items,
                bot_user_type: datas.bot_user_type,
                conversation_id: datas.conversation_id,
                email_id: datas.email_id,
                mobile_number: datas.mobile_number,
                opportunity: datas.opportunity,
                sentiment: datas.sentiment,
                sentiment_status: datas.sentiment_status,
                summary: datas.summary,
                user_name: datas.user_name
            };
        });
        setTablerecord(table);

    } catch (error) {
        console.error(error);
    }
};
