import { memo, useState, useEffect, useRef } from "react";
import { Col, Row, Input, Tabs, Modal, Button, Form, message } from "antd";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import "./chatmain.scss";
import { updateLastWord } from "../Slice/slice";
import { Icon } from "@iconify/react";
import ChartList from "./ChartList";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getAvatarDetails,
  startConversation,
  startConversationCloudShifu,
  startConversationKendra,
  getAvatarChatHistory,
  getTokenRemaining,
  getKendraOpenAI,
  startConversationPgVector,
  startConversationGeneralChat,
  getAvatarLibrary,
  getEmbeddingfile,
  getFilecheck,
} from "../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import PromptsList from "./PromptsList";
import FrequentlyPromptsList from "./FrequentlyPromptsList";
import HistoryPromptsList from "./HistoryPromptsList";
import AboutScroll from "./AboutScroll";
import aivolvex_icon from "../../assets/ui/X Icon SVG.svg";
import HelpKeyModel from "./HelpKeyModel";
import ChartAbout from "./ChartAbout";
import InsightsDetails from "./InsightsDetails";
import MonthlyTrendDetails from "./MonthlyTrendDetails";
import backgroundlogo from "../../assets/empty_chat_background.svg";
import { setSessionId } from "../Slice/slice";
//icons
import eye_icon from "../../assets/icons/eye.svg";
import message_icon from "../../assets/icons/message.svg";
import star_icon from "../../assets/icons/star.svg";
import price_icon from "../../assets/icons/price.svg";
import token_icon from "../../assets/icons/token.svg";
import chart_icon from "../../assets/icons/chart.svg";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SharedForm from "./SharedForm";
import SharedList from "./SharedList";
import preloader from "../../assets/loader/pre_loader.gif";
import { UploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
// import GPT3Tokenizer from "gpt3-tokenizer";

// import { tokenize } from "gpt3-tokenizer";
import AWS from "aws-sdk";

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   region: process.env.REACT_APP_AWS_REGION,
//   signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
// });
// const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});

const ChartMain = () => {
  //
  const navigateFn = useNavigate();
  const dispatch = useDispatch();
  const lastWord = useSelector((state) => state.lastWord);
  const session = useSelector((state) => state.session);
  const [message, setMessage] = useState("");
  const [avatardetails, setAvatarDetails] = useState([]);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [sessionid, setSessionID] = useState("");
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  // const [aibuttonstatue, setAiButtonStatus] = useState(false);
  const [aibuttonstatue, setAiButtonStatus] = useState(true);
  const [aibuttonstatue1, setAiButtonStatus1] = useState(false);
  const [promptslibrary, setPromptsLibrary] = useState([]);
  const [frequentlyprompts, setFrequentlyPrompts] = useState([]);
  const [historyprompts, setHistoryPrompts] = useState([]);
  const [viewallstatus, setViewAllStatus] = useState(true);
  const [token, setToken] = useState(0);
  const [frequentlypromptsstatus, setFrequentlyPromptsStatus] = useState(true);
  const [messageprocessstatus, setMessageProcessStatus] = useState(true);
  const [messageresponce, setMessageResponce] = useState(false);
  const [helpkeystatus, setHelpKeyStatus] = useState(false);
  const [fileupload, setFileUpload] = useState();
  const [mentionshow, setmentionshow] = useState(false);
  const [preloaderstatus, setPreLoaderStatus] = useState(true);
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const editorRef = useRef(null);
  const [toollist, setToolList] = useState([
    {
      name: "summary",
      id: 1,
    },
  ]);
  const [toolliststatus, setToolListStatus] = useState(false);
  const [selectedtool, setSelectedTool] = useState([]);
  const s3 = new AWS.S3();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const { id, conversationId } = useParams();
  const [conversation, setConversation] = useState(conversationId);
  const [basicActive, setBasicActive] = useState("1");

  //tab
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  //select prompts
  const getPromptsContent = (prompts) => {
    //
    setMessage(prompts);
    setAiButtonStatus(false);
  };
  //@ value
  const location = useLocation();

  useEffect(() => {
    if (lastWord && avatardetails.length !== 0) {
      //
      setSessionID(session);
      // if(session){
      //   setConversation(session)
      // }
      setMessages([]);
      setMessage(lastWord);
      setAiButtonStatus(false);
      setMessageProcessStatus(true);
    }

    //
  }, [avatardetails, lastWord, session]);

  useEffect(() => {
    if (messageprocessstatus && !aibuttonstatue && lastWord) {
      getDetails();
    }
  }, [messageprocessstatus, aibuttonstatue]);
  const getDetails = () => {
    setMessage(lastWord);
    // if(session){
    //
    //   getHistoryPrompts();}
    getStartConversation();
    setAiButtonStatus(true);
    dispatch(updateLastWord(""));
  };

  // const triggerStartConversation = () => {
  //   getStartConversation();
  // };

  //tap items
  const items = [
    {
      key: 1,
      label: (
        <>
          <Icon
            icon="bi:bookmark"
            className={
              basicActive == 1
                ? "prompt-title-icon prompt-title-icon-active"
                : "prompt-title-icon"
            }
          />{" "}
          Library
        </>
      ),
      children: (
        <PromptsList
          promptslist={promptslibrary}
          getPromptsContent={getPromptsContent}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(1),
      active: basicActive === 1,
    },
    {
      key: 2,
      label: (
        <>
          <Icon
            icon="octicon:question-24"
            className={
              basicActive == 2
                ? "prompt-title-icon prompt-title-icon-active"
                : "prompt-title-icon"
            }
          />{" "}
          Trending
        </>
      ),
      children: (
        <FrequentlyPromptsList
          getPromptsContent={getPromptsContent}
          avatar_id={id}
          frequentlypromptsstatus={frequentlypromptsstatus}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(2),
      active: basicActive === 2,
    },
    {
      key: 3,
      label: (
        <>
          <Icon
            icon="solar:history-linear"
            className={
              basicActive == 3
                ? "prompt-title-icon prompt-title-icon-active"
                : "prompt-title-icon"
            }
          />{" "}
          History
        </>
      ),
      children: (
        <HistoryPromptsList
          avatar_id={id}
          frequentlypromptsstatus={frequentlypromptsstatus}
          setSessionID={setSessionID}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(3),
      active: basicActive === 3,
    },
    {
      key: 4,
      label: (
        <>
          <Icon
            icon="icons8:share"
            className={
              basicActive == 4
                ? "prompt-title-icon prompt-title-icon-active"
                : "prompt-title-icon"
            }
          />{" "}
          Shared
        </>
      ),
      children: <SharedList />,
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(3),
      active: basicActive === 3,
    },
  ];

  useEffect(() => {
    setPreLoaderStatus(true);

    const input = {
      companyname: companyName,
      id: id,
    };
    dispatch(getAvatarLibrary(input))
      .unwrap()
      .then(({ data }) => {
        setPromptsLibrary(JSON.parse(data.aivolvex_control_plane));
        // setPromptsLibrary(
        //   JSON.parse(data.aivolvex_control_plane)[0].general_prompts
        // );
      })
      .catch((err) => {});

    dispatch(getAvatarDetails(id))
      .unwrap()
      .then(({ data }) => {
        setPreLoaderStatus(false);
        //
        //

        const currentPath = window.location.pathname;
        // const freq_prompts = JSON.parse(data.aivolvex_control_plane)[0]
        //   .frequently_used_prompts;

        // const list_freq_prompts = freq_prompts.filter(
        //   (v, index) =>
        //     v !== "" &&
        //     v !== "\r\n" &&
        //     v !== " " &&
        //     v !== null &&
        //     v !== undefined
        // );
        // setFrequentlyPrompts(list_freq_prompts);

        // const hitory_prompts = JSON.parse(data.aivolvex_control_plane)[0]
        //   .history;
        // const list_his_prompts = hitory_prompts.filter(
        //   (v, index) =>
        //     v !== "" &&
        //     v !== "\r\n" &&
        //     v !== " " &&
        //     v !== null &&
        //     v !== undefined
        // );
        // setHistoryPrompts(list_his_prompts);
        setAvatarDetails(JSON.parse(data.aivolvex_control_plane)[0]);
        // l
        // setPromptsLibrary(
        //   JSON.parse(data.aivolvex_control_plane)[0].general_prompts
        // );
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [id]);
  useEffect(() => {
    getHistoryPrompts();
  }, [conversationId]);
  useEffect(() => {
    const input = {
      company: companyName,
    };
    dispatch(getTokenRemaining(input))
      .unwrap()
      .then(({ data }) => {
        //
        const records = JSON.parse(data.aivolvex_control_plane);
        //
        setToken(records.remaining_credits);
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [messageresponce]);
  const getHistoryPrompts = () => {
    setMessages([]);
    if (conversationId !== undefined) {
      setSessionID(conversationId);
      const input = {
        conversationid: conversationId,
        // conversationid: conversation,
        companyname: companyName,
      };
      //
      dispatch(getAvatarChatHistory(input))
        .unwrap()
        .then(({ data }) => {
          const records = JSON.parse(data.aivolvex_control_plane);
          //
          //
          if (records.length > 0) {
            records.forEach((messagedata, index) => {
              //
              //
              setMessages((messages) => [
                ...messages,
                {
                  text: messagedata.question,
                  user: true,
                  date: formatDate(new Date(messagedata.timestamp)),
                  // date: getCurrentDayAndTime(),
                  type: "message",
                },
              ]);

              setMessages((messages) => [
                ...messages,
                {
                  text: messagedata.answer,
                  user: false,
                  date: formatDate(new Date(messagedata.timestamp)),
                  // date: getCurrentDayAndTime(),
                  type: "message",
                },
              ]);
            });
          }
          //
        })
        .catch((err) => {
          // message.error(err);
        });
    }
  };
  const deleteRecord = () => {
    setMessages((messages) => {
      return messages.slice(0, -1);
    });
  };
  function generateTransactionId() {
    const timestamp = Date.now().toString();
    const randomNum = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    return `${timestamp}-${randomNum}`;
  }
  function getCurrentDayAndTime() {
    const options = {
      weekday: "short",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat("ist", options);
    const currentDate = new Date();
    const formattedDate = formatter.format(currentDate);
    return formattedDate;
  }
  function formatDate(date) {
    const options = {
      weekday: "short",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat("ist", options);
    const currentDate = new Date();
    const diffInDays = Math.floor((currentDate - date) / (24 * 60 * 60 * 1000));

    if (diffInDays === 0) {
      // If the date is today, display it with time
      return formatter.format(date);
    } else if (diffInDays <= 7) {
      // If the date is within the past week, display the weekday with time
      return formatter.format(date).replace(",", ""); // Remove the comma after the weekday
    } else {
      // If the date is more than a week ago, display just the date
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      const dateFormatter = new Intl.DateTimeFormat("ist", options);
      return dateFormatter.format(date);
    }
  }
  const handleKeyPress = (event) => {
    if (message.length > 0) {
      if (event.key === "Enter") {
        event.preventDefault();
        getStartConversation();
      }
    }
  };
  const handleInputChange = (event) => {
    // const htmlContent = event;
    // const tempElement = document.createElement("div");
    // tempElement.innerHTML = htmlContent;
    // const inputValue = tempElement.textContent;
    // setMessage(inputValue);
    // if (inputValue.length > 0) {
    //   setAiButtonStatus(false);
    // } else {
    //   setAiButtonStatus(true);
    // }
  };
  const htmlRemove = (event) => {
    const htmlContent = event;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    return tempElement.textContent;
  };
  const handleKeyDown = (event) => {
    if (event.key === "@") {
      setToolListStatus(true);
    } else if (event.key === "Backspace") {
      //
      const newmessage = htmlRemove(message);
      if (!newmessage.includes("@")) {
        setToolListStatus(false);
      }
      // const removedLetter = message.slice(-1);
      //
    } else if (event.key === "Enter") {
      const value = htmlRemove(editorRef.current.value);
      //
      //
      if (value.length > 0) {
        event.preventDefault();
        getStartConversation();
      }
    }
  };

  const handleSuggestionSelect = (tooldetails) => {
    setSelectedTool([
      ...selectedtool,
      {
        name: tooldetails.name,
        obj: `<span style='color: rgb(219, 42, 172); font-family: Poppins;'>${tooldetails.name}</span> <span>&nbsp;</span>`,
      },
    ]);
    const newmessage = message.replace(
      "@",
      `<span style='color: rgb(219, 42, 172); font-family: Poppins;'>${tooldetails.name}</span> <span>&nbsp;</span>`
    );
    setMessage(newmessage);
    setToolListStatus(false);
  };
  // async function getStartConversation() {
  //
  //   const result = await startConversation();
  //
  //   // Expected output: "resolved"
  // }

  const getStartConversation = () => {
    if (messageprocessstatus) {
      setMessageProcessStatus(false);
      setAiButtonStatus(true);
      // setAiButtonStatus(false);

      if (!aibuttonstatue) {
        // setMessages([...messages, { text: message, user: true }]);
        const currentDayAndTime = getCurrentDayAndTime();

        // var chatmessage = message;

        const chatinput = htmlRemove(message);
        var chatmessage = chatinput;
        var chattool = null;
        if (selectedtool.length > 0) {
          chatmessage = chatinput.replace(selectedtool[0].name, " ");
          chattool = selectedtool[0].name;
        }
        setMessages((messages) => [
          ...messages,
          {
            text: chatmessage,
            user: true,
            date: getCurrentDayAndTime(),
            type: "message",
          },
        ]);

        setMessages((messages) => [
          ...messages,
          {
            text: "loader",
            user: false,
            date: getCurrentDayAndTime(),
            type: "loader",
          },
        ]);

        let currentDate = new Date();
        var chatsessionid;
        if (sessionid === "") {
          chatsessionid = generateTransactionId();
          setSessionID(chatsessionid);
          dispatch(setSessionId(chatsessionid));
        } else {
          chatsessionid = sessionid;
          dispatch(setSessionId(chatsessionid));
        }
        setMessage("");
        // editorRef.current.value = "";

        // if (avatardetails.type === "kendra") {
        //   //kendra
        //   const input = {
        //     date_time: `${currentDate.getFullYear()}-${
        //       currentDate.getMonth() + 1
        //     }-${currentDate.getDate()}`,
        //     user_session: chatsessionid,
        //     avatar_id: id,
        //     chat: chatmessage,
        //     ai: "openai",
        //     schema: companyName,
        //     user_name: userName,
        //   };
        //   dispatch(startConversationKendra(input))
        //     .unwrap()
        //     .then(({ data }) => {
        //       setMessageResponce(!messageresponce);
        //       //
        //       const responce = JSON.parse(data.query_kendra);
        //       var kendra_message = "";
        //       // if (responce.answer.answer.length > 0) {
        //       // kendra_message = responce.answer.answer[0];

        //       // deleteRecord();
        //       // setMessages((messages) => [
        //       //   ...messages,
        //       //   {
        //       //     text: kendra_message,
        //       //     user: false,
        //       //     date: getCurrentDayAndTime(),
        //       //     type: "message",
        //       //     message_list: responce.document,
        //       //     // pdf: responce.presigned_url,
        //       //     // pdfname: responce.docuemnt_name,
        //       //     // pagenumber: responce.page_no,
        //       //     source: true,
        //       //   },
        //       // ]);
        //       // setFrequentlyPromptsStatus(!frequentlypromptsstatus);
        //       // setMessageProcessStatus(true);
        //       // } else {
        //       var content = "";
        //       responce.prompt.map((data) => {
        //         content = data + ", " + content;
        //       });
        //       // var prompts = `question: ${message} read the following : Based on the analysis : ${content}. Frame a appropriate response for the question from the analysis`;
        //       var prompts = `Read the following question,answer and form a response based on that. This is the Question : ${message} and Answer : ${content}.`;

        //       // if (responce.document.length == 1) {
        //       //   var prompts = `question: ${message} read the following : Based on the analysis : ${responce.document[0].answer}.Frame a reponse by reading the question and answer 1`;
        //       // } else if (responce.document.length > 1) {
        //       //   var prompts = `question: ${message} read the following : Based on the analysis : ${responce.document[0].answer}, ${responce.document[1].answer}.Frame a reponse by reading the question, analysis 1 and analysis 2`;
        //       // }
        //       //
        //       //
        //       const openai_input = {
        //         prompts: prompts,
        //         user_session: chatsessionid,
        //         avatar_id: id,
        //         schema: companyName,
        //         user_name: userName,
        //         question: message,
        //       };
        //       dispatch(getKendraOpenAI(openai_input))
        //         .unwrap()
        //         .then(({ data }) => {
        //           const result_kendra = JSON.parse(data.openai_response);
        //           //
        //
        //           deleteRecord();
        //           setMessages((messages) => [
        //             ...messages,
        //             {
        //               text: result_kendra,
        //               user: false,
        //               date: getCurrentDayAndTime(),
        //               type: "message",
        //               message_list: responce.document,
        //               // pdf: responce.presigned_url,
        //               // pdfname: responce.docuemnt_name,
        //               // pagenumber: responce.page_no,
        //               source: true,
        //             },
        //           ]);
        //           setFrequentlyPromptsStatus(!frequentlypromptsstatus);
        //           setMessageProcessStatus(true);
        //         })
        //         .catch((err) => {
        //
        //           deleteRecord();
        //           setMessages((messages) => [
        //             ...messages,
        //             {
        //               text: "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?",
        //               user: false,
        //               date: getCurrentDayAndTime(),
        //               type: "message",
        //               // message_list: [],
        //               // source: true,
        //             },
        //           ]);
        //           setMessageProcessStatus(true);
        //         });
        //       // }
        //     })
        //     .catch((err) => {
        //
        //       deleteRecord();
        //       setMessages((messages) => [
        //         ...messages,
        //         {
        //           text: "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?",
        //           user: false,
        //           date: getCurrentDayAndTime(),
        //           type: "message",
        //         },
        //       ]);
        //       setMessageProcessStatus(true);
        //     });
        // } else {

        // var reference_schema = avatardetails.reference_schema;
        // if (
        //   avatardetails.managed_by === " " &&
        //   avatardetails.managed_by === null &&
        //   avatardetails.managed_by === undefined
        // ) {
        //   reference_schema = companyName;
        // }

        const input = {
          user_session: chatsessionid,
          avatar_id: id,
          chat: chatmessage,
          schema: companyName,
          user_name: userName,
        };

        dispatch(startConversation(input))
          .unwrap()
          .then(({ data }) => {
            setMessageResponce(!messageresponce);
            const result = JSON.parse(data.aivolvex_control_plane);
            //

            const responce = result.answer;
            deleteRecord();
            if (result.type === "chat") {
              setMessages((messages) => [
                ...messages,
                {
                  text: responce,
                  user: false,
                  date: getCurrentDayAndTime(),
                  type: "message",
                },
              ]);
            } else {
              setMessages((messages) => [
                ...messages,
                {
                  text: responce,
                  user: false,
                  date: getCurrentDayAndTime(),
                  type: "message",
                  message_list: result.document,
                  source: true,
                },
              ]);
            }

            setFrequentlyPromptsStatus(!frequentlypromptsstatus);
            setMessageProcessStatus(true);
          })
          .catch((err) => {
            deleteRecord();
            setMessages((messages) => [
              ...messages,
              {
                text: "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?",
                user: false,
                date: getCurrentDayAndTime(),
                type: "message",
              },
            ]);
            setMessageProcessStatus(true);
          });
        // }
      }
    }
  };
  // const handleFileUpload = (event) => {
  //   var chatsessionid;
  //   if (sessionid === "") {
  //     chatsessionid = generateTransactionId();
  //     setSessionID(chatsessionid);
  //   }
  //   const selectedFile = event.target.files[0];
  //   setFileUpload(selectedFile);
  //   setHelpKeyStatus(true);
  // };
  function generateRandomFourDigitNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  function generateUniqueID() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const randomFourDigitNumber = generateRandomFourDigitNumber();

    return `${year}${month}${day}-${randomFourDigitNumber}`;
  }
  let timer;
  const startTimer = () => {
    const timerInterval = 3 * 1000;
    timer = setInterval(() => {
      //
    }, timerInterval);
  };

  const stopTimer = () => {
    clearInterval(timer);
  };
  const handleFileUpload = (event) => {
    var chatsessionid;
    //
    //
    const files = selectedFiles.map((fileInfo) => ({
      file: fileInfo,
    }));
    if (sessionid === "") {
      chatsessionid = generateTransactionId();
      setSessionID(chatsessionid);
    }
    const selectedFile = files;
    //
    setFileUpload(selectedFile);
    // setHelpKeyStatus(true);
    handlefileOk(selectedFile, chatsessionid);
    // startTimer();
    setFileModalOpen(false);
    setSelectedFiles([]);
  };

  const handlefileOk = (selectedFile, chatsessionid) => {
    // setConfirmLoading(true);
    //
    const uniqueID = generateUniqueID();
    //
    //
    // var chatsessionid;
    // if (sessionid === "") {
    //   chatsessionid = generateTransactionId();
    //   setSessionID(chatsessionid);
    //   dispatch(setSessionId(chatsessionid));
    // } else {
    //   chatsessionid = sessionid;
    //   dispatch(setSessionId(chatsessionid));
    // }
    const params = selectedFile.map((fileDetail) => ({
      // Bucket: "platform-kendra-bucket",
      // Key: `AIVolveXTitan/${sessionid}/${fileDetail.file.name}`,
      Bucket: "embedding-input-bucket",
      Key: `${chatsessionid}/${uniqueID}/${fileDetail.file.name}`,
      Body: fileDetail.file.originFileObj,
    }));
    //
    stopTimer();
    handleAWSFileUpload(params, uniqueID, chatsessionid);
  };
  const handleAWSFileUpload = async (params, uniqueID, chatsessionid) => {
    try {
      const uploadPromises = params.map(async (param) => {
        await s3.upload(param).promise();
      });
      setMessages((messages) => [
        ...messages,
        {
          text: "File Uploaded Successfully! currently processing the file. Please wait while it's being analyzed..",
          user: false,
          date: getCurrentDayAndTime(),
          type: "message",
        },
      ]);
      const input = {
        user_session: chatsessionid,
        company: companyName,
        unique_id: uniqueID,
      };
      //
      setTimeout(() => {
        dispatch(getEmbeddingfile(input))
          .unwrap()
          .then(({ data }) => {
            const value = JSON.parse(data.aivolvex_control_plane);
            //
            setMessages((messages) => [
              ...messages,
              {
                text: "File analysis is complete! 'You can now inquire about the file's contents'",
                user: false,
                date: getCurrentDayAndTime(),
                type: "message",
              },
            ]);
          });
      }, 2000).catch((err) => {
        let timerId;
        timerId = setInterval(async () => {
          try {
            const input = {
              user_session: chatsessionid,
              company: companyName,
              unique_id: uniqueID,
            };
            const { data } = await dispatch(getFilecheck(input)).unwrap();
            //

            if (data) {
              clearInterval(timerId);
            }
          } catch (error) {
            console.error("Error checking file status:", error);
          }
        }, 30000);
      });

      // setConfirmLoading(false);
      setOpen(false);
    } catch (error) {
      console.error("Error uploading files to S3:", error);
    }
  };
  const handleCancel = () => {
    setFileModalOpen(false);
    setSelectedFiles([]);
  };
  return (
    <>
      {helpkeystatus && (
        <HelpKeyModel
          open={helpkeystatus}
          setOpen={setHelpKeyStatus}
          filedetails={fileupload}
          sessionid={sessionid}
        />
      )}
      <NavBar />
      <div className="body-bg">
        {preloaderstatus ? (
          <>
            {" "}
            <div className="aivolvex-loader">
              <img src={preloader} width="30%" />
            </div>
          </>
        ) : (
          <>
            <Row className="body-margin chat-body">
              {/* <Col span={5} className="window-margin-right body-margin-top">
            <Sidemenu />
          </Col> */}
              <Col span={24} className="body-margin-top">
                <Row className="mt-2">
                  <Col span={24}>
                    <div>
                      <h3 className="chat-title aivolvex-font">
                        <Icon
                          icon="cil:home"
                          className="chat-home-icon"
                          onClick={() => {
                            if (
                              avatardetails.pod?.toLowerCase() === "aivolvex"
                            ) {
                              navigateFn("/avatarhub/general");
                            } else {
                              navigateFn("/avatarhub/enterprise");
                            }
                          }}
                        />{" "}
                        <Icon
                          icon="iconamoon:arrow-right-2-light"
                          className="promp-logo"
                        />{" "}
                        <span
                          onClick={() => {
                            if (
                              avatardetails.pod?.toLowerCase() === "aivolvex"
                            ) {
                              navigateFn("/avatarhub/general");
                            } else {
                              navigateFn("/avatarhub/enterprise");
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {avatardetails.pod?.toLowerCase() === "aivolvex" && (
                            <>AIVolveX Hub</>
                          )}
                          {avatardetails.pod?.toLowerCase() !== "aivolvex" && (
                            <>Avatar Hub</>
                          )}
                        </span>
                        <Icon
                          icon="iconamoon:arrow-right-2-light"
                          className="promp-logo"
                        />{" "}
                        <span
                          onClick={() => {
                            if (
                              avatardetails.pod?.toLowerCase() === "aivolvex"
                            ) {
                              navigateFn("/avatarhub/general");
                            } else {
                              navigateFn("/avatarhub/enterprise");
                            }
                          }}
                          className="cursor-pointer"
                        >
                          {avatardetails.pod}
                        </span>
                        <Icon
                          icon="iconamoon:arrow-right-2-light"
                          className="promp-logo"
                        />{" "}
                        <span className="promp-active">
                          {avatardetails.avatar_name}
                        </span>
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row className="chat-main-row">
                  <Col
                    xs={24}
                    sm={24}
                    md={11}
                    lg={11}
                    xl={11}
                    className="chat-right-box"
                  >
                    <Row className="chat-right-box-main">
                      <Col span={24} className="chat-left-avatar-details">
                        <Row>
                          <Col xs={24} sm={24} md={7} lg={4} xl={4}>
                            <div className="profile-picture">
                              <img
                                src={avatardetails.avatar_image}
                                alt=""
                                className="profile-avator-img"
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={11} lg={14} xl={14}>
                            <div>
                              <h5 className="white-color">
                                {avatardetails.avatar_name}
                              </h5>
                              <h6 className="site-second-color version-font-size">
                                {avatardetails.version}
                              </h6>
                              {avatardetails.avatar_owner && (
                                <p className="white-color">
                                  Avatar Owner : {avatardetails.avatar_owner}{" "}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={6}
                            lg={6}
                            xl={6}
                            className="right-token-view"
                          >
                            <div className="d-flex justify-content-end">
                              <div>
                                <img
                                  src={aivolvex_icon}
                                  alt="logo"
                                  className="profile-token-icon"
                                />{" "}
                              </div>
                              <div>
                                {" "}
                                <h5 className="white-color">{token}</h5>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} className="chat-left-about">
                        <label className="label-header white-color aivolvex-font">
                          About Me
                        </label>
                        {/* <AboutScroll viewallstatus={viewallstatus}> */}
                        <div className="samplediv"></div>
                        <div>
                          <div
                            className={
                              viewallstatus &&
                              avatardetails.about_us?.length > 380
                                ? "side-scroll-container-shadow"
                                : ""
                            }
                            // className="side-scroll-container-shadow"
                          >
                            <div class="job-description word-wrap colorSecondary small-text white-color aivolvex-font mt-3 side-scroll-container">
                              {viewallstatus ? (
                                <>
                                  {avatardetails.about_us?.length > 380 ? (
                                    <>
                                      {avatardetails.about_us?.substring(
                                        0,
                                        380
                                      )}
                                      ...{" "}
                                    </>
                                  ) : (
                                    <>{avatardetails.about_us}</>
                                  )}
                                </>
                              ) : (
                                <>{avatardetails.about_us}</>
                              )}
                            </div>
                            <div className="text-right right-padding-2">
                              {viewallstatus && (
                                <>
                                  {avatardetails.about_us?.length > 300 && (
                                    <>
                                      <span
                                        className="avatar-view-more"
                                        onClick={() => setViewAllStatus(false)}
                                      >
                                        Read more...
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* </AboutScroll> */}
                      </Col>
                      {/* <div class="bottom-hr-line"></div> */}

                      <Col span={24} className="mt-2 mb-2 chat-left-askme">
                        {tag_id === "all" ? (
                          <>
                            <Row className="d-flex" gutter={[16, 16]}>
                              <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                                <div className="avatar-info-icons">
                                  <span>
                                    <img
                                      src={eye_icon}
                                      alt="eye"
                                      className="avatar-info-img"
                                    />
                                  </span>
                                  <span className="info-icon-text">5000</span>
                                </div>
                              </Col>
                              <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                                <div className="avatar-info-icons">
                                  <span>
                                    <img
                                      src={message_icon}
                                      alt="message"
                                      className="avatar-info-img"
                                    />
                                  </span>
                                  <span className="info-icon-text">4200</span>
                                </div>
                              </Col>
                              <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                                <div className="avatar-info-icons">
                                  <span>
                                    <img
                                      src={star_icon}
                                      alt="star"
                                      className="avatar-info-img"
                                    />
                                  </span>
                                  <span className="info-icon-text">4.8/5</span>
                                </div>
                              </Col>
                              <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                                <div className="avatar-info-icons">
                                  <span>
                                    <img
                                      src={price_icon}
                                      alt="price"
                                      className="avatar-info-img"
                                    />
                                  </span>
                                  <span className="info-icon-text">$12000</span>
                                </div>
                              </Col>
                              <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                                <div className="avatar-info-icons">
                                  <span>
                                    <img
                                      src={token_icon}
                                      alt="token"
                                      className="avatar-info-img"
                                    />
                                  </span>
                                  <span className="info-icon-text">3000</span>
                                </div>
                              </Col>
                              <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                                <div className="avatar-info-icons">
                                  <span>
                                    <img
                                      src={chart_icon}
                                      alt="chart"
                                      className="avatar-info-img"
                                    />
                                  </span>
                                  <span className="info-icon-text">950</span>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Row className="d-flex" gutter={[16, 16]}>
                            <Col xs={8} sm={8} md={8} lg={6} xl={6}></Col>
                            <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                              <div className="avatar-info-icons">
                                <span>
                                  <img
                                    src={eye_icon}
                                    alt="eye"
                                    className="avatar-info-img"
                                  />
                                </span>
                                <span className="info-icon-text">5000</span>
                              </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                              <div className="avatar-info-icons">
                                <span>
                                  <img
                                    src={message_icon}
                                    alt="message"
                                    className="avatar-info-img"
                                  />
                                </span>
                                <span className="info-icon-text">4200</span>
                              </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                              <div className="avatar-info-icons">
                                <span>
                                  <img
                                    src={star_icon}
                                    alt="star"
                                    className="avatar-info-img"
                                  />
                                </span>
                                <span className="info-icon-text">4.8/5</span>
                              </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={6} xl={6}></Col>
                          </Row>
                        )}
                      </Col>
                      <Col span={24} className="mt-2 mb-2 chat-left-prompts">
                        <Tabs
                          className="aivolvex-bot-tab aivolvex-font"
                          items={items}
                          onChange={handleBasicClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    className={
                      messages.length > 0
                        ? "chat-left-box"
                        : "chat-left-box bg-img-center"
                    }
                  >
                    <div className="right-bot-head">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <Icon
                            onClick={() => {
                              dispatch(setSessionId(""));
                              setSessionID("");
                              window.location.href = "/chat/" + id;
                            }}
                            icon="ri:chat-new-line"
                            className="con-share-icon-left cursor-pointer"
                          />
                        </div>
                        <div>
                          <h5 className="white-color">
                            {avatardetails.avatar_name}
                          </h5>
                          <h6 className="site-second-color version-font-size">
                            {avatardetails.version}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <Icon
                            icon="system-uicons:share"
                            className="con-share-icon cursor-pointer"
                            onClick={() => {
                              setOpen(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="right-bot-chat-body">
                      {messages.length > 0 ? (
                        <ChartList
                          messages={messages}
                          avatardetails={avatardetails}
                        />
                      ) : (
                        <div className="chat-empty-bg-div">
                          <img
                            src={backgroundlogo}
                            className="chat-empty-background"
                          />
                        </div>
                      )}
                      {toolliststatus && (
                        <ul className="tool-list">
                          {toollist.map((tool, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionSelect(tool)}
                              className="tool-list-item"
                            >
                              {tool.name}
                            </li>
                          ))}
                        </ul>
                      )}
                      <div class="input-container">
                        <Input.TextArea
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                            if (e.target.value.length > 0) {
                              setAiButtonStatus(false);
                            } else {
                              setAiButtonStatus(true);
                            }
                          }}
                          autoSize={{ minRows: 1 }}
                          className="input-container-textarea aivolvex-font"
                          onKeyPress={handleKeyPress}
                        />
                        {/* rich text */}
                        {/* <ReactQuill
                      value={message}
                      ref={editorRef}
                      // onClick={handleEditorClick}
                      onChange={(event) => {
                        setMessage(event);  
                        const inputvalue = htmlRemove(event);
                        if (inputvalue.length > 0) {
                          setAiButtonStatus(false);
                        } else {
                          setAiButtonStatus(true);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      modules={{ toolbar: false }}
                      className="input-container-textarea aivolvex-font chat-react-quill"
                    /> */}
                        {id == 694 && (
                          <label
                            htmlFor="file-input"
                            className="custom-file-upload d-flex justify-content-center align-items-center"
                          >
                            {/* <input
                              id="file-input"
                              type="file"
                              accept=".pdf"
                              onChange={handleFileUpload}
                              // style={{ display: "none" }}
                            /> */}
                            <span className="upload-icon">
                              <Icon
                                icon="typcn:upload-outline"
                                onClick={() => setFileModalOpen(true)}
                              />
                            </span>
                          </label>
                        )}
                        {tag_id === "all" && (
                          <div className="btn-chat-token">
                            <span className="d-flex justify-content-between">
                              <span>10</span>{" "}
                              <img
                                src={aivolvex}
                                alt="logo"
                                className="token-chat-logo"
                              />
                            </span>
                          </div>
                        )}

                        <button
                          onClick={() => getStartConversation()}
                          disabled={aibuttonstatue}
                          className={aibuttonstatue && "disable-ai-btn"}
                        >
                          <Icon
                            icon="uil:message"
                            className="input-container-icon"
                          />
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>

      {/* model */}
      <Modal
        title=""
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        {" "}
        <Tabs
          className="aivolvex-bot-tab aivolvex-font"
          items={[
            {
              key: 1,
              label: (
                <>
                  <Icon
                    icon="fluent:tag-20-regular"
                    className="prompt-title-icon"
                  />{" "}
                  Tags
                </>
              ),
              children: (
                <SharedForm
                  type="tag"
                  avatar_id={id}
                  sessionid={sessionid}
                  setOpen={setOpen}
                />
              ),
              className: "plat-dashboard-link",
            },
            {
              key: 2,
              label: (
                <>
                  <Icon
                    icon="system-uicons:user-add"
                    className="prompt-title-icon"
                  />{" "}
                  Members
                </>
              ),
              children: (
                <SharedForm
                  type="member"
                  avatar_id={id}
                  sessionid={sessionid}
                  setOpen={setOpen}
                />
              ),
              className: "plat-dashboard-link",
            },
          ]}
        />
      </Modal>
      <Modal open={fileModalOpen} footer={false} onCancel={handleCancel} >
        {/* <input
          id="file-input"
          type="file"
          accept=".pdf"
        // onChange={handleFileUpload}
        // style={{ display: "none" }}
        /> */}
        <>
          <Upload
            multiple
            fileList={selectedFiles}
            onChange={(info) => {
              setSelectedFiles(info.fileList);
            }}
          >
            <Button className="select-file">
              <Icon icon="line-md:upload-loop" className="upload-file-icon" />
              Select Files
            </Button>
            <br />
          </Upload>
          <br />
          {selectedFiles.length > 0 && (
            <Button
              onClick={handleFileUpload}
              type="primary"
              className="upload-file"
            >
              Upload Files
            </Button>
          )}
        </>
      </Modal>
      {/* end model */}
    </>
  );
};

export default memo(ChartMain);
