import { Col, Row } from "antd";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Historymobiletable from "./Historymobiletable";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
import HistoryTable from "../Desktop/HistoryTable"
import { useDispatch, useSelector } from "react-redux";
const HistoryBodyMobile = () => {
    const { agentname } = useSelector((state) => state.chat);
    return (
        <>
            <Row>
                <Col span={24}>
                    <Navbarmobile />
                </Col>
            </Row>
            <Row className="mobile-background">
                <Col span={24} className="white-color aivolvex-font d-flex justify-content-center p-1">
                    <h4>{agentname}</h4>
                </Col>
            </Row>
            <Row className="recent-mobile-body mobile-background">
                <Col span={24}>
                    {/* <Historymobiletable /> */}
                    <HistoryTable/>
                </Col>
            </Row>
            <Row className="chat-bottom-section">
                <Col span={24} ><SidemenuMobile /></Col>
            </Row>
        </>
    )
}
export default HistoryBodyMobile