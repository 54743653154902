import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { Icon } from '@iconify/react';
import PositiveImg from "../../Images/positive.svg";
import NegativeImg from "../../Images/negative.svg";
import NeutralImg from "../../Images/neutral.svg";
import OpportunityTooltip from './OpporunityTooltip';
import IssueTooltip from './IssueTooltip';
import TooltipSentiment from './TooltipSentiment';
import StepperTooltip from './StepperTooltip';
// import { Tooltip } from 'react-tooltip'

const { Step } = Steps;

const MyStepsComponent = (props) => {
  console.log(props, "sentiment_history")
  const [sentimentHistory, setSentimentHistory] = useState([]);

  useEffect(() => {
    if (props.SentimentInfo && Object.keys(props.SentimentInfo).length > 0) {
      if (props.SentimentInfo.type !== "ans") {
        setSentimentHistory(prevHistory => [...prevHistory, {
          ...props.SentimentInfo.response,
          timestamp: new Date().toLocaleTimeString()
        }]);
      }
    }
  }, [props.SentimentInfo]);

  const getOpportunityIcon = (status) => {
    switch (status) {
      case "positive":
        return <Icon icon="ph:warning-circle-fill" width="1.7em" height="1.7em" className="green-warning" />;
      case "negative":
        return <Icon icon="ph:warning-circle-fill" width="1.7em" height="1.7em" className="red-warning" />;
      default:
        return null;
    }
  };

  return (
    <div className="horizontal-steps-container">
      <div>
        <Steps className="step-margin timelinechart"
          current={sentimentHistory.length - 1}
          progressDot={'customDot'}
          items={sentimentHistory.map((data, index) => ({
            title: <span className="text-white font-size">{data.timestamp}</span>,
            description: (
              <div>
                <TooltipSentiment title={<span>{data.Overall_sentiment}</span>}>
                  {data.Overall_sentiment === "Negative" && (
                    <img src={NegativeImg} alt="Negative Sentiment Logo" />
                  )}
                  {data.Overall_sentiment === "Neutral" && (
                    <img src={NeutralImg} alt="Neutral Sentiment Logo" />
                  )}
                  {data.Overall_sentiment === "Positive" && (
                    <img src={PositiveImg} alt="Positive Sentiment Logo" />
                  )}
                </TooltipSentiment>

              
                
                    {getOpportunityIcon(data.Opportunity_status)}
                 
             
               
              </div>
            ),
          }))}
        />
      </div>
    </div>
  );
}

export default MyStepsComponent;
