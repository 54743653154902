import React, { useState } from "react";
import { Button, Modal, Input } from "antd";
import { Icon } from "@iconify/react";
// import {
//   LinkedinShareButton,
//   FacebookShareButton,
//   TwitterShareButton,
// } from "react-share";

const { TextArea } = Input;

const SocialMediaModel = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  const handleShareOnTwitter = () => {
    const textToShare = extractPlainText(props.socialmediacontent);
    const twitterShareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      textToShare
    )}`;
    window.open(twitterShareURL, "_blank");
  };
  const handleShareOnLinkedIn = () => {
    const textToShare = encodeURIComponent("Check out my awesome content!");
    const shareUrl = encodeURIComponent("https://www.dev.aivolvex.ai/"); // Replace with your content URL

    const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&comment=${textToShare}`;

    window.open(linkedInShareURL, "_blank");
  };

  const extractPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const handleShareOnFacebook = () => {
    const contentTitle = "Awesome React.js Content";
    const contentDescription = "Check out this amazing React.js content!";

    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      "https://www.dev.aivolvex.ai/"
    )}&quote=Check out this amazing React.js content!`;

    window.open(shareUrl, "_blank");
  };

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal with async logic
      </Button> */}
      {/* <Icon
        icon="fa6-brands:x-twitter"
        className="cursor-pointer social-media-share"
        onClick={() => handleShareOnTwitter()}
      /> */}
      {/* <TwitterShareButton url={window.location.href}>
        <Icon
          icon="fa6-brands:x-twitter"
          className="cursor-pointer social-media-share"
        />
      </TwitterShareButton> */}
      {/* <LinkedinShareButton url={window.location.href}>
        <Icon
          icon="devicon:linkedin"
          className="cursor-pointer social-media-share"
        />
      </LinkedinShareButton> */}
      {/* <FacebookShareButton url={window.location.href}>
        <Icon
          icon="devicon:facebook"
          className="cursor-pointer social-media-share"
        />
      </FacebookShareButton> */}
      {/* <Icon
        icon="devicon:linkedin"
        className="cursor-pointer social-media-share"
        onClick={() => handleShareOnLinkedIn()}
      />
      <Icon
        icon="devicon:facebook"
        className="cursor-pointer social-media-share"
        onClick={handleShareOnFacebook}
      /> */}
    </>
  );
};

export default SocialMediaModel;
