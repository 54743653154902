import React, { useEffect, useState } from "react";
import { Col, Row, Space, Table } from "antd";
import PCAProfile from "./PCAProfile";
import PCACallAI from "./PCACallAI";
import PCASentimentChart from "./PCASentimentChart";
import PCACallSummary from "./PCACallSummary";
import PCAQAPoints from "./PCAQAPoints";
import PCATranscript from "./PCATranscript";
import PCAKnowledgebase from "./PCAKnowledgebase";
import { getPCAPageRecords } from "../../../api/accountAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const PCAComponents = () => {
  const { conversation_id } = useParams();
  const [pcapagerecords, setPCAPagePageRecords] = useState([]);
  const [pcaqacheck, setPCAQaCheck] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const input = {
      conversation_id: conversation_id,
    };
    dispatch(getPCAPageRecords(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
       
        if (records) {
          setPCAPagePageRecords(records);
          setPCAQaCheck(JSON.parse(records.qa_checklist));
          console.log("test")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Col span={24} className="aivolvex-main-body">
        <h4 className="dashboard-credit aivolvex-font text-center">
          Post Analytics
        </h4>
        <Row className="mt-1 mb-3" gutter={[16, 16]}>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="customerprofile"
          >
            <PCAProfile record_prop={pcapagerecords} />
          </Col>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PCACallAI />
              </Col>
              {/* <Col span={24}>
                <PCASentimentChart />
              </Col> */}
            </Row>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PCACallSummary callSummary_details={pcapagerecords} />
              </Col>
              <Col span={24}>
                <PCAQAPoints
                  qa_details={pcapagerecords}
                  pcaqacheck={pcaqacheck}
                />
              </Col>
            </Row>
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
            <PCATranscript />
          </Col>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <PCAKnowledgebase />
          </Col>
        </Row>
      </Col>
    </>
  );
};
export default PCAComponents;
