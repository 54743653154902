import { memo } from "react";
import { Icon } from "@iconify/react";

const PopConfirmJDQa = (props) => {
    return (
      <>
       <div className="volvex-shared-form">
        {props.content.split("\r\n").map((line, index) => {
          const [content, status] = line.split(": ");
          return (
            <p key={index}>
              {index + 1}. {content}
              {status === "Yes" ? (
                <Icon icon="mdi:tick-circle-outline" className="qa-check-tick" />
              ) : (
                <Icon icon="iconoir:xmark-circle" className="qa-check-cross" />
              )}
            </p>
          );
        })}
      </div>
    </>
  );
};
export default memo(PopConfirmJDQa);
