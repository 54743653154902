import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import "../CSS/dashboardnew.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getnumberagents,
  gettotalprompts,
  gettokenrequest,
  getallocatedtoken,
} from "../../api/accountAction";
import { simplifyNumber } from "../../customhook/CustomHoom";
import preloader from "../../../assets/loader/pre_loader.gif";
// function simplifyNumber(number) {
//   const billion = 1000000000;
//   const million = 1000000;
//   const thousand = 1000;

//   if (number >= billion) {
//     return (number / billion).toFixed(1) + 'B';
//   } else if (number >= million) {
//     return (number / million).toFixed(1) + 'M';
//   } else if (number >= thousand) {
//     return (number / thousand).toFixed(1) + 'K';
//   } else {
//     return number.toString();
//   }
// }

const ModalUsageTrend = () => {
  const { dashbaorddate, offerstatus } = useSelector((state) => state.chat);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [numberagentvalue, setNumberAgentValue] = useState([0]);
  const [totalpromptsdata, setTotalPromptsData] = useState([0]);
  const [tokenrequestdata, setTotalRequestData] = useState([0]);
  const [allocationtoken, setAllocationToken] = useState([0]);
  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const date = new Date(dashbaorddate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  useEffect(() => {
    const input = {
      event_type: "avatar_count",
      company: companyName,
      user_id: userName,
      date_time: dashbaorddate,
      user_type: offerstatus,
    };

    dispatch(getnumberagents(input))
      .unwrap()
      .then(({ data }) => {
        const numberagents = JSON.parse(data.aivolvex_control_plane);
        setNumberAgentValue(numberagents.avatar_count);
      })
      .catch((err) => {});

    const totalpromts = {
      event_type: "question_count",
      company: companyName,
      user_id: userName,
      year: year,
      month: month,
      user_type: offerstatus,
    };

    dispatch(gettotalprompts(totalpromts))
      .unwrap()
      .then(({ data }) => {
        const totalprompts = JSON.parse(data.aivolvex_control_plane);
        const Totalprompts = Math.round(totalprompts[0].question);
        setTotalPromptsData(Totalprompts);
      })
      .catch((err) => {});

    const currenttoken = {
      event_type: "current_month_tokens",
      company: companyName,
      user_id: userName,
      year: year,
      month: month,
      user_type: offerstatus,
    };

    dispatch(gettokenrequest(currenttoken))
      .unwrap()
      .then(({ data }) => {
        const tokenrequest = JSON.parse(data.aivolvex_control_plane);
        const Requesttotal = Math.round(tokenrequest[0].total_tokens);
        setTotalRequestData(Requesttotal);
      })
      .catch((err) => {});

    const allocatedtokn = {
      event_type: "allocated_tokens",
      company: companyName,
      user_id: userName,
      year: year,
      month: month,
      user_type: offerstatus,
    };

    dispatch(getallocatedtoken(allocatedtokn))
      .unwrap()
      .then(({ data }) => {
        const allocationtoken = JSON.parse(data.aivolvex_control_plane);
        //
        //
        setAllocationToken(allocationtoken.available_tokens);
      })
      .catch((err) => {});
  }, [dashbaorddate, offerstatus]);

  return (
    <>
      {/* <Row> */}
      {/* <Col span={24} className="d-flex justify-content-center mt-4">
          <h4 className="dashboard-credit aivolvex-font">Model Usage Trend</h4>
        </Col> */}
      {/* <Col span={24} className="d-flex justify-content-center mt-3"> */}
      {/* <h4 className="white-color dashboard-sub-header">Usage Overview</h4> */}
      {/* </Col> */}
      {/* </Row> */}
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_head_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                {/* <h2>{totalpromptsdata}</h2> */}
                <h2>{simplifyNumber(totalpromptsdata)}</h2>
                <p>Total prompts</p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_head_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                {/* <h2>{tokenrequestdata}</h2> */}
                {tokenrequestdata ? (
                  <h2>{simplifyNumber(tokenrequestdata)}</h2>
                ) : (
                  <h2>0</h2>
                )}
                <p>Credits</p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_head_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                {allocationtoken ? (
                  <>
                    {/* <h2>{allocationtoken}</h2> */}
                    <h2>{simplifyNumber(allocationtoken)}</h2>
                    <p>Allocated Credits</p>
                  </>
                ) : (
                  <div>
                    <p>0</p>
                    <p>Allocated Credits</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_head_box Dashboard_head_box_shadow">
              <div className="Dashboard_head_content">
                {/* <h2> {numberagentvalue} </h2> */}
                <h2>{simplifyNumber(numberagentvalue)}</h2>
                <p>No.of Agents</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModalUsageTrend;
