import { memo, useEffect, useState } from "react";
import {
  Col,
  Row,
  Space,
  Table,
  Pagination,
  Button,
  Popconfirm,
  Input,
  Dropdown,
  Select,
} from "antd";
import { Icon } from "@iconify/react";
import {
  getPageination,
  getRecentPrompts,
} from "../../../components/api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoTrashBin } from "react-icons/io5";
import "../CSS/recentprompts.scss";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { setLibraryQuestion } from "../../Slice/reducer/chatReducer";
import { getdatacount, getdatarecord } from "../Auth/RecentpromptAuth";
import preloader_image from "../../../assets/loader/pre_loader.gif";
const Recentprompttable = () => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState("");
  const [record, setRecord] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState("question");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const columns = [
    {
      title: "Date and Time",
      dataIndex: "dateandtime",
      key: "dateandtime",
      width: "20%",
      align: "center",
      render: (text) => formatDate(text),
    },
    {
      title: "Agent",
      dataIndex: "avatar_name",
      key: "avatar_name",
      width: "15%",
      render: (text) => {
        return (
          <>
            <div className="recent-table-agent-name">{text}</div>
          </>
        );
      },
      align: "center",
    },
    {
      title: "Pod",
      dataIndex: "pod",
      key: "pod",
      width: "10%",
      align: "center",
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      key: "Prompt",
    },
    {
      title: "Token",
      // <div>
      //   <img
      //     src={aivolvex}
      //     alt="Image"
      //     className="token-footer-logo aivolvex-font"
      //   />
      //   Credits
      // </div>
      render: (text) => {
        return (
          <>
            <div className="recent-table-credits">{text.toFixed(2)}</div>
          </>
        );
      },
      dataIndex: "token",
      key: "token",
      align: "center",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: "15%",
      render: (text, record) => (
        <Space size="middle">
          <span>
            {/* <Button
              className="his-button history_btn"
              onClick={() => handleIconClick(record)}
            > */}
            <Icon
              icon="fluent:clipboard-link-20-regular"
              style={{ fontSize: "30px" }}
              onClick={() => handleIconClick(record)}
              className="history-action-icons his-link"
            />
            {/* </Button> */}
          </span>
          <span>
            <Popconfirm
              title="Are you sure you want to delete this item?"
              onConfirm={() => handleDeleteClick(record)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              {/* <Button className="his-button his-delete"><IoTrashBin style={{ fontSize: "18px" }} /> <span className="his-delete-text">Delete</span></Button> */}
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];
  const formatDate = (inputDate) => {
    //
    if (inputDate) {
      const date = new Date(inputDate);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amOrPm}`;
      return formattedDate;
    }
  };
  const handleIconClick = (record) => {
    dispatch(setLibraryQuestion(record.prompt));
    const avatarId = record.avatar_id;

    navigate(`/conversation/${avatarId}`);
  };
  const handleDeleteClick = (record) => {
    //
  };
  const cancel = (e) => {
    //
  };

  // useEffect(() => {
  //   const input = {
  //     user_id: userName,
  //     company: companyName,
  //     dropdown: selectedItem,
  //     text: searchQuery
  //   };
  //   getdatacount(dispatch, input, setCount);
  // }, []);
  const filteredPrompts = record.filter((item) =>
    item.prompt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // useEffect(() => {
  //   const inputlist = {
  //     page: currentPage,
  //     limit: pageSize,
  //     user_id: userName,
  //     company: companyName,
  //     text: searchQuery,
  //     dropdown: selectedItem
  //   };
  //   getdatarecord(dispatch, inputlist, setPreloaderStatus, setRecord);
  // }, [dispatch, inputlist]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const items = [
    {
      label: "Pod",
      value: "pod_name",
    },
    {
      label: "Agent",
      value: "avatar_name",
    },
    {
      label: "Prompt",
      value: "question",
    },
  ];

  const handleItemClick = (item) => {
    //
    setSelectedItem(item);
    setSearchQuery("");
  };

  const inputlist = {
    page: currentPage,
    limit: pageSize,
    user_id: userName,
    company: companyName,
    text: searchQuery,
    dropdown: selectedItem,
  };
  useEffect(() => {
    //
    const debounceTimer = setTimeout(() => {
      //
      getdatarecord(dispatch, inputlist, setPreloaderStatus, setRecord);
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, selectedItem, currentPage, pageSize]);

  useEffect(() => {
    const input = {
      user_id: userName,
      company: companyName,
      dropdown: selectedItem,
      text: searchQuery,
    };
    setCurrentPage(1);
    getdatacount(dispatch, input, setCount);
  }, [searchQuery, selectedItem]);
  return (
    <>
      <Col span={24} className="aivolvex-main-body">
        <Row className="recent-prompt-table-head">
          <Col span={24} className="d-flex justify-content-between">
            <h4 className="theme-title-font">Recent Prompts</h4>
          </Col>
        </Row>
        <Row className="recent-prompt-search">
          <div className="recent-search">
            <Col>
              {/* <Dropdown
                menu={{
                  items,
                  handleItemClick,
                }}
                placement="bottomLeft"
                className="recent-search-dropdown"
              >
                <div className="d-flex justify-content-center align-items-center">
                   {selectedItem ? selectedItem.label : "Search here"} */}
              {/* {selectedItem.label} */}
              {/* <Icon
                    icon="mingcute:down-line"
                    className="lib-search-icon white-color"
                  />
                </div>
              </Dropdown>  */}
              <Select
                defaultValue={selectedItem}
                className="recent-search-dropdown"
                onChange={handleItemClick}
                options={items}
              />
            </Col>
            <Col className="recent-search-input-mobile">
              <Input
                placeholder="Search"
                suffix={
                  <Icon icon="material-symbols:search" className="icon-size" />
                }
                className="recent-search-input "
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </Col>
          </div>
        </Row>
        <Row>
          {preloaderstatus ? (
            <>
              <Col span={24}>
                <div className="aivolvex-sub-loader h-18">
                  <img src={preloader} width="20%" />
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col span={24} className="recent-prompt-table">
                {record.length > 0 ? (
                  <Table
                    // className="mt-3 ms-2 costimize-bootstrap-table"
                    className="recent-prompts-table dashboard-board-background-table"
                    dataSource={record}
                    columns={columns}
                    pagination={{
                      current: currentPage,
                      pageSize: pageSize,
                      total: count,
                      onChange: handlePageChange,
                    }}
                  />
                ) : (
                  <>
                    {searchQuery.length == 0 ? (
                      <div className="aivolvex-sub-loader h-18">
                        <img src={preloader_image} width="20%" />
                      </div>
                    ) : (
                      <div>
                        <div className="no-data">No Data</div>
                        <div className="d-flex justify-content-center">
                          <Icon
                            icon="tabler:database-exclamation"
                            className="no-data-icon"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Col>
            </>
          )}
        </Row>
      </Col>
    </>
  );
};
export default Recentprompttable;
