import { profileinsertdata, profilelistdata } from "../../api/accountAction";
import { message } from "antd";
export const insertprofile = async (dispatch, input) => {
  try {
    if (input[0].key_id === "company_info") {
      localStorage.removeItem("customer_first_name");
      localStorage.removeItem("customer_logo");
      localStorage.removeItem("AIVolvexConversation");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
    } else if (input[0].key_id === "profile_info") {
      localStorage.removeItem("companyimage");
      localStorage.removeItem("companyloaderimage");
    }
    const response = await dispatch(profileinsertdata(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);

    // const encodedValue = btoa(item.meta_value);
    // localStorage.setItem("customer_first_name", encodedValue);
    // window.location.reload();

    // localStorage.removeItem("customer_first_name");
    // localStorage.removeItem("customer_logo");
    // localStorage.removeItem("companyimage");
    // localStorage.removeItem("companyloaderimage");
    // localStorage.removeItem("AIVolvexConversation")

    //
    window.location.reload();
    message.success("Details Updated");
    // window.location.href = "/";
    // window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
export const listprofile = async (dispatch, input, setRecordCallback) => {
  try {
    const response = await dispatch(profilelistdata(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);

    setRecordCallback(value);
    value.forEach((item) => {
      if (item.key_id === "profile_info") {
        if (item.meta_key === "customer_first_name") {
          const encodedValue = btoa(item.meta_value);
          localStorage.setItem("customer_first_name", encodedValue);
        } else if (item.meta_key === "customer_logo") {
          localStorage.setItem("customer_logo", item.meta_value);
        }
      } else if (item.key_id === "company_info") {
        if (item.meta_key === "conversation_backgroundimage") {
          localStorage.setItem("companyloaderimage", item.meta_value);
          localStorage.setItem("AIVolvexConversation", item.meta_value);
        } else if (item.meta_key === "company_logo") {
          localStorage.setItem("companyimage", item.meta_value);
        } else if (item.meta_key === "conversation_fontsize") {
          localStorage.setItem("AIVolvexConversation", item.meta_value);
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
};
