import { memo, useEffect, useState } from "react";
import NavBar from "../../Header/NavBar";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { Col, Row, Tabs } from "antd";
import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import TagMemberConfig from "./TagMemberConfig";
import "../CSS/rolehome.scss";
import TagAvatarConfig from "./TagAvatarConfig";
import { listTagging, getAvatarList } from "../../api/accountAction";
import { useDispatch } from "react-redux";
import preloader from "../../../assets/loader/pre_loader.gif";

const TagHome = () => {
  const [basicActive, setBasicActive] = useState(1);
  const [datachangestatus, setDataChangeStatus] = useState(true);
  const [preloaderstatus, setPreLoaderStatus] = useState(true);
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const [avatarlist, setAvatarList] = useState([]);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );

  useEffect(() => {
    setPreLoaderStatus(true);
    const input = {
      compnayname: companyName,
    };
    dispatch(listTagging(input))
      .unwrap()
      .then(({ data }) => {
        setPreLoaderStatus(false);
        const responce = JSON.parse(data.aivolvex_control_plane);
        const resultArray = responce.map((item) => ({
          id: item.id,
          title: item.tag_title,
          members_list: splitMembers(item.member_list),
          avatar_list: item.avatar_name.map((email, index) => ({
            avatar_id: index + 1,
            avatar: email,
          })),
        }));
        setRecords(resultArray);
      })
      .catch((err) => {});
  }, [datachangestatus]);

  useEffect(() => {
    const input = {
      companyName: companyName,
    };
    dispatch(getAvatarList(input))
      .unwrap()
      .then(({ data }) => {
        const record = JSON.parse(data.aivolvex_control_plane);
        const newAvatars = record.filter(
          (avatar) => avatar.agent_type == "private"
        );
        // setAvatarList(newAvatars);
        setAvatarList(record);

      })
      .catch((err) => {});
  }, []);

  function splitMembers(members) {
    return members.split(",").map((email, index) => ({
      members_id: index + 1,
      members_email: email,
    }));
  }

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const items = [
    {
      key: 1,
      label: "Members",
      children: (
        <TagMemberConfig
          records={records}
          datachangestatus={datachangestatus}
          setDataChangeStatus={setDataChangeStatus}
          avatarlist={avatarlist}
          setBasicActive={setBasicActive}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(1),
      active: basicActive === 1,
    },
    {
      key: 2,
      label: "Agents",
      children: (
        <TagAvatarConfig
          records={records}
          avatarlist={avatarlist}
          datachangestatus={datachangestatus}
          setDataChangeStatus={setDataChangeStatus}
          setBasicActive={setBasicActive}
        />
      ),
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(2),
      active: basicActive === 2,
    },
  ];

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={2}
            xl={2}
            className="body-margin-top"
          >
            <Sidemenu />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={22}
            xl={22}
            className="body-margin-top aivolvex-main-body "
          >
            <Row className="mt-1">
              {/* <BreadCrumb
                homelink="/tag"
                breadcrumblist={[]}
                activebreadcroum={{
                  name: "Tags",
                  link: "/tag",
                }}
              /> */}
              {/* <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">Tags</h4>
              </Col> */}
            </Row>
            {preloaderstatus ? (
              <div className="aivolvex-loader">
                <img src={preloader} width="30%" />
              </div>
            ) : (
              <Row>
                <Col span={24}>
                  <Tabs
                    defaultActiveKey={basicActive}
                    className="tag-tabs aivolvex-font"
                    items={items}
                    onChange={handleBasicClick}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(TagHome);
