import { memo, useState } from "react";
import "./CSS/conversation.scss";
import DesktopConversationBody from "./Desktop/DesktopConversationBody";
import MobileConversationBody from "./Mobile/MobileConversationBody";

const ConversationBody = () => {
  return (
    <>
      <div className="conversation-desktop">
        <DesktopConversationBody />
      </div>
      <div className="conversation-mobile">
        <MobileConversationBody />
      </div>
    </>
  );
};

export default memo(ConversationBody);
