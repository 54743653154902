import React, { useState, useEffect } from "react";
import { Modal, Table, Space, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const SourceModel = (props) => {
  const s3 = new AWS.S3();
  const [tablerecords, setTableRecords] = useState(props.sourcerecords);
  const navigate = useNavigate();
  const handleCancel = () => {
    //
    props.setOpen(false);
  };
  //   useEffect(() => {
  //
  //   }, [props]);

  const columns = [
    {
      title: "Document Name",
      dataIndex: "docuemnt_name",
      key: "docuemnt_name",
      width: "20%",
      render: (text, index) => {
        return (
          <>
            {text.length > 50 ? (
              <Tooltip placement="bottom" title={text}>
                <p
                  onClick={() => {
                    window.open(index.presigned_url, "_blank");
                  }}
                  className="cursor-pointer"
                >
                  {text.substring(0, 50)}...
                </p>
              </Tooltip>
            ) : (
              <p
                onClick={() => {
                  window.open(index.presigned_url, "_blank");
                }}
                className="cursor-pointer"
              >
                {text}
              </p>
            )}
          </>
        );
        //
        //
        // <Space size="middle">
        //   <a>
        //     <Icon
        //       icon="fa-solid:file-pdf"
        //       style={{ fontSize: "25px", color: "#ad0b00" }}
        //   onClick={() => {
        //     window.open(text, "_blank");
        //   }}
        //     />
        //   </a>
        // </Space>
      },
      // ...getColumnSearchProps("pod"),
    },
    {
      title: "Page No",
      dataIndex: "page_no",
      key: "page_no",
      width: "10%",
      // ...getColumnSearchProps("avatar"),
    },
    {
      title: "Location",
      dataIndex: "answer",
      key: "answer",
      width: "60%",
      render: (text, index) => {
        return (
          <>
            {text.length > 600 ? (
              <Tooltip placement="bottom" title={text}>
                <p
                  onClick={() => {
                    window.open(index.presigned_url, "_blank");
                  }}
                  className="cursor-pointer"
                >
                  {text.substring(0, 600)}...
                </p>
              </Tooltip>
            ) : (
              <p
                onClick={() => {
                  window.open(index.presigned_url, "_blank");
                }}
                className="cursor-pointer"
              >
                {text}
              </p>
            )}
          </>
        );
      },
      // ...getColumnSearchProps("pod"),
    },

    {
      title: "Actions",
      dataIndex: "presigned_url",
      key: "presigned_url",
      width: "10%",
      align: "center",
      render: (text) => (
        <Space size="middle">
          <a>
            <Icon
              icon="vscode-icons:file-type-pdf2"
              style={{ fontSize: "25px" }}
              onClick={() => {
                window.open(text, "_blank");
              }}
            />
            {/* <Icon
              icon="fa-solid:file-pdf"
              style={{ fontSize: "25px", color: "#ad0b00" }}
              onClick={() => {
                window.open(text, "_blank");  
              }}
            /> */}
          </a>
        </Space>
      ),
    },
  ];

  const paginationConfig = {
    pageSize: 4, // Set the page size (limit) here
  };

  // const getImageURL = (url) => {
  //   const params = {
  //     Bucket: "pgvector-documents",
  //     Key: "finvizor-bank/Electric Power & Gas Sector FY23 Outlook.pdf",
  //     Expires: 3600,
  //   };

  //   const url = s3.getSignedUrl("getObject", params);
  //   return fetchPdfUrl(url);
  // };

  // const fetchPdfUrl = async (url) => {
  //   try {
  //     const response = await fetch(url);
  //     if (response.ok) {
  //       const url = await response.text();
  //       return url;
  //     } else {
  //       console.error("Error fetching PDF URL");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching PDF URL:", error);
  //   }
  // };

  return (
    <>
      <Modal
        title=""
        open={props.open}
        onCancel={handleCancel}
        footer={[]}
        width="70%"
      >
        <Table
          // className="mt-3 ms-2 source-bootstrap-table"
          className="mt-3 ms-2 costimize-bootstrap-table"
          dataSource={tablerecords}
          columns={columns}
          pagination={paginationConfig}
        />
      </Modal>
    </>
  );
};

export default SourceModel;
