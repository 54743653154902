import { memo, useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";

const PCACallSummary = (props) => {
  console.log(props,"propsssss")
  const [recordslist, setRecordsList] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');

  const showModal = () => {
    setHistoryOpen(true);
  };

  const handleCancel = () => {
    setHistoryOpen(false);
  };

  const onChange = (key) => {
    setSelectedTab(key);
  };

  const renderContent = () => {
    const { opportunity_status, opportunity_tag, summary } = props.callSummary_details;

    if (selectedTab === '1' && (opportunity_status === 'Positive' || opportunity_status === 'positive')) {
      return (
        <div className="success-height">
          <p className="success-tag">{opportunity_tag}</p>
          <p className="text-white">{summary}</p>
        </div>
      );
    } else if (selectedTab === '2' && (opportunity_status === 'Negative' || opportunity_status === 'negative')) {

      return (
        <div className="Issue-height">
          <p className="issue-tag">{opportunity_tag}</p>
          <p className="text-white">{summary}</p>
        </div>
      );
    } else {
      return <p className="text-white">No records data available.</p>;
    }
  };


  const items = [
    {
      key: '1',
      label: "Opportunities",
      children: renderContent(),
    },
    {
      key: '2',
      label:<span className="ms-2">Issues</span>,
      children: renderContent(),
    },
  ];

  return (
    <div className="pca-ai-card">
      <div className="aivolvex-dashboard-outline full-width">
        <div className="Dashboard_graph_box Dashboard_head_box_shadow action-item">
          <Row>
            <Col span={19}>
              <h4 className="dashboard-credit aivolvex-font font-size-title">
                Analytics Summary
              </h4>
            </Col>
          </Row>
          <div className="dashboard-scroll">
            <Row className="pca-df pca-csa-body">
              <Col span={7} className="pca-label-title topic-bold">
                Action Item :
              </Col>
              <Col span={17} className="pca-label-content text-white">
                <p>{props.callSummary_details["action_items"]}</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="custom-tabs" />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default memo(PCACallSummary);
