import { memo, useState, useEffect } from "react";
import { Tag } from "antd";
import { Icon } from "@iconify/react";

const PCAQAPoints = (props) => {
  const [QaCheck, setQaCheck] = useState("");
  const [QaCheckStatus, setQaCheckStatus] = useState([]);


  const QaCheckvalue = () => {
    const staticQaStatus = props.qa_details["qa_check"];
    var ss_json = {};
    if (props.pcaqacheck) {
      ss_json = props.pcaqacheck;
    }
    const staticQaCheck = ss_json;
    setQaCheck(staticQaStatus);
    const qaCheckArray = Object.entries(staticQaCheck);

    setQaCheckStatus(qaCheckArray);

  };

  useEffect(() => {
    QaCheckvalue();
  }, [props]);

  return (
    <>
      {QaCheck ? (
        <div className="pca-ai-card">
          <div className="aivolvex-dashboard-outline full-width">
            <div className="Dashboard_graph_box Dashboard_head_box_shadow opp-height">
              <div className="pca-insights-scroll">
                <div className="d-flex justify-content-between">
                  <h4 className="dashboard-credit aivolvex-font font-size-title">
                    QA Check
                  </h4>
                  <div>
                    <Tag
                      color={
                        QaCheck.toLowerCase() === "completed" ? "green" : "red"}
                      className="opp-tag">
                      {QaCheck}
                    </Tag>
                  </div>
                </div>
                <div className="pca-qa-body">
              
                  {QaCheckStatus.map(([key, value], index) => (
                 
                    <div key={key} className="text-white">
                        
                      {key.replace(/_/g, ' ')}&nbsp;&nbsp;
                      {value === "Yes" ? (
                        <Icon
                          icon="mdi:tick-circle-outline"
                          className="qa-check-tick"
                        />
                      ) : (
                        <Icon
                          icon="iconoir:xmark-circle"
                          className="qa-check-cross"
                        />
                      )}
                    </div>
                  ))}
                </div>
                {/* <div className="pca-qa-body">

                  {QaCheckStatus.map(([key, value], index) => (
                    <div key={key} className="text-white">
                      {key}&nbsp;&nbsp;
                      {value === "Yes" ? (
                        <Icon
                          icon="mdi:tick-circle-outline"
                          className="qa-check-tick"
                        />

                      ) : (
                        <Icon
                          icon="iconoir:xmark-circle"
                          className="qa-check-cross"
                        />
                      )}
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center h-64 w-100 align-items-center">
          {/* <img src={callsumarylogo} className="text-center width-lastsummary" /> */}
          <p>No Data Found!</p>
        </div>
      )}
    </>
  );
};

export default memo(PCAQAPoints);
