import { listAvatar, pinnedAvatar, mailboxsend } from "../../api/accountAction";
import { message } from "antd";
import { setPinnedStatus } from "../../Slice/reducer/chatReducer";

export const getAvatarList = async (dispatch, input, setPreloader) => {
  try {
    const response = await dispatch(listAvatar(input)).unwrap();
    setPreloader(false);
  } catch (error) {
    console.error(error);
    setPreloader(false);
  }
};

export const getpinnedavatar = async (
  dispatch,
  input,
  pinnedstatus,
  pinmessage
) => {
  try {
    const response = await dispatch(pinnedAvatar(input)).unwrap();
    dispatch(setPinnedStatus(!pinnedstatus));
    message.success(pinmessage);
  } catch (error) {
    console.error(error);
    message.error("Action not done");
  }
};
export const sendemail = async (dispatch, input) => {
  try {
    const response = await dispatch(mailboxsend(input)).unwrap();
  } catch (error) {
    console.error(error);
  }
};
