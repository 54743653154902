import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import preloader from "../../../assets/loader/pre_loader.gif";
import { gethistoryrecordviewlist } from "../Auth/Publicauth";
import { formatDateHistory } from "../../customhook/CustomHoom";
import MainStyle from "../../../MainStyle.scss";

const Mobilehistorycontent = (props) => {
  const fontcolor = MainStyle["theme-font-primary"];

  const user_session = props.records.conversation_id;
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);

  useEffect(() => {
    const input = {
      event_type: "public_agent_user_session",
      user_session: user_session,
    };
    gethistoryrecordviewlist(
      dispatch,
      input,
      setTablerecord,
      setPreloaderStatus
    );
  }, [dispatch, user_session]);

  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <div>
            {tablerecord.map((item, index) => (
              <Row className="history-tab-row">
                <Col span={24}>
                  <Row>
                    <Col>
                      <span className="puple-font question-font">Q:</span>{" "}
                      <span className="white-font"> {item.question}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="puple-font question-font">A:</span>{" "}
                      <span className="white-font">{item.answer}</span>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="white-font" span={8}>Total Spend:</Col> */}
                    <Col span={8}>
                      <div>
                        {(() => {
                          let iconColor, iconName, textColor;

                          switch (item.handed_off) {
                            case "Positive":
                              iconColor = "#0BB852";
                              iconName = "mdi:smiley";
                              textColor = "#0BB852";
                              break;
                            case "Neutral":
                              iconColor = "#FFC400";
                              iconName = "ph:smiley-meh-fill";
                              textColor = "#FFC400";
                              break;
                            case "Negative":
                              iconColor = "#FF2441";
                              iconName = "ion:sad";
                              textColor = "#FF2441";
                              break;
                            default:
                              iconColor = fontcolor;
                              iconName = "ph:smiley-fill";
                          }

                          return (
                            <>
                              <Icon
                                icon={iconName}
                                style={{ color: iconColor, fontSize: "24px" }}
                              />
                              <span
                                style={{ color: iconColor }}
                                className="smiley-text"
                              >
                                {item.handed_off}
                              </span>

                              {/* <div className="history-token">
                                                            <img
                                                                src={aivolvex}
                                                                alt="logo"
                                                                className="history-token-logo"
                                                            />{" "}
                                                            Credits
                                                        </div> */}
                            </>
                          );
                        })()}
                      </div>
                    </Col>
                    <Col
                      className="puple-font d-flex justify-content-end"
                      //  span={8}
                      span={16}
                    >
                      {" "}
                      {formatDateHistory(item.date_time)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
        </>
      )}
    </>
  );
};
export default Mobilehistorycontent;
