import { Button, Col, Input, Row, Form } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const id = "stzbzsqnszfvvflvx55hfkm7mu";
const region = "ap-south-1";
const apikey = "da2-aowcakvaljdyfhwfsuqzcmlyvu";

const host = id + ".appsync-api." + region + ".amazonaws.com";
const url =
  "wss://" + id + ".appsync-realtime-api." + region + ".amazonaws.com";

const StreamingTest = () => {
  const [data, setData] = useState("");
  const [uniqueid, setUniqueid] = useState(generateUUID());
  const [randomUUID, setrandomUUID] = useState(generateUUID());
  var ws = undefined;

  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const openWebsocket = () => {
    const api_header = {
      host: host,
      "x-api-key": apikey,
    };

    const payload = {};
    const base64_api_header = btoa(JSON.stringify(api_header));
    const base64_payload = btoa(JSON.stringify(payload));
    const appsync_url =
      url + "?header=" + base64_api_header + "&payload=" + base64_payload;

    const socket = new WebSocket(appsync_url, ["graphql-ws"]);

    socket.onopen = (e) => {
      socket.send(JSON.stringify({ type: "connection_init" }));
    };

    socket.onmessage = (e) => {
      const receivedData = JSON.parse(e.data);
      if (receivedData.type === "connection_ack") {
        // Connection Acknowledged, you can start subscribing
        const query = JSON.stringify({
          query:
            'subscription MySubscription {on_aivolvex_control_plane(user_id: "' +
            uniqueid +
            '")}',
        });
        console.log(query);
        const queryStr = JSON.stringify({
          id: randomUUID,
          type: "start",
          payload: {
            data: query,
            extensions: {
              authorization: {
                host: host,
                "x-api-key": apikey,
              },
            },
          },
        });
        socket.send(queryStr);
      } else if (
        receivedData.type === "data" &&
        receivedData.id === randomUUID
      ) {
        const temp = data;
        const streaming_responce = JSON.parse(
          receivedData.payload.data.on_aivolvex_control_plane
        );
     
        if (uniqueid === streaming_responce.user_id) {
          setData((item) => item + streaming_responce.response);
        }
      }
    };  
    setTimeout(() => {
      console.log("close");
      socket.close();
    }, 60000);
    return socket;
  };

  const onFinish = (values) => {
    ws = openWebsocket();
    setData("");
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "da2-aowcakvaljdyfhwfsuqzcmlyvu");
    myHeaders.append("Content-Type", "application/json");

    var graphql = JSON.stringify({
      query:
        'query MyQuery {\r\n  send_message(question: "' +
        values.question +
        '", user_id: "' +
        uniqueid +
        '")\r\n}\r\n',
      variables: {},
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    fetch(
      "https://stzbzsqnszfvvflvx55hfkm7mu.appsync-api.ap-south-1.amazonaws.com/graphql",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <div>
      <Row>
        <Col>
          <Link to="/conversation"> home </Link>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Ask"
              name="question"
              rules={[
                {
                  required: true,
                  message: "Please input your input!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ color: "#fff" }}>
            <pre>{data}</pre>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default StreamingTest;
