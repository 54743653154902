import { Col, Table, Tag, Space, Modal, Tooltip, Popconfirm, Row } from "antd";
import { memo, useEffect, useState } from "react";
import {
  capitalizeWords,
  formatDatewithtime,
} from "../../../customhook/CustomHoom";
import { threewaytablerecords } from "../../../Records/Auth/Publicauth";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PopConfirmJDQa from "../../../Records/Desktop/PopConfirmJDQa";
import { Icon } from "@iconify/react";
import Historymodal from "../../../Records/Desktop/Historymodal";
import MarkdownToJSX from "markdown-to-jsx";

const LatestJdRecords = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [historyopen, setHistoryOpen] = useState(false);
  const [records, setRecords] = useState([]);

  const formatDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedDate = `${day}-${month}- ${year}, ${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amOrPm}`;
      return formattedDate;
    }
  };
  
  useEffect(() => {
    const input = {
      event_type: "live_agent_history",
      avatar_id: id,
    };
    threewaytablerecords(dispatch, input, setTablerecord);
    
  }, [dispatch, id]);



  // const filteredRecords = tablerecord.filter(record => record.typeofconversation === 'chat');
  const filteredRecords = tablerecord
  const columns = [
    {
      title: "Date",
      dataIndex: "Date",    
      key: "Date",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(text)}</span>
      ),
    },
    // {
    //   title: "Username",
    //   dataIndex: "typeofconversation",
    //   key: "typeofconversation",
    //   render: (_, { typeofconversation }) => (
    //     <>
    //       {typeofconversation != null && (
    //         <>
    //           {typeofconversation.split(",").map((tag) => {
    //             let color;
    //             if (tag.trim() === "chat") {
    //               color = "purple";
    //             } else if (tag.trim() === "chat") {
    //               color = "blue";
    //             } else {
    //               color = "red";
    //             }
    //             return (
    //               <Tag color={color} key={tag.trim()}>
    //                 chat
    //               </Tag>
    //             );
    //           })}
    //         </>
    //       )}
    //     </>
    //   ),
    //   onHeaderCell: () => ({
    //     style: {
    //       whiteSpace: "normal",
    //       wordWrap: "break-word",
    //       textAlign: "center",
    //     },
    //   }),
    // },
    {
      title: "Username",
      dataIndex: "user_name",
      key: "user_name",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "Email id",
      dataIndex: "email_id",
      key: "email_id",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      // render: (text) => capitalizeWords(text),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment_status",
      key: "sentiment_status",
      render: (handed_off, record) => {
      let iconColor, iconName, textColor;
      switch (handed_off) {
        case "Positive":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "Negative":
            iconColor = "#FF2441";
            iconName = "ion:sad";
            textColor = "#FF2441";
            break;
          default:
            iconColor = "black";
            iconName = "ph:smiley-fill";
      }

      return (
        <>
          <div style={{ whiteSpace: "nowrap" }}>
            <Popconfirm
              className="record-dashboard-popup"
              placement="top"
              title=""
               description=<MarkdownToJSX>
              {record.sentiment}</MarkdownToJSX>
              footer={false}
              okText=""
              cancelText=""
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              {handed_off && (
                <>
                  <span>
                    <Icon
                      icon={iconName}
                      style={{ color: iconColor, fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>
                  <span
                    style={{ color: textColor }}
                    className="smiley-text cursor"
                  >
                    {capitalizeWords(handed_off)}
                  </span>
                </>
              )}
            </Popconfirm>
          </div>
        </>
      );
    },
    },
    {
      title: "Action Item",
      dataIndex: "action_items",
      key: "action_items",
      align: "center",
      render: (summary, record) => {
        return (
          <>
            {summary != null && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="eos-icons:role-binding-outlined"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
  
      // {
      //   title: 'Resume',
      //   dataIndex: 'url',
      //   key: 'url',
      //   render: (text) => (
      //       <Tooltip title={text}>
      //       <a href={text} target="_blank" rel="noopener noreferrer">
      //         <span style={{ display: 'inline-block', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //           {/* {text ? (`${text.slice(0, 15)}...`):("")} */}
      //           <Icon icon="vscode-icons:file-type-pdf2" style={{fontSize:"20px"}}/>
      //         </span>
      //       </a>
      //     </Tooltip>
      //     ),
      // },
      {
        title: " ",
        key: "action",
        render: (text, record, index) => (
          <Space size="middle">

<Link  className="view-more"
              
              style={{ whiteSpace: "nowrap", textDecoration: "underline" }} to={`/postchat/${record.conversation_id}`} >View more</Link>

            
          </Space>
        ),
        onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
      },
  ];
  const handlehistory = (record) => {
    setRecords(record);
    setHistoryOpen(true);
  };
  const handleCancel = () => {
    setHistoryOpen(false);
  };
  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="dashboard-board-background-table mobile-background"
        >
          <Table
            dataSource={filteredRecords}
            columns={columns}
            scroll={{ x: 1500 }}
            className="record-dashboard-table"
          />
        </Col>
      </Row>
      <Modal
        open={historyopen}
        footer={false}
        onCancel={handleCancel}
        className="report-table-modal"
        width={1000}
      >
        <Historymodal records={records} />
      </Modal>
    </>
  );
};
export default LatestJdRecords;
