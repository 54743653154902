import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import RecordDesktopBoard from "./Desktop/RecordDesktopBoard";
import RecordMobileboard from "./Mobile/RecordMobileboard";
const RecordsBody = () => {
  return (
    <>
      <div className="conversation-desktop">
        <RecordDesktopBoard />
      </div>
      <div className="conversation-mobile">
        <RecordMobileboard />
      </div>
    </>
  );
};
export default RecordsBody;
