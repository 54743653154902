import { memo, useState } from "react";
import { Drawer, Collapse } from "antd";
import AvatarAbout from "./AvatarAbout";
import { Icon } from "@iconify/react";
import LibraryList from "./LibraryList";
import TrendingList from "./TrendingList";
import HistoryList from "./HistoryList";
import SharedHistory from "./SharedHistory";

const AvatarAboutDrawer = (props) => {
  const getPromptsContent = (prompts) => {
    //
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <Icon icon="bi:bookmark" className="about-header-icon-library" />
          <span className="margin-left-1">Library</span>
        </>
      ),
      children: (
        <LibraryList promptslist={[]} getPromptsContent={getPromptsContent} />
      ),
    },
    {
      key: "2",
      label: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
          >
            <path d="M20.1757 8.32404C20.0026 8.15576 19.7597 8.07923 19.5218 8.11838C19.2836 8.15733 19.0779 8.30688 18.9674 8.52148C18.5873 9.25979 18.1046 9.93828 17.5366 10.5381C17.5933 10.1048 17.6217 9.66893 17.6217 9.23191C17.6217 8.39331 17.5094 7.53033 17.2876 6.6668C16.5585 3.83016 14.6467 1.43115 12.0423 0.0850403C11.8157 -0.0321628 11.5454 -0.027948 11.3223 0.0960091C11.0992 0.220068 10.9531 0.447517 10.9331 0.701931C10.7301 3.27979 9.40224 5.62512 7.28811 7.138C7.26008 7.15816 7.2323 7.17857 7.20453 7.19888C7.14704 7.24103 7.09276 7.28079 7.04213 7.31421C7.03421 7.31949 7.02634 7.32487 7.01857 7.33041C5.68891 8.2824 4.59178 9.55143 3.8457 11.0007C3.08754 12.4748 2.70312 14.0567 2.70312 15.7023C2.70312 16.5406 2.8155 17.4035 3.03716 18.2673C4.20691 22.8202 8.30415 25.9999 13.0009 25.9999C18.6788 25.9999 23.298 21.3804 23.298 15.7023C23.298 12.9022 22.1892 10.2819 20.1757 8.32404ZM13.0009 24.4765C8.9998 24.4765 5.50935 21.7673 4.51277 17.8884C4.32289 17.1485 4.22656 16.4129 4.22656 15.7023C4.22656 14.3014 4.5542 12.954 5.20029 11.6978C5.83409 10.4667 6.76562 9.38781 7.89485 8.57678C7.97143 8.52565 8.04222 8.47375 8.10518 8.42764C8.12951 8.40976 8.15393 8.39189 8.17663 8.37554C10.3277 6.8362 11.7856 4.55927 12.2892 2.00569C14.0227 3.19935 15.2831 4.98746 15.8122 7.04593C16.002 7.78556 16.0984 8.52108 16.0984 9.23196C16.0984 10.2729 15.9076 11.3086 15.5313 12.3101C15.4162 12.6164 15.5096 12.9619 15.7633 13.1686C16.017 13.3751 16.3741 13.3967 16.6509 13.2221C17.9076 12.429 18.9758 11.3764 19.7834 10.1429C21.0739 11.7077 21.7746 13.6484 21.7746 15.7023C21.7746 20.5404 17.8387 24.4765 13.0009 24.4765Z" />
          </svg>
          <span className="margin-left-1">Trending</span>
        </>
      ),
      children: (
        <TrendingList promptslist={[]} getPromptsContent={getPromptsContent} />
      ),
    },
    {
      key: "3",
      label: (
        <>
          <Icon icon="solar:history-bold" className="about-header-icon" />{" "}
          <span className="margin-left-1">History</span>
        </>
      ),
      children: <HistoryList />,
    },
    {
      key: "4",
      label: (
        <>
          <Icon icon="solar:share-outline" className="about-header-icon" />{" "}
          <span className="margin-left-1">Shared</span>
        </>
      ),
      children: <SharedHistory />,
    },
  ];

  return (
    <>
      <Drawer
        title={<AvatarAbout />}
        placement="right"
        onClose={() => {
          props.setDrawarStatus(false);
        }}
        open={props.drawerstatus}
        className="volvex-drawar"
        size="450px"
      >
        <Collapse
          className="volvex-avatar-list-collapse-drawar aivolvex-font"
          defaultActiveKey={["1"]}
          ghost
          items={items}
        />
      </Drawer>
    </>
  );
};

export default memo(AvatarAboutDrawer);
