import { memo, useEffect, useState } from "react";
import { Avatar } from "antd";
import { posttranscript } from "../../../api/accountAction";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PositiveImg from "../../Images/positive.svg"
import NegativeImg from "../../Images/negative.svg"
import NeutralImg from "../../Images/neutral.svg"
import { Icon } from "@iconify/react";
import TooltipSentiment from "../LCAFiles/TooltipSentiment";
import Tooltip from "../LCAFiles/ToolTip";
import OpportunityTooltip from "../LCAFiles/OpporunityTooltip";
import IssueTooltip from "../LCAFiles/IssueTooltip";

const PCATranscript = () => {
  const { conversation_id } = useParams();
  const dispatch = useDispatch();
  const [TranscriptData, setTranscriptData] = useState('')

  useEffect(() => {
    Transcript()
  }, [])

  const Transcript = () => {
    const input = {
      event_type: "transhis",
      conversation_id: conversation_id
    };
    dispatch(posttranscript(input))
      .unwrap()
      .then(({ data }) => {
       
        const response = JSON.parse(data.aivolvex_control_plane)
        setTranscriptData(response)
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <>

      <div className="aivolvex-dashboard-outline full-width">
        <div className="Dashboard_graph_box Dashboard_head_box_shadow action-item">

          <h4 className="dashboard-credit aivolvex-font font-size-title">
            Transcript
          </h4>
          <div className="post-call-transcript h173x  height-posttrans" >

            {Array.isArray(TranscriptData)

              ? TranscriptData.map((msg, index) => (

                <div key={index}>
                  {msg.type === "qus" ? (
                    <div className="d-flex justify-content-start mt-2">
                      <div>
                        <Avatar className="pca-chatbot-ai" size="large">
                          C
                        </Avatar>
                      </div>
                      <div>
                        <div className="pca-ai-message">
                          <span>{msg.transcript}</span>
                        </div>
                        <div className="d-flex justify-content-start ">

                          <TooltipSentiment title={<span>Negative</span>}>
                            {msg.overall_sentiment === "Negative" && (
                              <img src={NegativeImg} alt="Negative Sentiment Logo" />
                            )}
                          </TooltipSentiment>

                          <TooltipSentiment title={<span className="Opp-tooltip">Neutral</span>}>
                            {msg.overall_sentiment === "Neutral" && (
                              <img src={NeutralImg} alt="Neutral Sentiment Logo" />
                            )}
                          </TooltipSentiment>
                          <TooltipSentiment title={<span className="Opp-tooltip">Positive</span>}>
                            {msg.overall_sentiment === "Positive" && (
                              <img src={PositiveImg} alt="Positive Sentiment Logo" />
                            )}
                          </TooltipSentiment>
                          {msg.opportunity_status === "positive" && (

                            <Icon icon="ph:warning-circle-fill" width="1.7em" height="1.7em" className="green-warning" />

                          )}
                          {msg.opportunity_status === "negative" && (

                            <Icon icon="ph:warning-circle-fill" width="1.7em" height="1.7em" className="red-warning" />

                          )}
                          {msg.ticket_opportunity === "Yes" && msg.opportunity_status === "negative" && (
                            <Icon

                              className="ticket-color"
                              icon="typcn:ticket"
                              width="1.5rem"
                              height="1.5rem"
                            />
                          )}

                        </div>

                   
                      </div>
                    </div>
                  ) : (

                    <>

                      <div className="d-flex justify-content-end mt-2">

                        <div className="pca-ai-message-replay">
                          <span>{msg.transcript}</span>
                        </div>
                        <div>
                          <Avatar
                            className="pca-chatbot-ai-replay"
                            size="large"
                          >
                            A
                          </Avatar>
                        </div>


                      </div>
                      {msg.Ticket_Oppportunity === "Yes" && (
                        <Icon
                          className="ticket-client"
                          icon="typcn:ticket"
                          width="1.6rem"
                          height="1.6rem"
                        />
                      )}
                    </>
                  )}

                </div>
              ))
              : null}
          </div>
        </div>
      </div>
    </>

  );
};

export default memo(PCATranscript);
