import {
  gethistoryPageination,
  getHistorylist,
  updatehistory,
  deletehistory,
  getAvatarChatHistory,
} from "../../api/accountAction";
import { message } from "antd";
import jsPDF from "jspdf";

export const getdatacount = async (dispatch, input, setCount) => {
  try {
    const response = await dispatch(gethistoryPageination(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane)[0];
    setCount(value);
  } catch (error) {
    console.error(error);
  }
};
export const getdatarecord = async (
  dispatch,
  input,
  setPreloaderStatus,
  setRecord
) => {
  try {
    const response = await dispatch(getHistorylist(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);

    setPreloaderStatus(false);
    const table = value.map((datas) => {
      return {
        dateandtime: datas.timestamp,
        user_id: datas.user_email_id,
        prompt: datas.title,
        avatar_id: datas.avatar_id,
        session_id: datas.session_id,
      };
    });
    console.log(table);
    setRecord(table);
  } catch (error) {
    console.error(error);
    setPreloaderStatus(false);
  }
};
export const getupdatehistory = async (dispatch, input, setStatus) => {
  try {
    const response = await dispatch(updatehistory(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);
    setStatus(true);
  } catch (error) {
    console.error(error);
  }
};
export const getdeletehistory = async (dispatch, input, setStatus) => {
  try {
    const response = await dispatch(deletehistory(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);
    setStatus(true);
    message.success("Deleted");
  } catch (error) {
    console.error(error);
  }
};
export const getAvatarChatHistorypdf = async (dispatch, input) => {
  try {
    const data = await dispatch(getAvatarChatHistory(input)).unwrap();
    const value = JSON.parse(data.aivolvex_control_plane);
    const pdfrecord = value;
    const randomFilename = Math.floor(Math.random() * 1000000).toString();
    const filename = `Aivolvex-history-${randomFilename}.pdf`;
    const doc = new jsPDF();
    const maxWidth = 160;
    let yPosition = 10;
    pdfrecord.forEach((record, index) => {
      const { question, answer, timestamp, user_id, avatar_name } = record;
      const lines = doc.splitTextToSize(answer, maxWidth);
      const answerHeight = lines.length * 10;
      if (yPosition + answerHeight + 50 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = 10;
      }
      // doc.addImage(svgBase64, 'PNG', 10, 10, 20, 20);
      doc.text(`Record ${index + 1}:`, 10, yPosition);
      if (user_id) {
        doc.text(`${user_id}: ${question}`, 20, yPosition + 10);
      } else {
        doc.text(`Question: ${question}`, 20, yPosition + 10);
      }

      if (avatar_name) {
        doc.text(`${avatar_name}:`, 20, yPosition + 20);
      } else {
        doc.text(`Answer:`, 20, yPosition + 20);
      }

      const date = new Date(timestamp);
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }
      function formatAMPM(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${padZero(minutes)}${ampm}`;
      }
      const formattedTimestamp = `${padZero(date.getDate())}-${padZero(
        date.getMonth() + 1
      )}-${date.getFullYear()} ${formatAMPM(date)}`;
      doc.text(lines, 20, yPosition + 30);
      doc.text(
        `Date and Time:${formattedTimestamp}`,
        20,
        yPosition + 30 + answerHeight
      );
      yPosition += answerHeight + 50;
    });
    doc.save(filename);
  } catch (error) {
    console.error(error);
  }
};
// export const gethistoryofchat = async (dispatch, input) => {
//   try {
//     const response = await dispatch(getAvatarChatHistory(input)).unwrap();
//     const value = JSON.parse(response.data.aivolvex_control_plane);
//
//   } catch (error) {
//     console.error(error);
//   }
// };
