import { memo, useEffect, useState } from "react";
import { Input, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAvatarHistoryTitle,
  updatehistory,
  deletehistory,
} from "../../api/accountAction";
import { useDispatch } from "react-redux";
import preloader from "../../../assets/loader/pre_loader.gif";
import { IoTrashBin } from "react-icons/io5";

const HistoryList = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  // status
  const [preloaderstatus, setPreloaderStatus] = useState(true);
  const [editIndex, setEditIndex] = useState(-1);
  const [promptslist, setPromptsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [editstatus, setEditstatus] = useState(
    Array(promptslist.length).fill(false)
  );
  const [update, setUpdate] = useState(false);
  //user information
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  //effect
  useEffect(() => {
    setPreloaderStatus(true);
    const input = {
      id: id,
      user_id: userName,
      companyname: companyName,
    };
    dispatch(getAvatarHistoryTitle(input))
      .unwrap()
      .then(({ data }) => {
        setPreloaderStatus(false);
        const records = JSON.parse(data.aivolvex_control_plane);

        if (records.length > 0) {
          setPromptsList(records);
        } else {
          setPromptsList([]);
        }
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [id, update]);
  const filteredPrompts = promptslist.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(() => {
    if (editIndex !== -1) {
      setInputValue(promptslist[editIndex].title);
    }
  }, [editIndex, promptslist]);

  //update
  const handleApplyClick = (index, inputValue, conversation_id) => {
    //
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = false;
    setEditstatus(updatedEditStatus);
    setEditIndex(-1);
    const newData = [...promptslist];
    newData[index].title = inputValue;
    const input = {
      user_id: userName,
      company: companyName,
      user_session: conversation_id,
      avatar_id: id,
      title: inputValue,
    };

    // setPromptsList(newData);
    dispatch(updatehistory(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setUpdate(!update);
      })
      .catch((err) => {
        // message.error(err);
      });
  };
  const handleCancelClick = (index) => {
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = false;
    setEditstatus(updatedEditStatus);
    setEditIndex(-1);
  };
  const handleEditClick = (index) => {
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = true;
    setEditstatus(updatedEditStatus);
    setEditIndex(index);
  };
  //delete
  const handleDeleteClick = (conversationId) => {
    const input = {
      user_id: userName,
      company: companyName,
      user_session: conversationId,
    };
    dispatch(deletehistory(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setUpdate(!update);
      })
      .catch((err) => {});
  };
  const getPromptsContent = (conversation_id) => {
    navigateFn("/conversation/" + id + "/" + conversation_id);
  };

  return (
    <>
      <div className="aivolvex-font">
        {preloaderstatus ? (
          <>
            <div className="aivolvex-sub-loader">
              <img src={preloader} width="20%" />
            </div>
          </>
        ) : (
          <>
            {/* {filteredPrompts.length > 0 ? ( */}
            <>
              <div className="history-search">
                <Input
                  placeholder="Search here"
                  prefix={
                    <Icon
                      icon="material-symbols:search"
                      className="lib-search-icon"
                    />
                  }
                  className="lib-history volvex-search-box"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </>
            {/* // ) : ( // "No Data" // )} */}
            <div className="side-scroll-container-prompts">
              <>
                {filteredPrompts.length > 0 &&
                  filteredPrompts.map((data, index) => {
                    const currentInputValue =
                      editIndex === index ? inputValue : data.title;
                    return (
                      <>
                        <div key={index}>
                          <button
                            class="button-primary-questions  btn btn-primary fontSize10"
                            role="button"
                            // onClick={() => {
                            //   getPromptsContent(data.conversation_id);
                            // }}
                          >
                            <div className="btn-main-box d-flex justify-content-between">
                              <div className="btn-prompts-content">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    className="margin-right-0-5"
                                  >
                                    <g clip-path="url(#clip0_619_4134)">
                                      <path
                                        d="M13.5602 0C13.5529 0.00723411 13.5385 0.00723411 13.5168 0.00723411C6.19586 0.00723411 0.242188 5.9609 0.242188 13.2818C0.242188 16.2695 1.2622 19.1704 3.1286 21.507L1.19709 25.9921C1.03794 26.3611 1.21156 26.7879 1.57326 26.9398C1.70348 26.9977 1.84816 27.0122 1.98561 26.9904L9.0678 25.7462C10.4857 26.2598 11.9759 26.5202 13.4806 26.513C20.8015 26.513 26.7552 20.5593 26.7552 13.2384C26.7697 5.9392 20.8594 0.00723411 13.5602 0ZM13.4878 25.0734C12.0917 25.0734 10.7099 24.8202 9.40057 24.3283C9.27759 24.2777 9.14737 24.2704 9.01716 24.2921L3.05625 25.3338L4.63329 21.6661C4.7418 21.413 4.6984 21.1164 4.51754 20.9066C3.66392 19.9155 2.98391 18.787 2.50646 17.5644C1.97114 16.1972 1.69624 14.7431 1.69624 13.2746C1.69624 6.74942 7.00608 1.44682 13.524 1.44682C20.0275 1.43235 25.3084 6.69155 25.3156 13.195C25.3156 13.2095 25.3156 13.2239 25.3156 13.2384C25.3156 19.7708 20.0058 25.0734 13.4878 25.0734Z"
                                        fill="white"
                                        fill-opacity="0.45"
                                      />
                                      <path
                                        d="M12.9267 10.6124H9.02029C8.62241 10.6124 8.29688 10.938 8.29688 11.3358C8.29688 11.7337 8.62241 12.0592 9.02029 12.0592H12.9267C13.3246 12.0592 13.6501 11.7337 13.6501 11.3358C13.6501 10.938 13.3246 10.6124 12.9267 10.6124Z"
                                        fill="white"
                                        fill-opacity="0.45"
                                      />
                                      <path
                                        d="M17.9906 14.2295H9.02029C8.62241 14.2295 8.29688 14.555 8.29688 14.9529C8.29688 15.3508 8.62241 15.6763 9.02029 15.6763H17.9906C18.3885 15.6763 18.714 15.3508 18.714 14.9529C18.714 14.555 18.3885 14.2295 17.9906 14.2295Z"
                                        fill="white"
                                        fill-opacity="0.45"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_619_4134">
                                        <rect
                                          width="27"
                                          height="27"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                {editIndex === index ? (
                                  <Input
                                    value={currentInputValue}
                                    bordered={false}
                                    className="edit-input-filed volvex-search-box"
                                    onChange={(e) =>
                                      setInputValue(e.target.value)
                                    }
                                  />
                                ) : (
                                  <>
                                    <div
                                      className="history-title-section"
                                      // onClick={props.handleclose}
                                      onClick={() => {
                                        getPromptsContent(data.conversation_id);
                                      }}
                                    >
                                      {data.title?.length > 70 ? (
                                        <>{data.title?.substring(0, 70)}... </>
                                      ) : (
                                        <>{data.title}</>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="btn-prompts-token">
                                <span className="d-flex justify-content-between">
                                  {editIndex === index ? (
                                    <>
                                      <span>
                                        <Icon
                                          icon="charm:tick"
                                          className="prompts-history-icon"
                                          onClick={() =>
                                            handleApplyClick(
                                              index,
                                              inputValue,
                                              data.conversation_id
                                            )
                                          }
                                        />
                                      </span>
                                      <span>
                                        <Icon
                                          icon="ph:x-bold"
                                          className="prompts-history-icon"
                                          onClick={() =>
                                            handleCancelClick(index)
                                          }
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        <Icon
                                          icon="wpf:edit"
                                          className="prompts-history-icon"
                                          onClick={() => handleEditClick(index)}
                                        />
                                      </span>
                                      <span>
                                        <Popconfirm
                                          title="Are you sure you want to delete this item?"
                                          onConfirm={() =>
                                            handleDeleteClick(
                                              data.conversation_id
                                            )
                                          }
                                          placement="bottomLeft"
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <IoTrashBin className="prompts-history-icon" />
                                        </Popconfirm>
                                      </span>
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                          </button>
                        </div>
                      </>
                    );
                  })}
              </>
              {filteredPrompts.length > 0 && (
                <div className="d-flex justify-content-center">
                  <label
                    className="cursor-pointer volvex-conversation-history-view"
                    onClick={() => {
                      navigateFn("/history");
                    }}
                  >
                    view more
                  </label>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(HistoryList);
