import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

const TextToAudio = (props) => {
  const synthesis = window.speechSynthesis;
  const [audiostatus, setAudioStatus] = useState(false);

  useEffect(() => {
    synthesis.onvoiceschanged = () => {
      // Ensure voices are loaded before speaking
      // You may need to modify this depending on your use case
      const voices = synthesis.getVoices();
      if (voices.length > 0) {
        synthesis.onvoiceschanged = null;
      }
    };
  }, []);

  const handleSpeak = () => {
    if (audiostatus) {
      if (synthesis.speaking || synthesis.pending) {
        synthesis.cancel();
        setAudioStatus(false);
      }
    } else {
      setAudioStatus(true);
      if (synthesis.speaking || synthesis.pending) {
        synthesis.cancel();
      }

      // Split the text into smaller chunks
      const chunkSize = 200; // Adjust this value based on your needs
      const chunks =
        props.records.match(new RegExp(`.{1,${chunkSize}}`, "g")) || [];

      // Specify the language and other voice characteristics
      // const voice = synthesis.getVoices().find((v) => v.lang === "ta-IN");

      // Speak each chunk sequentially
      chunks.forEach((chunk, index) => {
        const utterance = new SpeechSynthesisUtterance(chunk);
        // utterance.voice = voice;
        utterance.onend = () => {
          // If it's the last chunk, set audio status to false
          if (index === chunks.length - 1) {
            setAudioStatus(false);
          }
        };
        synthesis.speak(utterance);
      });
    }
  };

  return (
    <>
      <Icon
        icon="wpf:speaker"
        className={audiostatus ? "feedback-icon-active" : "feedback-icon feedback-icon-speaker"}
        onClick={handleSpeak}
      />
    </>
  );
};

export default TextToAudio;
