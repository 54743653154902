import "./App.scss";
import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
// import Dashboard from "./components/dashboard/Dashboard";
import Dashboardfile from "./components/DashboardNew/DashboardFile";
import Login from "./components/Login/Login";
import Library from "./components/Library/Library";
import ChartMain from "./components/ChartWindow/ChartMain";
// import History from "./components/History/History";
import History from "./components/History-v2/HistoryBody";
import OnboardAvatar from "./components/Avatar/OnboardAvatar";
import Favorites from "./components/Favorites/Favorites";
// import RecentPrompts from "./components/Recent-Prompts/RecentPrompts";
import RecentPrompts from "./components/Recent-Prompts-v2/RecentPromptsBody";
import AzureAuth from "./components/Azure_auth/AzureAuth";
import Launch from "./components/Launch/Launch";
import Rolemainbody from "./components/Role/Rolemainbody";
import GPT from "./components/gpt/GPT";
import SourcePDFView from "./components/ChartWindow/SourcePDFView";
import MobileSignin from "./components/Mobile/MobileSignin";
import RecordsBody from "./components/Records/RecordsBody";
//
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import Register from "./components/Login/Register";
import ConversationBody from "./components/Conversation/ConversationBody";
import Profile from "./components/Profile/Profile";
import Tokenmenu from "./components/Token/Tokenmenu";

// import LiveCallCCI from "./components/LiveCallCCI/Desktop/LiveCallPage"
import LiveCallCCI from "./components/LiveCallCCI/Desktop/LCAFiles/LiveCallPageMain";
import PCAMainPage from "./components/LiveCallCCI/Desktop/PCAFiles/PCAMainPage";
import UnderConstruction from "./components/Login/UnderConstruction";
import { profilelistdatakey } from "./components/api/accountAction";
import { useDispatch } from "react-redux";
import preloader from "./assets/loader/pre_loader.gif";
import { Col, Row } from "antd";
import QSearchBar from "./components/QSearchBar/QSearchBar";
import StreamingTest from "./components/customhook/StreamingTest";
import { deleteCookie } from "./components/customhook/CustomHoom";
import GoogleOauth from "./components/GoogleOauth";
import { BsThreeDots } from "react-icons/bs";
// import ChatBotMainFile from "./components/LiveCallCCI/Desktop/ChatSummary/ChatBotMainFile";
// import ChatBotMainFile from "./components/LiveCallCCI/Desktop/ChatSummary/ChatBotMainFile";
// import ChatBotMainFile from "./components/LiveCallCCI/Desktop/ChatSummary/ChatBotMainFile";
// import ChatBotMainFile from "./components/LiveCallCCI/Desktop/Chatsummary/ChatBotMainFile";
import ChatBotMainFile from "./components/LiveCallCCI/Desktop/ChatSummary/ChatBotMainFile";


Amplify.configure(awsExports);

//components path
// const Login = React.lazy(() => import("./components/Login/Login"));
// const Library = React.lazy(() => import("./components/Library/Library"));
// const ChartMain = React.lazy(() =>
//   import("./components/ChartWindow/ChartMain")
// );

const router = [
  // {
  //   path: "/",
  //   element: <Login />,
  // },
  // {
  //   path: "/signin",
  //   element: <Login />,
  // },
  // {
  //   path: "/launch",
  //   element: <Launch />,
  // },
  {
    path: "/avatarhub/enterprise",
    element: <Library pagetype="enterprice" />,
  },
  {
    path: "/avatarhub/general",
    element: <Library pagetype="general" />,
  },
  {
    path: "/chat/:id",
    element: <ChartMain />,
  },
  // {
  //   path: "/chat/:id/vertexai",
  //   element: <ChartMain />,
  // },
  {
    path: "/chat/:id/:conversationId",
    element: <ChartMain />,
  },
  {
    path: "/dashboard",
    element: <Dashboardfile />,
  },

  // {
  //   path: "/dashboardfile",
  //   element: <Dashboardfile />,
  // },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/onboard_avatar",
    element: <OnboardAvatar />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/recent_prompts",
    element: <RecentPrompts />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
  {
    path: "/role",
    element: <Rolemainbody />,
  },
  {
    path: "/gpt",
    element: <GPT />,
  },
  {
    path: "/pdf/:url",
    element: <SourcePDFView />,
  },
  {
    path: "/mobilesignin",
    element: <MobileSignin />,
  },
  {
    path: "/conversation",
    element: <ConversationBody />,
  },
  {
    path: "/conversation/:id",
    element: <ConversationBody />,
  },
  {
    path: "/conversation/:id/:conversationId",
    element: <ConversationBody />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/token",
    element: <Tokenmenu />,
  },
  {
    path: "/public-agent",
    element: <RecordsBody />,
  },
  {
    path: "/public-agent/:id",
    element: <RecordsBody />,
  },
  {
    path:"/live-agent",
    element: <ChatBotMainFile />,
  },
  {
    path:"/live-agent/:id",
    element: <ChatBotMainFile />,
  },
 
  {
    path: "/teststreaming",
    element: <StreamingTest />,
  },
  // {
  //   path: "/qsearchbar",
  //   element: <QSearchBar />,
  // },
  {
    path: "/oauth-testing",
    element: <GoogleOauth />,
  },
  {
    path: "/livecall",
    element: <LiveCallCCI />,
  },
  {
    path: "/postchat/:conversation_id",
    element: <PCAMainPage />,
  },
  // {
  //   path: "/chatsummary",
  //   element: <ChatSummaryMain />,
  // },

];

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [location]);
};

function App() {
  const dispatch = useDispatch();
  const [loaderstatus, setLoaderStatus] = useState(false);
  useEffect(() => {
    setLoaderStatus(true);
    const inputid = {
      keyid: "company_info",
      meta_key: "website_theme",
      user_id: "",
    };
    dispatch(profilelistdatakey(inputid))
      .unwrap()
      .then(({ data }) => {
        console.log("test");
        const responce = JSON.parse(data.aivolvex_control_plane);
        if (responce[0].meta_value !== "default") {
          const inputid = {
            keyid: responce[0].meta_value,
            meta_key: "",
            user_id: "",
          };
          dispatch(profilelistdatakey(inputid))
            .unwrap()
            .then(({ data }) => {
              const theme_responce = JSON.parse(data.aivolvex_control_plane);
              theme_responce.map((theme_res_data) => {
                document.documentElement.style.setProperty(
                  "--" + theme_res_data.meta_key,
                  theme_res_data.meta_value
                );
              });
              setLoaderStatus(false);
            })
            .catch((err) => {
              setLoaderStatus(false);
            });
        } else {
          setLoaderStatus(false);
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
      });

    // conversation cookies empty
    deleteCookie("conversation_start");
  }, []);
  //start theme
  // document.documentElement.style.setProperty(
  //   "--theme_primary_color",
  //   "#161616"
  // );
  // document.documentElement.style.setProperty(
  //   "--theme_background_color",
  //   "#f5f5f5"
  // );
  // document.documentElement.style.setProperty(
  //   "--theme_secondary_color",
  //   "#4D4D4F"
  // );
  // document.documentElement.style.setProperty(
  //   "--btn_and_active_color",
  //   "#ECCEA8"
  // );
  // document.documentElement.style.setProperty("--title_font_color", "#000");
  // document.documentElement.style.setProperty(
  //   "--con_title_and_input_color",
  //   "#ececf0"
  // );
  // document.documentElement.style.setProperty("--con_font_color", "#000");
  // document.documentElement.style.setProperty("--font_primary_color", "#fff");
  // document.documentElement.style.setProperty(
  //   "--font_secondary_color",
  //   "#646572"
  // );
  //
  //end theme

  const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
    : "";
  return (
    <div className="App">
      {loaderstatus ? (
        <Row>
          <Col>
            <div className="aivolvex-sub-loader full-wh">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <BrowserRouter>
            <ScrollToTop />
            {/* under construction */}
            {/* <>
          <Routes>
            <Route path="/" element={<UnderConstruction />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </> */}
            {userName ? (
              <>
                <Routes>
                  {router.map((val) => (
                    <Route
                      path={val.path}
                      key={val.path}
                      element={val.element}
                    />
                  ))}
                  <Route
                    path="*"
                    element={<Navigate replace to="/conversation" />}
                  />
                </Routes>
              </>
            ) : (
              <>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/signup" element={<Register />} />
                  <Route path="/azure-auth" element={<AzureAuth />} />
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </>
      )}
    </div>
  );
}

export default App;
