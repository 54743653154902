import { memo, useEffect, useState } from "react";
import HistoryBodyDesktop from "./Desktop/HistoryBodyDesktop";
import HistoryBodyMobile from "./Mobile/HistoryBodyMobile";
const HistoryBody = () => {
    return (
        <>
            <div className="conversation-desktop">
                <HistoryBodyDesktop />
            </div>
            <div className="conversation-mobile mobile-background">
                <HistoryBodyMobile />
            </div>
        </>
    )
}
export default HistoryBody;