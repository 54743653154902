import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Modal, Input, Upload, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FeedbackModel from "./Feedbackmodel";
import Chartgraph from "./Chartgraph";
import { Icon } from "@iconify/react";
import jsPDF from "jspdf";
import TextToAudio from "./TextToAudio";
import html2canvas from "html2canvas";
import "../CSS/conversation.scss";
import EmailInput from "./Email";
const Chatsubitems = (props) => {
  const index = props.index;
  const data = props.data;
  const type = props.type;
  const [copyconf, setCopyConf] = useState(false);
  const [graphmodal, setGraphmodal] = useState(false);
  const [graphrecord, setGraphrecord] = useState("");
  const [newavatar, setnewavatar] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [feedbackrecord, setFeedbackrecord] = useState();
  const [open, setOpen] = useState(false);
  const [mailbox, setMailbox] = useState(false);
  const handleCopyClick = (conversation) => {
    const plainText = extractPlainText(conversation);
    const textArea = document.createElement("textarea");
    textArea.value = plainText;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      setCopyConf(true);
      setTimeout(() => {
        setCopyConf(false);
      }, 2000);
      document.execCommand("copy");
    } catch (error) {
      console.error("Copy failed:", error);
    }

    document.body.removeChild(textArea);
  };
  const handlegraphclick = (record) => {
    // console.log(record);
    setGraphrecord(record);
    setGraphmodal(true);
  };
  const handlefeedback = (record) => {
    setOpen(true);
    setFeedbackrecord(record);
    setFeedback(true);
  };
  const extractPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };
  const wrapText = (pdf, text, x, y, maxWidth, lineHeight) => {
    const words = text.split(" ");
    let line = "";

    for (let i = 0; i < words.length; i++) {
      const testLine = line + (i > 0 ? " " : "") + words[i];
      const testWidth =
        pdf.getStringUnitWidth(testLine) * pdf.internal.getFontSize();

      if (testWidth > maxWidth) {
        pdf.text(x, y, line);
        y += lineHeight;
        line = words[i];
      } else {
        line = testLine;
      }
    }

    pdf.text(x, y, line);
  };
  const handlemailClick = (datatext) => {
    setMailbox(true);
  };
  const handleCancel = () => {
    setMailbox(false);
  };
  const handleDownloadPDF = (divName, aname) => {
    // const targetDiv = document.querySelector(`[name="${divName}`);
    const targetDiv = document.querySelector(`[name="${divName}"]`);
    if (type === "desktop") {
      if (targetDiv) {
        html2canvas(targetDiv).then((canvas) => {
          // html2canvas(targetDiv).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          const randomFilename = Math.floor(Math.random() * 1000000).toString();
          const filename = `AIVolveX-${randomFilename}.pdf`;
          pdf.save(filename);
        });
      }
    } else {
      const options = {
        width: 220,
        // height: "100%",
      };
      console.log(targetDiv);
      if (targetDiv) {
        html2canvas(targetDiv, options).then((canvas) => {
          // html2canvas(targetDiv).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          const randomFilename = Math.floor(Math.random() * 1000000).toString();
          const filename = `AIVolveX-${randomFilename}.pdf`;
          pdf.save(filename);
        });
      }
    }
    // const canvas = document.createElement("canvas");
  };

  return (
    <>
      <div>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <TextToAudio records={data.text} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {copyconf ? (
              <Icon icon="charm:tick" className="feedback-icon-mobile" />
            ) : (
              <Icon
                icon="ic:round-file-copy"
                className="feedback-icon-mobile"
                onClick={() => handleCopyClick(data.text)}
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Icon
              icon="mdi:graph-box"
              className="feedback-icon-mobile cursor-pointer"
              onClick={() => handlegraphclick(data.text)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Icon
              icon="vscode-icons:file-type-pdf2"
              className="feedback-icon-mobile"
              onClick={() => {
                setnewavatar(data.avatar_name);
                if (type === "desktop") {
                  handleDownloadPDF(
                    `content-to-convert-${index}d`,
                    data.avatar_name
                  );
                } else {
                  handleDownloadPDF(
                    `content-to-convert-${index}m`,
                    data.avatar_name
                  );
                }
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Icon
              icon="vscode-icons:file-type-outlook"
              className="feedback-icon-mobile"
              // onClick={() => {
              //   const owaComposeURL =
              //     "https://outlook.office365.com/owa/?path=/mail/action/compose";
              //   const subject = "Aivolvex";
              //   const body = data.text;
              //   const encodedSubject = encodeURIComponent(subject);
              //   const encodedBody = encodeURIComponent(body);
              //   const popupWidth = 600;
              //   const popupHeight = 400;
              //   const left = window.innerWidth / 2 - popupWidth / 2;
              //   const top = window.innerHeight / 2 - popupHeight / 2;
              //   const finalURL = `${owaComposeURL}&subject=${encodedSubject}&body=${encodedBody}`;
              //   const popupWindow = window.open(
              //     finalURL,
              //     "_blank",
              //     `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
              //   );
              //   if (popupWindow) {
              //     popupWindow.focus();
              //   }
              // }}
              onClick={() => {
                handlemailClick(data.text);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Icon
              icon="iconamoon:like-thin"
              className="feedback-icon-mobile"
              onClick={() => handlefeedback(index)}
            />
          </Col>
        </Row>
      </div>
      <Modal
        open={graphmodal}
        footer={false}
        onCancel={() => setGraphmodal(false)}
      >
        <Chartgraph graphrecord={graphrecord} />
      </Modal>
      <div>
        <Modal
          className="outlook-modal"
          open={mailbox}
          footer={false}
          onCancel={handleCancel}
        >
          <EmailInput
            recorddata={data.text}
            setMailbox={setMailbox}
            mailbox={mailbox}
          />
        </Modal>
      </div>
      {feedbackrecord && (
        <FeedbackModel
          open={open}
          setOpen={setOpen}
          avatarid={feedbackrecord}
          conversaction_id={data.conversection_id}
        />
      )}
    </>
  );
};
export default Chatsubitems;
