import React from "react";
import { Collapse, Col, Row } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../components/Records/CSS/record.scss";
import LatestThreeWayAvatarList from "./LatestThreeWayAvatarList";
import { liveagentdash } from "../../../api/accountAction";
import preloader from "../../../../assets/loader/pre_loader.gif";

const LatestPrivatelive = () => {
  const dispatch = useDispatch();
  const [podlist, setPodList] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [notpinnedavatar, setNotPinnedAvatar] = useState([]);
  const { pinnedstatus } = useSelector((state) => state.chat);

  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

//   useEffect(() => {
//     if (notpinnedavatar.length > 0) {
//       const input = {
//         company: companyName,
//         user_id: userName,
//         type: "public",
//       };
//       dispatch(listPinned(input))
//         .unwrap()
//         .then(({ data }) => {
//           //
//           setPreloaderStatus(false);
//           const all_pod = [
//             {
//               key: notpinnedavatar.length,
//               label: "Pinned Agent",
//               children: (
//                 <AvatarList
//                   avatarlist={JSON.parse(data.aivolvex_control_plane)}
//                 />
//               ),
//             },
//           ].concat(notpinnedavatar);
//           setPodList(all_pod);
//         })
//         .catch((err) => {
//           // message.error(err);
//         });
//     }
//   }, [notpinnedavatar, pinnedstatus]);


  useEffect(() => {
    // const input = {
    //   event_type: "public_avatar_details",
    // };
    const input = {
      company_name: companyName,
      user_id: userName,
      event_type: "livedash",
    };
    setPreloaderStatus(true);
    dispatch(liveagentdash(input))
      .unwrap()
      .then(({ data }) => {
        if (JSON.parse(data.aivolvex_control_plane).length > 0) {
          var all_pod_list = JSON.parse(data.aivolvex_control_plane).map(
            (v) => v.pod
          );
          const pod = new Set([...all_pod_list]);
          // setRecords(JSON.parse(data.aivolvex_control_plane));
          const item_list = [...pod].map((pod_name, index) => {
            return {
              key: index,
              label: pod_name,
              children: (
                <LatestThreeWayAvatarList
                  avatarlist={JSON.parse(data.aivolvex_control_plane).filter(
                    (v, index) => v.pod === pod_name
                  )}
                />
              ),
            };
          });
          setPreloaderStatus(false);
          setPodList(item_list);
          setNotPinnedAvatar(item_list);
        } else {
          setPodList([]);
          // setRecords([]);
        }
      })
      .catch((err) => {});
  }, []);

  const items = podlist;
  const { agentname } = useSelector((state) => state.chat);
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Row>
            <Col
              span={24}
              className="white-color aivolvex-font d-flex justify-content-center p-1"
            >
              <h4>{agentname}</h4>
            </Col>
          </Row>
          <div className="dashboard-board-private">
            <Collapse items={items} expandIconPosition="right" />
          </div>
        </>
      )}
    </>
  );
};
export default memo(LatestPrivatelive);
