import { memo, useEffect, useState } from "react";
import React from "react";
import NavBar from "../../../Header/NavBar";
import { Col, Row, Space, Table } from "antd";
import Sidemenu from "../../../Sidemenu/Sidemenu";
import LiveCallPage from "./LiveCallPage";


const LiveCallPageMain = () => {
  return (
    <>
      <NavBar />
      <Row>
        <Col
          xs={24}
          sm={24}
          md={2}
          lg={2}
          xl={2}
          className="body-margin-top body-margin-bottom-mobile">
            
          <Sidemenu />
        </Col>
       
        <Col xs={24} sm={24} md={22} lg={22} xl={22}>
          <LiveCallPage />
        </Col>
      </Row>
    </>
  );
};
export default LiveCallPageMain;
