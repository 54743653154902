import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
import CreditUsage from "../Desktop/CreditUsage";
import PromptUsage from "../Desktop/PromptUsage";
import UsageTrend from "../Desktop/UsageTrend";
const DashboardMobile = () => {
  const { agentname } = useSelector((state) => state.chat);
  return (
    <>
      <Row>
        <Col span={24}>
          <Navbarmobile />
        </Col>
      </Row>
      <Row className="mobile-background">
        <Col
          span={24}
          className="white-color aivolvex-font d-flex justify-content-center p-1"
        >
          <h4>{agentname}</h4>
        </Col>
      </Row>
      <Row className="recent-mobile-body mobile-background">
        <Col span={24} className="p-2">
          <CreditUsage />
          <PromptUsage />
          <UsageTrend />
        </Col>
      </Row>
      <Row className="chat-bottom-section">
        <Col span={24}>
          <SidemenuMobile />
        </Col>
      </Row>
    </>
  );
};
export default DashboardMobile;
