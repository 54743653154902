import {
  Col,
  Row,
  Button,
  Drawer,
  Input,
  Tooltip,
  Dropdown,
  Tabs,
  DatePicker,
} from "antd";
import { Icon } from "@iconify/react";
import Conversationchart from "./Conversationchart";
import Handsoffchart from "./Handsoffchart";
import Promptandcountchart from "./Promptandcountchart";
import Customercountchart from "./Customercountchart";
import dashboardicon from "../../../assets/icons/dashboardicon.svg";
import dashboardicon2 from "../../../assets/icons/dashboardicon2.svg";
import SentimentPioChart from "./SentimentPioChart";
import ConversationPieChart from "./ConversationPieChart";
import LeadPieChart from "./LeadPieChart";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setPublicDashDate } from "../../Slice/reducer/chatReducer";
import { useEffect, useState } from "react";
import {
  publicCustomerCount,
  publicPromptCount,
} from "../../api/accountAction";
import { useParams } from "react-router-dom";

const Chatbotdashboardmain = () => {
  const dispatch = useDispatch();
  const monthFormat = "YYYY/MM";
  const currentThreeMonthDate = new Date();
  const year = currentThreeMonthDate.getFullYear();
  const month = (currentThreeMonthDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  const formattedDate = `${year}/${month}`;
  //

  const { publicdashdate } = useSelector((state) => state.chat);
  const date = new Date(publicdashdate);
  const monthChoice = date.getMonth() + 1;

  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    const defaultDate = `${new Date().getFullYear()}-${(
      new Date().getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`;
    value
      ? dispatch(setPublicDashDate(value.format(dateFormat)))
      : dispatch(setPublicDashDate(defaultDate));
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const [promptCount, setPromptCount] = useState({
    today_prompt_count: 0,
    monthly_prompt_count: 0,
  });
  const [customerCount, setCustomerCount] = useState({
    today_customer_count: 0,
    monthly_customer_count: 0,
  });
  const { id } = useParams();
  useEffect(() => {
    const input = {
      avatar_id: id,
      month: monthChoice.toString(),
    };

    // setPreloaderStatus(true);
    dispatch(publicPromptCount(input))
      .unwrap()
      .then(({ data }) => {
        setPromptCount(JSON.parse(data.aivolvex_control_plane));
      })
      .catch((err) => {});

    dispatch(publicCustomerCount(input))
      .unwrap()
      .then(({ data }) => {
        setCustomerCount(JSON.parse(data.aivolvex_control_plane));
      })
      .catch((err) => {});
  }, [id, monthChoice]);

  return (
    <>
      <div className="chatbot-dashboard-mobile mobile-background">
        {/* <div className="chatbot-dashboard-main-body"></div> */}
        <Row gutter={[16, 16]}>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="aivolvex-right mb-3"
          >
            <DatePicker
              className="dashboard-datepicker"
              defaultValue={dayjs(formattedDate, monthFormat)}
              format={monthFormat}
              picker="month"
              onChange={handleMonthChange}
              disabledDate={disabledDate}
            />
          </Col>
        </Row>
        <Row span={24} gutter={[16, 16]}>
          <Col xl={6} lg={6} md={12} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup">
              <Col span={24} className="chatbot-dashboard-card">
                <Row className="chatbot-dashboard-card-item">
                  <Button className="chatbot-dashboard-card-button">
                    <Icon
                      style={{ fontSize: "35px" }}
                      icon="uil:label-alt"
                      className="chatbot-dashboard-card-icon"
                    />
                  </Button>
                </Row>
                <Row className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item">
                  Today Conversation count
                </Row>
                <Row className="chatbot-dashboard-card-text-secondary chatbot-dashboard-card-item">
                  {promptCount.today_prompt_count}
                </Row>
                <Row className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item puple-font">
                  Conversation count (Month): {promptCount.monthly_prompt_count}
                </Row>
              </Col>
            </div>
          </Col>
          {/* <Col xl={5} lg={5} md={24} sm={24} xs={24}>
          <div className="aivolvex-chatbot-dashboard-outline-setup  ">
            <Col span={24} className="chatbot-dashboard-card">
              <Row className="chatbot-dashboard-card-item">
                <img src={dashboardicon2} />
              </Row>
              <Row className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item">
                Today Total token
              </Row>
              <Row className="chatbot-dashboard-card-text-secondary chatbot-dashboard-card-item">
                560
              </Row>
              <Row className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item puple-font">
                Total Prompts : 10980
              </Row>
            </Col>
          </div>
        </Col> */}
          <Col xl={6} lg={6} md={12} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup  ">
              <Col span={24} className="chatbot-dashboard-card">
                <Row className="chatbot-dashboard-card-item">
                  <Button className="chatbot-dashboard-card-button">
                    <Icon
                      style={{ fontSize: "26px" }}
                      icon="mynaui:users-group"
                      className="chatbot-dashboard-card-icon"
                    />
                  </Button>
                </Row>
                <Row className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item">
                  Today Total Customers
                </Row>
                <Row className="chatbot-dashboard-card-text-secondary chatbot-dashboard-card-item">
                  {customerCount.today_customer_count}
                </Row>
                <Row className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item puple-font">
                  Customers count (Month) :{" "}
                  {customerCount.monthly_customer_count}
                </Row>
              </Col>
            </div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup  ">
              <div className="chatbot-dashboard-card">
                <Col>
                  <Row>
                    {/* <Col span={12} className="chatbot-dashboard-card-text-primary chatbot-dashboard-card-item">
                                        <Row>Type of conversation</Row>
                                        <Row className="p-3">
                                            <Col span={4} >
                                                <Icon icon="material-symbols:circle" className="chart-icon-voice" />
                                            </Col>
                                            <Col span={16}>
                                                <span>Voice</span>
                                            </Col>
                                            <Col span={4}>
                                                <span>40%</span>
                                            </Col>
                                        </Row>
                                        <Row className="p-3 pt-2">
                                            <Col span={4}>
                                                <Icon icon="material-symbols:circle" className="chart-icon-chat" />
                                            </Col>
                                            <Col span={16}>
                                                <span>Chat</span>
                                            </Col>
                                            <Col span={4}>
                                                <span>60%</span>
                                            </Col>
                                        </Row>
                                    </Col> */}
                    <Col span={24} className="chatbot-dashboard-card-chart">
                      <Conversationchart />
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </Col>
        </Row>

        <br />
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup  ">
              <Col span={24} className="chatbot-dashboard-card">
                {/* <Handsoffchart /> */}
                <SentimentPioChart />
              </Col>
            </div>
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup  ">
              <Col span={24} className="chatbot-dashboard-card">
                {/* <Handsoffchart /> */}
                <ConversationPieChart />
              </Col>
            </div>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup  ">
              <Col span={24} className="chatbot-dashboard-card">
                {/* <Handsoffchart /> */}
                <LeadPieChart />
              </Col>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[16, 16]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="aivolvex-chatbot-dashboard-outline-setup  ">
              <Col span={24} className="chatbot-dashboard-card">
                <Promptandcountchart />
              </Col>
            </div>
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="aivolvex-chatbot-dashboard-outline-setup  ">
            <Col span={24} className="chatbot-dashboard-card">
              <Handsoffchart />
            </Col>
          </div>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="aivolvex-chatbot-dashboard-outline-setup  ">
            <Col span={24} className="chatbot-dashboard-card">
              <Customercountchart />
            </Col>
          </div>
        </Col>
      </Row> */}
      </div>
    </>
  );
};
export default Chatbotdashboardmain;
