import { Input } from "antd";
import { memo, useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { getAvatarFrequentlyPrompts } from "../../api/accountAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import preloader from "../../../assets/loader/pre_loader.gif";
import { setLibraryQuestion } from "../../Slice/reducer/chatReducer";

const TrendingList = (props) => {
  const contentRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  //state
  const [searchQuery, setSearchQuery] = useState("");
  const [preloaderstatus, setPreloaderStatus] = useState(true);
  const [promptslist, setPromptsList] = useState([]);
  //user information
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );

  const filteredPrompts = promptslist.filter((item) =>
    item.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    setPreloaderStatus(true);
    const input = {
      id: id,
      user_id: userName,
      companyname: companyName,
    };
    //
    dispatch(getAvatarFrequentlyPrompts(input))
      .unwrap()
      .then(({ data }) => {
        setPreloaderStatus(false);
        const records = JSON.parse(data.aivolvex_control_plane);
        const filter_records = records.filter(
          (data_check) =>
            data_check.message !== undefined &&
            data_check.message !== null &&
            data_check.message !== " "
        );
        //
        if (filter_records.length > 0) {
          setPromptsList(filter_records);
        } else {
          setPromptsList([]);
        }
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [id]);

  return (
    <>
      <div className="aivolvex-font">
        {preloaderstatus ? (
          <>
            <div className="aivolvex-sub-loader">
              <img src={preloader} width="20%" />
            </div>
          </>
        ) : (
          <>
            {/* {filteredPrompts.length > 0 && ( */}
            <>
              <div className="history-search">
                <Input
                  placeholder="Search here"
                  prefix={
                    <Icon
                      icon="material-symbols:search"
                      className="lib-search-icon"
                    />
                  }
                  className="lib-history volvex-search-box"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  bordered={false}
                />
              </div>
            </>
            {/* )} */}

            <div className="side-scroll-container-prompts">
              {filteredPrompts.length > 0 &&
                filteredPrompts.map((data) => {
                  return (
                    <button
                      class="button-primary-questions  btn btn-primary fontSize10"
                      role="button"
                      onClick={() => {
                        dispatch(setLibraryQuestion(data.message));
                      }}
                    >
                      <div className="btn-main-box d-flex justify-content-between">
                        <div className="btn-prompts-content">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 27 27"
                              fill="none"
                              className="margin-right-0-5"
                            >
                              <g clip-path="url(#clip0_619_4134)">
                                <path
                                  d="M13.5602 0C13.5529 0.00723411 13.5385 0.00723411 13.5168 0.00723411C6.19586 0.00723411 0.242188 5.9609 0.242188 13.2818C0.242188 16.2695 1.2622 19.1704 3.1286 21.507L1.19709 25.9921C1.03794 26.3611 1.21156 26.7879 1.57326 26.9398C1.70348 26.9977 1.84816 27.0122 1.98561 26.9904L9.0678 25.7462C10.4857 26.2598 11.9759 26.5202 13.4806 26.513C20.8015 26.513 26.7552 20.5593 26.7552 13.2384C26.7697 5.9392 20.8594 0.00723411 13.5602 0ZM13.4878 25.0734C12.0917 25.0734 10.7099 24.8202 9.40057 24.3283C9.27759 24.2777 9.14737 24.2704 9.01716 24.2921L3.05625 25.3338L4.63329 21.6661C4.7418 21.413 4.6984 21.1164 4.51754 20.9066C3.66392 19.9155 2.98391 18.787 2.50646 17.5644C1.97114 16.1972 1.69624 14.7431 1.69624 13.2746C1.69624 6.74942 7.00608 1.44682 13.524 1.44682C20.0275 1.43235 25.3084 6.69155 25.3156 13.195C25.3156 13.2095 25.3156 13.2239 25.3156 13.2384C25.3156 19.7708 20.0058 25.0734 13.4878 25.0734Z"
                                  fill="white"
                                  fill-opacity="0.45"
                                />
                                <path
                                  d="M12.9267 10.6124H9.02029C8.62241 10.6124 8.29688 10.938 8.29688 11.3358C8.29688 11.7337 8.62241 12.0592 9.02029 12.0592H12.9267C13.3246 12.0592 13.6501 11.7337 13.6501 11.3358C13.6501 10.938 13.3246 10.6124 12.9267 10.6124Z"
                                  fill="white"
                                  fill-opacity="0.45"
                                />
                                <path
                                  d="M17.9906 14.2295H9.02029C8.62241 14.2295 8.29688 14.555 8.29688 14.9529C8.29688 15.3508 8.62241 15.6763 9.02029 15.6763H17.9906C18.3885 15.6763 18.714 15.3508 18.714 14.9529C18.714 14.555 18.3885 14.2295 17.9906 14.2295Z"
                                  fill="white"
                                  fill-opacity="0.45"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_619_4134">
                                  <rect width="27" height="27" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span onClick={props.handleclose}>
                            {data.message}
                          </span>
                        </div>
                        {/* <div className="btn-prompts-token">
                        <span className="d-flex justify-content-between">
                          <span>100</span>{" "}
                          <img
                            src={aivolvex}
                            alt="logo"
                            className="token-footer-logo"
                          />
                        </span>
                      </div> */}
                      </div>
                    </button>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(TrendingList);
