import { Row, Col, Avatar,Divider } from "antd";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  listtranscript,
  historytranscript
} from "../../../api/accountAction";


function Callhistory(props) {
  const dispatch = useDispatch();
  const { PhoneNumberValue } = useSelector((state) => state.chat);
  const { SessionIdValue } = useSelector((state) => state.chat);
  const [pcaconversationid, setPCAConversationID] = useState([]);

  const { sessionId } = useParams();
  const { url } = useParams();

  const [CallhistoryData, setCallHistoryData] = useState([]);
  const [SummaryDetails, setSummaryDetails] = useState([]);
  const [summary, setsummaryvalue] = useState("");
  const [sentiment, setsentimentvalue] = useState("");
  const [QAcheck, setQACheck] = useState("");
  const [QACheckValue, setQACheckValue] = useState("");
  const [DateCallHistory, setDateCallHistory] = useState("");
  const [SecondsCallHistory, setSecondsCallHistory] = useState("");
  const [selectedRecordIndex, setSelectedRecordIndex] = useState(0);
  const [preloader2, setPreloader2] = useState(false);
  const [preloader1, setPreloader1] = useState(false);
  const [TitleChat, setTitleChat] = useState([])
  const [PreviousTranscript, setPreviousTranscript] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0);
  const customColors = ["#9E9E9E", "#048800", "#F94144"];
  let colorIndex = 0;
  const getNextColor = () => {
    const color = customColors[colorIndex];
    colorIndex = (colorIndex + 1) % customColors.length;
    return color;
  };
 

  const handleRowClick = (index, Temp_convo_id) => {
    setSelectedIndex(index);
    HistoryTranscript(Temp_convo_id);
  };
  const handleLinkClick = () => {
    window.open("https://example.com", "_blank");
  };

  function formatDate(dateString) {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
  
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', options);
  

    const [datePart, timePart] = formattedDate.split(', ');
    const [month, day, year] = datePart.split('/');
    const [time, period] = timePart.split(' ');
  
    return `${year}-${month}-${day} ${time} ${period}`;
  }
  

  useEffect(() => {
    ChatListed();
  }, [])

  const ChatListed = () => {
    const input = {
      event_type: "list_transcript",
      conversation_id: props.conversation_id
    };
    dispatch(listtranscript(input))
        .unwrap()
        .then(({ data }) => {
            const response = data
            setTitleChat(JSON.parse(response.aivolvex_control_plane))
            
            
            // setCustomerProfile(JSON.parse(response.aivolvex_control_plane))

      })
      .catch((err) => {
        console.log(err);
      });
  };



  const HistoryTranscript = (temp_id) => {
    const input = {
      event_type: "live_qa_transcript",
      temp_convo_id: temp_id,
    };
   
    dispatch(historytranscript(input))
      .unwrap()
      .then(({ data }) => {
        const response = data
        setPreviousTranscript(JSON.parse(response.aivolvex_control_plane))

      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (TitleChat.length > 0) {
      handleRowClick(0, TitleChat[0].Temp_convo_id);
    }
  }, [TitleChat]);


  const noData = TitleChat.length === 0 && PreviousTranscript.length === 0;
  return (
    <>
      {noData ? (
        <div className="no-data-found-container">
          <h1 className="no-data-found">No Data Found</h1>
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={24}
            sm={24}
            xs={24}
            className="px-2 mt-4 "
          >
            <div className="dashboard-call-card dashboard-card-history agent-card">
              <h4 className="dashboard-credit aivolvex-font font-size-title">
                Chat Title
              </h4>

              {TitleChat.map((data, index) => (
                <Row
                
                  onClick={() => handleRowClick(index, data.Temp_convo_id)}
                >
                  {data.Title && (
                    <Col span={4}
                    >
                      <Avatar style={{ backgroundColor: getNextColor() }} className="mt-3">
                        {data.Title.charAt(0)}
                      </Avatar>
                    </Col>
                  )}
                  <Col span={20}
                  >
                    <div key={index}
                  className={`py-1 history-contact mt-2 ${selectedIndex === index ? 'selected' : ''}`}>{data.Title}</div>
                    <div className="call-history-call">
                      {formatDate(data.Date)}
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
          <Divider type="vertical" className="div-chat" />
          <Col xxl={17} xl={17} lg={17} md={24} sm={24} xs={24} className="mt-4 scroll-ques">
            <h4 className="dashboard-credit aivolvex-font font-size-title">
              Chat History
            </h4>
            {Array.isArray(PreviousTranscript) && PreviousTranscript.length > 0 ? (
              PreviousTranscript.map((data, index) => (
                <Row className="history-contact" key={index}>
                  <Col span={3} className="dashboard-credit aivolvex-font">
                    Question
                  </Col>
                  <Col span={21}>
                    :&nbsp;{data.question}
                  </Col>
                  <Col span={3} className="dashboard-credit aivolvex-font">
                    Answer
                  </Col>
                  <Col span={21}>
                    :&nbsp;{data.answer}
                  </Col>
                </Row>
              ))
            ) : (
              <div>
                <p className="no-data mt-4 ms-2">No Data found!</p>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}
export default Callhistory;
