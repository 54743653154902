import {
  memo,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Col, Row, Avatar, Popover } from "antd";
import ChatListScroll from "./ChatListScroll";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
// import ceoimg from "../../../assets/ceo.png";
import preloader from "../../../assets/loader/pre_loader.gif";
import MarkdownToJSX from "markdown-to-jsx";
import DocumentViewSource from "./DocumentViewSource";
import { formatCurrentTime } from "../../customhook/CustomHoom";
import Chatsubitems from "./Chatsubitems";
import profile_image from "../../../assets/profile.png";
import {
  setLatestavatar,
  setStreamingRecord,
} from "../../Slice/reducer/chatReducer";

// start streaming
const id = process.env.REACT_APP_API_ID;
const region = process.env.REACT_APP_API_REGION;

const host = id + ".appsync-api." + region + ".amazonaws.com";
const url =
  "wss://" + id + ".appsync-realtime-api." + region + ".amazonaws.com";
var streamingstopstatus = false;
// end streaming

const ChatBody = forwardRef((props, ref) => {
  const [sourcemodel, setSourceModel] = useState(false);
  // const [streamingstopstatus, setStreamingStopStatus] = useState(false);
  const [streaming_id, setStreamingID] = useState(0);
  const [sourcerecords, setSourceRecords] = useState([]);
  const [completedstatus, setCompletedStatus] = useState({
    status: false,
    records: [],
  });
  const [streamingloader, setStreamingLoader] = useState(false);
  const { chatmessage } = useSelector((state) => state.chat);
  const parse = require("html-react-parser");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user_details = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.store")
  );
  const fontlevel = localStorage.getItem("AIVolvexConversation");
  const chatfont = `${fontlevel}px`;

  const [bodydata, setData] = useState("");

  function isMarkdownTableFormat(text) {
    const markdownTableRegex = /^\s*\|.*\|\s*$/m;
    return markdownTableRegex.test(text);
  }
  const customerLogo = localStorage.getItem("customer_logo");
  // const { username, userlogo } = useSelector((state) => state.chat);
  const customerFirstName = atob(localStorage.getItem("customer_first_name"));
  const currentTime = formatCurrentTime();
  console.log(customerFirstName,"customerFirstName")
  function findLastAvatarText(dataArray) {
    for (let i = dataArray.length - 1; i >= 0; i--) {
      if (dataArray[i].type === "avatar") {
        dispatch(setLatestavatar(dataArray[i].text));
        // console.log("Last avatar text value:", dataArray[i].text);
        return; // Stop iterating once found
      }
    }
  }
  findLastAvatarText(chatmessage);

  // start streaming
  useImperativeHandle(ref, () => ({
    openWebsocket,
    tempStopStreaming,
    setStreamingLoader,
    streamingloader,
  }));

  const openWebsocket = (last_message_id, uniqueid, randomUUID) => {
    setStreamingLoader(true);
    props.setParentState(true);
    setStreamingID(last_message_id);

    const api_header = {
      host: host,
      Authorization: localStorage.getItem("_token"),
    };

    const payload = {};
    const base64_api_header = btoa(JSON.stringify(api_header));
    const base64_payload = btoa(JSON.stringify(payload));
    const appsync_url =
      url + "?header=" + base64_api_header + "&payload=" + base64_payload;

    var socket = new WebSocket(appsync_url, ["graphql-ws"]);

    socket.onopen = (e) => {
      socket.send(JSON.stringify({ type: "connection_init" }));
    };

    socket.onmessage = (e) => {
      const receivedData = JSON.parse(e.data);
      // console.log(receivedData);
      if (receivedData.type === "connection_ack") {
        setData("");
        const query = JSON.stringify({
          query: `subscription MySubscription {
            on_aivolvex_control_plane
          }`,
        });

        const queryStr = JSON.stringify({
          id: randomUUID,
          type: "start",
          payload: {
            data: query,
            extensions: {
              authorization: {
                host: host,
                Authorization: localStorage.getItem("_token"),
              },
            },
          },
        });
        streamingstopstatus = true;
        // setStreamingStopStatus(true);
        socket.send(queryStr);
      } else if (
        receivedData.type === "data" &&
        receivedData.id === randomUUID
      ) {
        const streaming_responce = JSON.parse(
          receivedData.payload.data.on_aivolvex_control_plane
        );
        if (uniqueid === streaming_responce.user_id) {
          const find_id_record = chatmessage.find(
            (item) => item.id === last_message_id
          );
          setCompletedStatus({
            status: false,
            records: streaming_responce,
          });
          if (streaming_responce.stream_status === "completed") {
            socket.close();
            if (streamingstopstatus) {
              setCompletedStatus({
                status: true,
                records: streaming_responce,
              });
            }
          } else {
            if (streamingstopstatus) {
              setData((item) => item + streaming_responce.response);
            } else {
              socket.close();
            }
          }
        }
      }
    };
    return socket;
  };

  const tempStopStreaming = () => {
    streamingstopstatus = false;
    setCompletedStatus({
      status: true,
      records: completedstatus.records,
    });
  };

  useEffect(() => {
    if (completedstatus.status) {
      // setCompletedStatus(false);
      console.log(completedstatus);
      console.log(
        completedstatus.records.document !== undefined
          ? JSON.parse(completedstatus.records.document)
          : []
      );
      const input = {
        id: streaming_id,
        text: bodydata,
        conversection_id: Number(completedstatus.records.inserted_id),
        message_list:
          completedstatus.records.document !== undefined
            ? JSON.parse(completedstatus.records.document)
            : [],
      };
      dispatch(setStreamingRecord(input));
      // setStreamingStopStatus(false);
      setStreamingLoader(false);
      props.setParentState(false);
      streamingstopstatus = false;
      props.setMessageProcessStatus(true);
    }
  }, [completedstatus]);

  // end streaming
  return (
    <>
      {sourcemodel && (
        <DocumentViewSource
          open={sourcemodel}
          setOpen={setSourceModel}
          sourcerecords={sourcerecords}
        />
      )}
      <Row className="mt-2 full-width">
        <ChatListScroll>
          {/* dynamic value */}
          {/* {console.log(chatmessage)} */}
          {chatmessage.map((data, index) => (
            <>
              {data.type === "avatar" ? (
                <>
                  {/* <Col span={24} className="mt-4 with-lines"> */}
                  <Col span={24} className="mt-4 ">
                    {/* start question */}
                    <div className="d-flex justify-content-center title-prompt ">
                      {/* <label className="volvex-new-avatar  "> */}
                      <Col>
                        <span className="margin-right-0-5 ">
                          <img
                            src={data.image}
                            alt="avatar-img"
                            className="volvex-chat-avatar-logo title-prompt"
                          />
                        </span>
                      </Col>
                      <Col>
                        <Row>
                          <label className="volvex-new-avatar  ">
                            {data.text}
                          </label>
                        </Row>
                        <Row className="d-flex justify-content-center footer-chat-time-title ">
                          {/* <Popover
                            title={null}
                            content={<>{data.date ? data.date : currentTime}</>}
                            trigger="hover"
                            overlayClassName="date-popover"
                          > */}
                          {data.date ? data.date : currentTime}
                          {/* </Popover> */}
                        </Row>
                        {/* {data.text}
                            <div className="d-flex justify-content-center footer-chat-time title-prompt-time">
                              {data.date} */}
                        {/* </div> */}
                      </Col>

                      {/* </label> */}

                      {/* <div className="d-flex justify-content-center footer-chat-time title-prompt-time">
                        {data.date}
                      </div> */}
                    </div>
                    {/* <div className="d-flex justify-content-center footer-chat-time">
                      {data.date}
                    </div> */}
                    {/* end question */}
                  </Col>
                  {/* <hr /> */}
                </>
              ) : data.type === "loader" ? (
                <>
                  <Col span={24} className="mt-4">
                    {/* start answer */}
                    <div className="d-flex justify-content-between volvex-ans-chat-body">
                      <div className="text-center">
                        <img src={preloader} width="15%" />
                      </div>
                    </div>
                    {/* end answer */}
                  </Col>
                </>
              ) : (
                <>
                  {data.user ? (
                    <>
                      <Col span={24} className="mt-4">
                        {/* start question */}
                        <div className="d-flex justify-content-end volvex-question-chat-body">
                          <div className="volvex-question-chat">
                            <div style={{ fontSize: chatfont }}>
                              <pre className="conversation-responce-pre aivolvex-font pre-text-source">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.text,
                                  }}
                                />
                              </pre>
                            </div>
                            <div className="text-right">
                              <label className="footer-chat-time">
                                {data.date}
                              </label>
                            </div>
                          </div>
                          <div className="volvex-chat-avatar-img-left">
                            {/* {user_details ? (
                              <Avatar className="volvex-chat-avatar-logo">
                                {user_details[0].toUpperCase()}
                              </Avatar>
                            ) : (
                              <Avatar className="volvex-chat-avatar-logo">
                                U
                              </Avatar>
                            )} */}

                            {/* <img
                              src={profile_image}
                              alt="avatar-img"
                              className="volvex-chat-avatar-logo"
                            /> */}
                            {customerLogo && customerFirstName ? (
                              <div>
                                <img
                                  src={customerLogo}
                                  alt="avatar-img"
                                  className="volvex-chat-avatar-logo"
                                />
                              </div>
                            ) : (
                              <>
                                {customerFirstName ? (
                                  <Avatar>
                                    {customerFirstName.charAt(0).toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <img
                                    src={customerLogo}
                                    alt="avatar-img"
                                    className="volvex-chat-avatar-logo"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {/* end question */}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24} className="mt-4">
                        {/* start answer */}
                        <div className="d-flex justify-content-between volvex-ans-chat-body">
                          <div className="volvex-question-chat ">
                            <div className="d-flex justify-content-start">
                              <div className="volvex-chat-avatar-img-right">
                                <img
                                  src={data.image}
                                  alt="avatar-img"
                                  className="volvex-chat-avatar-logo"
                                />
                              </div>
                              <div
                                className="text-source"
                                style={{ fontSize: chatfont }}
                              >
                                <pre
                                  className="conversation-responce-pre aivolvex-font message-background"
                                  name={`content-to-convert-${index}d`}
                                >
                                  {streaming_id == data.id ? (
                                    <>
                                      {/* {isMarkdownTableFormat(bodydata) ? (
                                        <MarkdownToJSX>
                                          {bodydata}
                                        </MarkdownToJSX>
                                      ) : (
                                        <>{parse(bodydata)}</>
                                      )} */}
                                      {/* {parse(bodydata)} */}
                                      <MarkdownToJSX>{bodydata}</MarkdownToJSX>
                                      {streamingloader && (
                                        <div className="blinking_cursor">_</div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {/* {isMarkdownTableFormat(data.text) ? (
                                        <MarkdownToJSX>
                                          {data.text}
                                        </MarkdownToJSX>
                                      ) : (
                                        <>{parse(data.text)}</>
                                      )} */}
                                      {/* {parse(data.text)} */}
                                      <MarkdownToJSX>{data.text}</MarkdownToJSX>
                                    </>
                                  )}
                                </pre>
                                {!streamingloader && (
                                  <>
                                    <div className="text-left">
                                      <>
                                        <div className="d-flex justify-content-between ">
                                          <label className="footer-chat-time ">
                                            {data.date}
                                          </label>
                                          {data.message_list?.length > 0 && (
                                            <p
                                              className="cursor-pointer app-secondary-color volvex-view-source-font"
                                              onClick={() => {
                                                setSourceModel(true);
                                                setSourceRecords(
                                                  data.message_list
                                                );
                                              }}
                                            >
                                              View Source
                                            </p>
                                          )}
                                        </div>
                                      </>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="volvex-feedback-body">
                            {!streamingloader && (
                              <Chatsubitems
                                index={index}
                                data={data}
                                type={"desktop"}
                              />
                            )}
                          </div>
                        </div>
                        {/* end answer */}
                      </Col>
                    </>
                  )}

                  {index < chatmessage.length - 1 &&
                    chatmessage[index + 1].type === "avatar" && (
                      <Col span={24}>
                        <hr className="text-line" />
                      </Col>
                    )}
                </>
              )}
            </>
          ))}
        </ChatListScroll>
      </Row>
    </>
  );
});

export default memo(ChatBody);
