import { azureAdEmailCheck, getUserController } from "../../api/accountAction";
export const getAzureemail = async (dispatch, email) => {
  try {
    const response = await dispatch(azureAdEmailCheck(email)).unwrap();
    const records = JSON.parse(response.data.company_check);
    const client_id = records.azureclient_id;
    const tenantid = records.tenant_id;
    const cognito_client_id = records.cognitoclient_id;
    localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
    localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
    localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
    localStorage.removeItem("AIVolveXIdentityServiceProvider.store");

    window.location.href =
      "https://login.microsoftonline.com/" +
      tenantid +
      "/oauth2/logout?post_logout_redirect_uri=https://sandbox-azure-aivolvex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
      cognito_client_id +
      "&redirect_uri=http://localhost:3000/";
  } catch (error) {
    console.error(error);
  }
};

export const getUserControllerlist = async (dispatch, input, setOffer) => {
  try {
    const response = await dispatch(getUserController(input)).unwrap();
    const records = JSON.parse(response.data.aivolvex_control_plane);
    setOffer(records.offering);
  } catch (error) {
    console.error(error);
  }
};
