import {
  getAvatarList,
  agentlist,
  agentlog,
  agentupdate,
  agentinsert,
  agentdelete,
  tokencreditlist,
  tokencreditupdate,
  tokencreditlog,
} from "../../api/accountAction";
import { message } from "antd";
export const getavatarListrecord = async (dispatch, input, setAvatarList) => {
  try {
    const response = await dispatch(getAvatarList(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);
    setAvatarList(value);
  } catch (error) {
    console.error(error);
  }
};
export const getAgentlist = async (
  dispatch,
  input,
  setremaincredit,
  settotalcredit,
  setCardrecord,
  setnewavailablecredit,
  newtotalcredit
) => {
  try {
    const response = await dispatch(agentlist(input)).unwrap();
    const inputData = JSON.parse(response.data.aivolvex_control_plane);
    const sumofremain = inputData.reduce(
      (acc, item) => acc + item.remaining_token,
      0
    );
    const sumoftotal = inputData.reduce(
      (acc, item) => acc + item.total_tokens,
      0
    );
    setremaincredit(sumofremain);
    settotalcredit(sumoftotal);
    setCardrecord(inputData);
    setnewavailablecredit(newtotalcredit - sumofremain);
  } catch (error) {
    console.error(error);
  }
};
export const getAgentlog = async (dispatch, input, setLogdata) => {
  try {
    const response = await dispatch(agentlog(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);
    setLogdata(value);
  } catch (error) {
    console.error(error);
  }
};
export const getAgentupdate = async (
  dispatch,
  updateinput,
  setUpdatestatus,
  updatestatus
) => {
  try {
    const response = await dispatch(agentupdate(updateinput)).unwrap();
    const input = JSON.parse(response.data.aivolvex_control_plane);
    if (input.includes("not eligible")) {
      message.success("Updated");
    } else {
      message.error("not updated");
      setUpdatestatus(!updatestatus);
    }
  } catch (error) {
    console.error(error);
  }
};
export const getAgentinsert = async (
  dispatch,
  insertinput,
  insertstatus,
  setInsertstatus,
  setValue
) => {
  try {
    const response = await dispatch(agentinsert(insertinput)).unwrap();
    const inputData = JSON.parse(response.data.aivolvex_control_plane);
    if (inputData[0].includes("Not enough tokens available")) {
      message.error("Not enough tokens available");
    } else {
      message.success("Credits are inserted");
      setValue(0);
      setInsertstatus(!insertstatus);
    }
  } catch (error) {
    console.error(error);
  }
};
export const getAgentdelete = async (
  dispatch,
  input,
  setUpdatestatus,
  updatestatus
) => {
  try {
    const response = await dispatch(agentdelete(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);
    setUpdatestatus(!updatestatus);
  } catch (error) {
    console.error(error);
  }
};
export const gettokencreditlist = async (
  dispatch,
  input,
  setMonths,
  setTotalCredits,
  setActualcredit,
  months,
  year,
  setLoaderStatus
) => {
  try {
    const responce = await dispatch(tokencreditlist(input)).unwrap();
    const inputData = JSON.parse(responce.data.aivolvex_control_plane);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const transformData = (data) => {
      // console.log(data);
      const transformedData = [];
      for (let month = 1; month <= 12; month++) {
        const foundItem = data.find((item) => item.month === month);
        if (foundItem) {
          const monthName = monthNames[month - 1];
          transformedData.push({
            ...foundItem,
            remaining_tokens: foundItem.available_tokens,
            monthname: monthName,
          });
        } else {
          const monthName = monthNames[month - 1];
          transformedData.push({
            total_tokens: 0,
            remaining_tokens: 0,
            available_tokens: 0,
            year: year,
            month: month,
            monthname: monthName,
          });
        }
      }
      return transformedData;
    };
    const transformedData = transformData(inputData);
    setMonths(transformedData);
    const totalAvailableTokens = inputData.reduce(
      (total, data) => total + data.available_tokens,
      0
    );
    const totalTotalTokens = inputData.reduce(
      (total, data) => total + data.total_tokens,
      0
    );
    setTotalCredits(totalTotalTokens);
    setActualcredit(totalAvailableTokens);
    setLoaderStatus(false);
  } catch (error) {
    console.error(error);
  }
};
export const gettokencreditupdate = async (dispatch, update, setSave, save) => {
  try {
    const response = await dispatch(tokencreditupdate(update)).unwrap();
    message.success("Successfully updated");
    setSave(!save);
  } catch (error) {
    console.error(error);
  }
};
export const gettokencreditlog = async (dispatch, input, setLogdata) => {
  try {
    const response = await dispatch(tokencreditlog(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane);
    setLogdata(value);
  } catch (error) {
    console.error(error);
  }
};