import { useEffect, useState } from "react";
import React, { memo } from "react";
import { useContext, useRef } from "react";
import {
  Col,
  Row,
  Space,
  Table,
  Modal,
  Button,
  Input,
  Form,
  message,
  Popover,
} from "antd";
import { Icon } from "@iconify/react";
import jsPDF from "jspdf";
import "../CSS/history.scss";
import { IoTrashBin } from "react-icons/io5";
// import { Page, Document, StyleSheet, View, Text, PDFDownloadLink,Image, } from '@react-pdf/renderer';
import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
// import MyDocument from "../Desktop/MyDocument"
// import { Page, Document, PDFDownloadLink, StyleSheet, View, Text, Image, } from '@react-pdf/renderer';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { formatDatewithtime } from "../../customhook/CustomHoom";
import {
  getdatacount,
  getdatarecord,
  getupdatehistory,
  getdeletehistory,
  getAvatarChatHistorypdf,
  // gethistoryofchat
} from "../Auth/HistoryAuth";
import { getHistoryPdf } from "../../api/accountAction";
// import navLogo from "../../../assets/ui/Logo SVG.svg";
// import aivolvexdemo from "../../../assets/ui/testlogo.jpg";
const EditableContext = React.createContext(null);
const logo = require("../../../assets/ui/Logo SVG.svg");
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      const isEqual = (obj1, obj2) =>
        JSON.stringify(obj1) === JSON.stringify(obj2);

      if (isEqual(record, { ...record, ...values })) {
        message.info("Title is the same, no changes were made", [1]);
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } else {
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
        message.success("Title Changed", [1]);
      }
    } catch (errInfo) {
      message.error("Title Not Changed", [1]);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    textAlign: "center",
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  textBox: {
    border: 1,
    borderColor: "black",
    borderRadius: 5,
    padding: 10,
  },
});
const userName = atob(
  localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
);
const companyName = atob(
  localStorage.getItem("AIVolveXIdentityServiceProvider.com")
);

const useHistoryConversation = (input) => {
  const dispatch = useDispatch();
  // gethistoryofchat(dispatch, input);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAvatarChatHistory(input))
  //     .unwrap()
  //     .then(({ data }) => {
  //       const records = JSON.parse(data.aivolvex_control_plane);

  //       if (records.length > 0) {
  //         var avatar_list = records.map((v) => v.avatar_id);
  //         avatar_list = new Set([...avatar_list]);
  //         avatar_list = [...avatar_list];
  //         var avatar_list_details = [];
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, [dispatch, input]);
};
const MyDocument = (props) => {
  const record = props.record;
  const [historyavatardetails, setHistoryAvatar] = useState({
    avatar_count: 0,
    history_list: [],
  });
  const input = {
    conversationid: record.session_id,
    companyname: companyName,
  };

  // useHistoryConversation(input);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {/* <Image
            src={logo}
          /> */}
          <Text>{record.dateandtime}</Text>
          {/* <Text>
            {record.user_id}
          </Text> */}
          {/* <Image src={{ uri: require('../../../assets/ui/Logo SVG.svg'), method: 'GET', headers: {}, scale: 1 }} /> */}

          <View style={{ ...styles.section, ...styles.textContainer }}>
            <View style={styles.textBox}>
              <Text>{record.prompt}</Text>
            </View>
          </View>
          {/* <Text>
            {record.avatar_id}
          </Text>
          <Text>
            {record.session_id}
          </Text> */}
        </View>
      </Page>
    </Document>
  );
};
const Historymobiletable = memo(() => {
  const dispatch = useDispatch();
  const [count, setCount] = useState("");
  const [records, setRecord] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState(false);
  const [user, setUser] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletemodal, setdeletemodal] = useState(false);
  const [deleterecord, setDeleterecord] = useState("");
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  // const lastWord = useSelector((state) => state.lastword);
  const navigate = useNavigate();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const defaultColumns = [
    {
      title: "Date and Time",
      dataIndex: "dateandtime",
      key: "dateandtime",
      width: "40%",
      render: (text) => formatDatewithtime(text),
      align: "center",
      onHeaderCell: () => ({
        style: {
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      }),
    },
    {
      title: (
        <span>
          <span>Title </span>
          <Popover title="Click on Title to Edit">
            <Icon icon="material-symbols:info" className="lib-search-icon" />
          </Popover>
        </span>
      ),
      dataIndex: "prompt",
      key: "prompt",
      align: "center",
      width: "50%",
      editable: true,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record, index) => (
        <Space size="middle">
          <span>
            <Icon
              icon="akar-icons:link-out"
              className="history-action-icons his-link"
              onClick={() => handleIconClick(record)}
            />
          </span>
          <span>
            {/* <Popconfirm
                            title="Delete Chat?"
                            onConfirm={() =>
                                handleDeleteClick(record)
                            }
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        > */}
            {/* <Button className="his-delete" onClick={() => handleremove(record)}> */}
            <IoTrashBin
              onClick={() => handleremove(record)}
              className="history-action-icons his-del"
            />
            {/* <span className="his-delete-text">Delete</span> */}
            {/* </Button> */}
            {/* </Popconfirm> */}
          </span>

          <span>
            <Icon
              icon="vscode-icons:file-type-pdf2"
              className="history-action-icons"
              onClick={() => handlePdfIconClick(record)}
            />
          </span>
        </Space>
      ),
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  // const columns = [
  //   {
  //     title: "Date and Time",
  //     dataIndex: "dateandtime",
  //     key: "dateandtime",
  //     width: "20%",
  //     render: (text) => formatDatewithtime(text),
  //     align: "center",
  //     onHeaderCell: () => ({
  //       style: {
  //         whiteSpace: "nowrap",
  //         textAlign: "center",
  //       },
  //     }),
  //   },
  //   {
  //     title: "Title",
  //     dataIndex: "prompt",
  //     key: "prompt",
  //     align: "center",
  //     width: "50%",
  //   },

  //   {
  //     title: "Actions",
  //     dataIndex: "actions",
  //     key: "actions",
  //     align: "center",
  //     width: "30%",
  //     render: (text, record, index) => (
  //       <Space size="middle">
  //         <span>
  //           {/* <Button
  //                 className="his-button"
  //                 onClick={() => handleEditClick(record)}
  //               > */}
  //           <Icon
  //             icon="wpf:edit"
  //             onClick={() => handleEditClick(record)}
  //             className="history-action-icons"
  //           />{" "}
  //           {/* <span className="his-delete-text">Edit</span> */}
  //           {/* </Button>{" "} */}
  //         </span>
  //         <span>
  //           {/* <Popconfirm
  //                               title="Delete Chat?"
  //                               onConfirm={() =>
  //                                   handleDeleteClick(record)
  //                               }
  //                               onCancel={cancel}
  //                               okText="Yes"
  //                               cancelText="No"
  //                           > */}
  //           {/* <Button className="his-delete" onClick={() => handleremove(record)}> */}
  //           <IoTrashBin
  //             onClick={() => handleremove(record)}
  //             className="history-action-icons his-del"
  //           />
  //           {/* <span className="his-delete-text">Delete</span> */}
  //           {/* </Button> */}
  //           {/* </Popconfirm> */}
  //         </span>
  //         <span>
  //           {/* <Button
  //                 className="his-button history_btn"
  //                 onClick={() => handleIconClick(record)}
  //               >
  //               */}
  //           <Icon
  //             icon="gg:link"
  //             className="history-action-icons his-link"
  //             onClick={() => handleIconClick(record)}
  //           />
  //           {/* </Button> */}
  //         </span>

  //         <span>
  //           {/* <Button
  //                 className="his-button history_btn"
  //                 onClick={() => handlePdfIconClick(record)}
  //               > */}{" "}
  //           <Icon
  //             icon="vscode-icons:file-type-pdf2"
  //             className="history-action-icons"
  //             onClick={() => handlePdfIconClick(record)}
  //           />
  //           {/* </Button> */}
  //         </span>
  //       </Space>
  //     ),
  //   },
  // ];
  const handleremove = (record) => {
    setDeleterecord(record);
    setdeletemodal(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    const input = {
      user_id: userName,
      company: companyName,
      user_session: user.session_id,
      avatar_id: user.avatar_id,
      title: editTitle,
    };
    getupdatehistory(dispatch, input, setStatus);
    // dispatch(updatehistory(input))
    //     .unwrap()
    //     .then(({ data }) => {
    //         const records = JSON.parse(data.aivolvex_control_plane);
    //         setStatus(true)
    //     })
    //     .catch((err) => {
    //     });
  };
  const handleSave = (row) => {
    const input = {
      user_id: userName,
      company: companyName,
      user_session: row.session_id,
      avatar_id: row.avatar_id,
      title: row.prompt,
    };
    getupdatehistory(dispatch, input);
    setStatus(!status);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const cancel = (e) => {
    setdeletemodal(false);
  };
  const handleEditClick = (record) => {
    setEditTitle(record.prompt);
    setIsModalOpen(true);
    setUser(record);
  };
  const input = {
    user_id: userName,
    company: companyName,
    text: searchQuery,
  };
  // useEffect(() => {
  //     const input = {
  //         user_id: userName,
  //         company: companyName
  //     }
  //     dispatch(gethistoryPageination(input))
  //         .unwrap()
  //         .then(({ data }) => {
  //             const value = JSON.parse(data.aivolvex_control_plane)[0];
  //             setCount(value)

  //         })
  //         .catch((err) => {
  //         });
  // }, []);
  useEffect(() => {
    getdatacount(dispatch, input, setCount);
  }, []);
  const handleDeleteClick = () => {
    const record = deleterecord;
    setdeletemodal(false);
    const input = {
      user_id: userName,
      company: companyName,
      user_session: record.session_id,
    };
    getdeletehistory(dispatch, input, setStatus);
    // dispatch(deletehistory(input))
    //     .unwrap()
    //     .then(({ data }) => {
    //         const records = JSON.parse(data.aivolvex_control_plane);
    //         setStatus(true)
    //         message.success("Deleted")

    //     })
    //     .catch((err) => {

    //     });
  };
  const inputlist = {
    page: currentPage,
    limit: pageSize,
    user_id: userName,
    company: companyName,
    text: searchQuery,
  };
  // const inputlist = {
  //   page: currentPage,
  //   limit: pageSize,
  //   user_id: userName,
  //   company: companyName,
  // };
  useEffect(() => {
    getdatarecord(dispatch, inputlist, setPreloaderStatus, setRecord);
  }, [currentPage, searchQuery, pageSize, status]);
  const [svgBase64, setSvgBase64] = useState(null);

  useEffect(() => {
    // Create an image element
    const img = new Image();
    img.src = aivolvex;

    // Ensure the image has loaded
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the SVG on the canvas
      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      // Get the base64 data URL
      const dataURL = canvas.toDataURL("image/png");
      setSvgBase64(dataURL);
    };
  }, []);
  const handlePdfIconClick = (record) => {
    const input = {
      company: companyName,
      user_session: record.session_id,
    };
    dispatch(getHistoryPdf(input))
      .unwrap()
      .then(({ data }) => {
        window.open(JSON.parse(data.aivolvex_control_plane), "_blank");
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleIconClick = (record) => {
    const avatarId = record.avatar_id;
    const sessionId = record.session_id;
    navigate(`/conversation/${avatarId}/${sessionId}`);
  };
  const filteredPrompts = records.filter((item) =>
    item.prompt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Row className="search-box d-flex justify-content-start">
        <Col>
          <Input
            placeholder="Search here"
            prefix={
              <Icon
                icon="material-symbols:search"
                className="lib-search-icon"
              />
            }
            //   className="lib-history"
            className="recent-search-input recent-search"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="mt-3">
          <Row>
            {preloaderstatus ? (
              <>
                <Col span={24}>
                  <div className="aivolvex-sub-loader h-18">
                    <img src={preloader} width="20%" />
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Table
                    className="mobile-view-table"
                    components={components}
                    dataSource={filteredPrompts}
                    columns={columns}
                    scroll={{ x: 1500 }}
                    pagination={{
                      current: currentPage,
                      pageSize: pageSize,
                      total: count,
                      onChange: handlePageChange,
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="edit-button"
      >
        <input
          type="text"
          value={editTitle}
          className="table-input"
          onChange={(e) => setEditTitle(e.target.value)}
        />
      </Modal>
      <Modal
        open={deletemodal}
        onOk={handleDeleteClick}
        onCancel={cancel}
        className="history-delete-modal"
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>
    </>
  );
});
export default Historymobiletable;
