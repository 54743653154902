import React from "react";
import { Row, Col, Card, Tag } from "antd";

function IssueTooltip(props) {
  
  return (
    <Card>
      <Row>
        <Col>
          <Tag className="issue-tag">
            {props.response.Opportunity_tag
              ? props.response.Opportunity_tag
              : ""}
          </Tag>
        </Col>
      </Row>
      <Row span={24}>
        <p>
          {props.response.Opportunity
            ? props.response.Opportunity
            : ""}
        </p>
      </Row>
    </Card>
  );
}
export default IssueTooltip;
