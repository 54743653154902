import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Space,
  Table,
  Modal,
  Input,
  message,
  Form,
  Popover,
} from "antd";
import { Icon } from "@iconify/react";
import { formatDatewithtime } from "../../customhook/CustomHoom";
import jsPDF from "jspdf";
import "../CSS/history.scss";
import { IoTrashBin } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import preloader from "../../../assets/loader/pre_loader.gif";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { getHistoryPdf } from "../../api/accountAction";
import preloader_image from "../../../assets/loader/pre_loader.gif";
// import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import {
  getdatacount,
  getdatarecord,
  getupdatehistory,
  getdeletehistory,
  getAvatarChatHistorypdf,
} from "../Auth/HistoryAuth";
import { current } from "immer";
// import MyDocument from "./MyDocument";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      const isEqual = (obj1, obj2) =>
        JSON.stringify(obj1) === JSON.stringify(obj2);

      if (isEqual(record, { ...record, ...values })) {
        message.info("Title is the same, no changes were made", [1]);
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } else {
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
        message.success("Title Changed", [1]);
      }
    } catch (errInfo) {
      message.error("Title Not Changed", [1]);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const HistoryTable = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState("");
  const [records, setRecord] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletemodal, setdeletemodal] = useState(false);
  const [deleterecord, setDeleterecord] = useState("");
  const [preloaderstatus, setPreloaderStatus] = useState(false);

  // const lastWord = useSelector((state) => state.lastword);
  const navigate = useNavigate();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const handleremove = (record) => {
    setDeleterecord(record);
    setdeletemodal(true);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleIconClick = (record) => {
    const avatarId = record.avatar_id;
    const sessionId = record.session_id;
    navigate(`/conversation/${avatarId}/${sessionId}`);
  };

  const cancel = (e) => {
    setdeletemodal(false);
  };

  const inputlist = {
    page: currentPage,
    limit: pageSize,
    user_id: userName,
    company: companyName,
    text: searchQuery,
  };
  useEffect(() => {
    //
    const debounceTimer = setTimeout(() => {
      //
      getdatarecord(dispatch, inputlist, setPreloaderStatus, setRecord);
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, pageSize, currentPage, status]);
  useEffect(() => {
    const input = {
      user_id: userName,
      company: companyName,
      text: searchQuery,
    };
    setCurrentPage(1);
    getdatacount(dispatch, input, setCount);
  }, [searchQuery]);

  const handleSave = (row) => {
    const input = {
      user_id: userName,
      company: companyName,
      user_session: row.session_id,
      avatar_id: row.avatar_id,
      title: row.prompt,
    };
    getupdatehistory(dispatch, input);
    // message.success("Title Changed", [1]);
    setStatus(!status);
  };

  const handleDeleteClick = () => {
    const record = deleterecord;
    setdeletemodal(false);
    const input = {
      user_id: userName,
      company: companyName,
      user_session: record.session_id,
    };
    getdeletehistory(dispatch, input);
    setStatus(!status);
  };
  const [svgBase64, setSvgBase64] = useState(null);

  useEffect(() => {
    // Create an image element
    const img = new Image();
    img.src = aivolvex;

    // Ensure the image has loaded
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the SVG on the canvas
      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      // Get the base64 data URL
      const dataURL = canvas.toDataURL("image/png");
      //
      setSvgBase64(dataURL);
    };
  }, []);
  const handlePdfIconClick = (record) => {
    const input = {
      company: companyName,
      user_session: record.session_id,
    };
    dispatch(getHistoryPdf(input))
      .unwrap()
      .then(({ data }) => {
        window.open(JSON.parse(data.aivolvex_control_plane), "_blank");
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  };
  const defaultColumns = [
    {
      title: "Date and Time",
      dataIndex: "dateandtime",
      key: "dateandtime",
      width: "20%",
      render: (text) => formatDatewithtime(text),
      align: "center",
    },
    {
      title: (
        <span>
          <span>Title </span>
          <Popover title="Double-click the Title column to edit and update the title.">
            <Icon icon="material-symbols:info" className="lib-search-icon" />
          </Popover>
        </span>
      ),
      dataIndex: "prompt",
      key: "prompt",
      align: "center",
      className: "history_title_clm",
      editable: true,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      // width:"10",
      className: "fixed-column",
      render: (text, record, index) => (
        <Space size="middle">
          <span>
            <Icon
              icon="akar-icons:link-out"
              className="history-action-icons his-link"
              onClick={() => handleIconClick(record)}
            />
          </span>
          <span>
            <IoTrashBin
              onClick={() => handleremove(record)}
              className="history-action-icons his-del"
            />
          </span>
          <span>
            <Icon
              icon="vscode-icons:file-type-pdf2"
              className="history-action-icons"
              onClick={() => handlePdfIconClick(record)}
            />
          </span>
        </Space>
      ),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <>
      <Col span={24} className="aivolvex-main-body">
        <Row className="recent-prompt-table-head">
          <Col span={24} className="d-flex justify-content-between">
            <h4 className="theme-title-font">History</h4>
          </Col>
        </Row>

        <Row>
          <Col>
            <Input
              placeholder="Search here"
              prefix={
                <Icon
                  icon="material-symbols:search"
                  className="lib-search-icon"
                />
              }
              //   className="lib-history"
              className="recent-search recent-search-input"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </Col>
        </Row>
        <Row>
          {records.length > 0 ? (
            <>
              <Col span={24} className="recent-prompt-table">
                <Table
                  //   className="mt-3 ms-2 costimize-bootstrap-table"
                  components={components}
                  className="recent-prompts-table"
                  dataSource={records}
                  columns={columns}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: count,
                    onChange: handlePageChange,
                  }}
                />
              </Col>
            </>
          ) : (
            <>
              {searchQuery.length == 0 ? (
                <Col span={24}>
                  {" "}
                  <div className="aivolvex-sub-loader h-18">
                    <img src={preloader_image} width="20%" />
                  </div>
                </Col>
              ) : (
                <Col span={24}>
                  {" "}
                  <div className="no-data">No Data</div>
                  <div className="d-flex justify-content-center">
                    <Icon
                      icon="tabler:database-exclamation"
                      className="no-data-icon"
                    />
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
      <Modal
        open={deletemodal}
        onOk={handleDeleteClick}
        onCancel={cancel}
        className="history-delete-modal"
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>
    </>
  );
};
export default HistoryTable;
