export const dropDownResult = (selectedlist, selectedvalue) => {
  var checkenvironment;

  return checkenvironment;
};
export const useErrorNavigation = (error) => {
  if (error) {
    if (error.networkError && error.networkError.statusCode === 401) {
      // if (error.networkError) {
      //
      localStorage.removeItem("CognitoIdentityServiceProvider.auth");
      localStorage.removeItem("_token");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      window.location.href = "/";
      // window.location.href = "/";
    }
  }
};
// export function simplifyNumber(number) {
//   const billion = 1000000000;
//   const million = 1000000;
//   const thousand = 1000;

//   if (number >= billion) {
//     return (number / billion).toFixed(1) + 'B';
//   } else if (number >= million) {
//     return (number / million).toFixed(1) + 'M';
//   } else if (number >= thousand) {
//     return (number / thousand).toFixed(1) + 'K';
//   } else {
//     return number.toString();
//   }
// }
export function simplifyNumber(number) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return (number / billion).toFixed(1) + "B";
  } else if (number >= million) {
    return (number / million).toFixed(1) + "M";
  } else if (number >= thousand) {
    return (number / thousand).toFixed(1) + "K";
  } else {
    if (number !== undefined) {
      return number.toString();
    } else {
      return "Null";
    }
  }
}

export function formatDate(inputDate) {
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDate = new Date(inputDate).toLocaleString("en-US", options);
  return formattedDate;
}

export function formatFileSize(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (bytes >= gigabyte) {
    return (bytes / gigabyte).toFixed(2) + " GB";
  } else if (bytes >= megabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes >= kilobyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return bytes + " Bytes";
  }
}
export function formatCurrentTime() {
  const now = new Date();
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const day = days[now.getDay()];
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${day} ${formattedHours}.${formattedMinutes}${ampm}`;
  return formattedTime;
}
export function getCurrentDayAndTime() {
  const options = {
    weekday: "short",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formatter = new Intl.DateTimeFormat("ist", options);
  const currentDate = new Date();
  const formattedDate = formatter.format(currentDate);
  return formattedDate;
}
export function generateTransactionId() {
  const timestamp = Date.now().toString();
  const randomNum = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, "0");
  return `${timestamp}-${randomNum}`;
}
export const htmlRemove = (event) => {
  const htmlContent = event;
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;
  return tempElement.textContent;
};
export function generateRandomFourDigitNumber() {
  return Math.floor(1000 + Math.random() * 9000);
}
export function generateUniqueID() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const randomFourDigitNumber = generateRandomFourDigitNumber();

  return `${year}${month}${day}-${randomFourDigitNumber}`;
}
export function capitalizeWords(inputString) {
  if (inputString != null) {
    const words = inputString.split(" ");

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const resultString = capitalizedWords.join(" ");

    return resultString;
  }
}
export function formatDateHistory(dateString) {
  const date = new Date(dateString);
  const currentDate = new Date();
  const isCurrentWeek =
    date >= currentDate && date - currentDate <= 7 * 24 * 60 * 60 * 1000;
  if (isCurrentWeek) {
    const options = {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  } else {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
}
export function formatDatewithtime(inputDate) {
  if (inputDate) {
    const date = new Date(inputDate);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedDate = `${day}-${month}- ${year}, ${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;
    return formattedDate;
  }
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function imageChecking(oldimagestatus, filelength, oldimage) {
  if (oldimagestatus) {
    return (
      <div className="uploaded-image-body">
        <img src={oldimage} alt="" className="uploaded-image" />
      </div>
    );
  } else {
    if (filelength === 0) {
      if (oldimage) {
        return (
          <>
            <div className="uploaded-image-body">
              <img src={oldimage} alt="" className="uploaded-image" />
            </div>
          </>
        );
      } else {
        return <>+</>;
      }
    }
  }
}

export function setCookie(cookieName, cookieValue, expirationDays) {
  var d = new Date();
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}
export function getCookie(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(";");
  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

export function deleteCookie(cookieName) {
  document.cookie =
    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
