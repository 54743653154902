import React from "react";
import { memo, useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import {
  FaSignOutAlt,
  FaUser,
  FaSearch,
  PiShareFatLight,
  FaShare,
} from "react-icons/fa";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MentionInputField from "./drop";
import "./navbar.scss";
import navLogo from "../../assets/ui/Logo SVG.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "antd";
import { capitalizeWords } from "../customhook/CustomHoom";
import { azureAdEmailCheck, getUserController,AcceptCall } from "../api/accountAction";
// import ceoimg from "../../assets/ceo.png";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import aivolvex from "../../assets/ui/X Icon SVG.svg";
import aivolvexdemo from "../../assets/ui/testlogo.jpg";
import { IoShuffleOutline } from "react-icons/io5";
import { IoIosKeypad, IoIosTimer } from "react-icons/io";
import { getUserControllerlist, getAzureemail } from "./Auth/HeaderAuth";
import profile_image from "../../assets/profile.png";
import { listprofile } from "../Profile/Auth/Profileauth";
import {
  setUsername,
  setUserlogo,
  setBackgroundlogo,
  setCompanylogo,
  setCompanyName,
} from "../Slice/reducer/chatReducer";
function NavBar() {
  const navigateFn = useNavigate();
  const [toggled, setToggled] = useState(false);
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const [token, setToken] = useState(0);
  const [recordvalue, setRecordvalue] = useState([]);
  const [record, setRecord] = useState([]);
  const dispatch = useDispatch();
  const { offerstatus } = useSelector((state) => state.chat);
  const { comapanyname, companyimage, bgimage, username, userlogo } =
    useSelector((state) => state.chat);
  // let customercompanyLogo;
  //
  // useEffect(() => {
  //   if (companyimage !== null) {
  //     const input = {
  //       keyid: "company_info",
  //       meta_key: "comapany_logo"
  //     };
  //     listprofile(dispatch, input, setRecord);
  //   } else {
  //     customercompanyLogo = companyimage;
  //   }
  //   //
  //
  //   if (record) {
  //
  //     customercompanyLogo = record[0].meta_value;
  //   }
  // }, [])
  //
  // useEffect(() => {
  //   if (companyimage !== null) {
  //     const inputlist = {
  //       keyid: "profile_info",
  //       meta_key: ""
  //     };
  //     listprofile(dispatch, inputlist, setRecordvalue);
  //   } else {
  //     customercompanyLogo = companyimage;
  //   }
  //   //
  //
  //   if (recordvalue > 0) {
  //
  //     // customercompanyLogo = record[0].meta_value;
  //   }
  // }, [])
  //
  const [offer, setOffer] = useState();
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const user_details = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.store")
  );
  const costomer_email = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const customerFirstName = localStorage.getItem("customer_first_name")
    ? atob(localStorage.getItem("customer_first_name"))
    : "";
  const customerLogo = localStorage.getItem("customer_logo");
  const customercompanyLogo = localStorage.getItem("companyimage");

  const logout = () => {
    const input = {
      event_type:"accept_agent",
      email_id: costomer_email,
      calltype: "offline",
    };
    dispatch(AcceptCall(input))
        .unwrap()
        .then(({ data }) => {
        console.log(data)
        })
        .catch((err) => {
        console.log(err);
        });

    const account_type = atob(
      localStorage.getItem("AIVolveXIdentityServiceProvider.logtype")
    );

    // navigateFn(
    //   "https://login.microsoftonline.com/1e0b7619-b37d-4b53-a46b-35a1180b4632/oauth2/logout?post_logout_redirect_uri=https://www.dev.aivolvex.ai/"
    // );
    // navigateFn(
    //   "https://azureaivolex.auth.ap-south-1.amazoncognito.com/logout?client_id=tbc80j9rr00uvg7rihougtd7h&logout_uri=https://www.dev.aivolvex.ai/logout"
    // );

    if (account_type === "azure") {
      const email = costomer_email;
      // dispatch(azureAdEmailCheck(email))
      //   .unwrap()
      //   .then(({ data }) => {
      //     const records = JSON.parse(data.company_check);
      //     //
      //     const client_id = records.azureclient_id;
      //     const tenantid = records.tenant_id;
      //     const cognito_client_id = records.cognitoclient_id;
      //     //
      //     localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      //     localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      //     localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      //     localStorage.removeItem("AIVolveXIdentityServiceProvider.store");
      //
      //       "https://login.microsoftonline.com/" +
      //       tenantid +
      //       "/oauth2/logout?post_logout_redirect_uri=http://localhost:3000/"
      //     );
      //
      //       "https://azureaivolex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
      //       cognito_client_id +
      //       "&logout_uri=http://localhost:3000/"
      //     );
      //     // window.open(
      //     //   "https://login.microsoftonline.com/" +
      //     //     tenantid +
      //     //     "/oauth2/logout?post_logout_redirect_uri=http://localhost:3000/",
      //     //   "_blank"
      //     // );
      //     // window.location.href =
      //     //   "https://sandbox-azure-aivolvex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
      //     //   cognito_client_id +
      //     //   "&redirect_uri=http://localhost:3000/";

      //     window.location.href =
      //       "https://login.microsoftonline.com/" +
      //       tenantid +
      //       "/oauth2/logout?post_logout_redirect_uri=https://sandbox-azure-aivolvex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
      //       cognito_client_id +
      //       "&redirect_uri=http://localhost:3000/";
      //   })
      //   .catch((err) => {
      //
      //   });
      getAzureemail(dispatch, email);
    } else {
      localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      localStorage.removeItem("customer_first_name");
      localStorage.removeItem("customer_logo");
      localStorage.removeItem("companyimage");
      localStorage.removeItem("companyloaderimage");
      localStorage.removeItem("AIVolvexConversation");
      window.location.href = "/";
      // navigateFn("/");
    }
    //
    // navigateFn("/")
  };
  // function CustomNavbarToggle({ onClick }) {
  //   return (
  //     <button className="navbar-toggler" type="button" onClick={onClick}>
  //       <Icon icon="usd-square" />
  //       {/* <img
  //         src={userLogo}
  //         alt="User Logo"
  //         className="user-logo" // Add a custom CSS class for your user logo
  //       /> */}
  //     </button>
  //   );
  // }
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  useEffect(() => {
    const input = {
      company: companyName,
      user_id: atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
      ),
    };
    getUserControllerlist(dispatch, input, setOffer);
    // dispatch(getUserController(input))
    //   .unwrap()
    //   .then(({ data }) => {
    //     const records = JSON.parse(data.aivolvex_control_plane);
    //     setOffer(records.offering);
    //   })
    //   .catch((err) => {
    //
    //   });
  }, [companyName]);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };
  const custom = () => {
    navigateFn("/profile");
  };
  const key_id = "profile_info";

  // useEffect(() => {
  //   const input = {
  //     keyid: key_id,
  //     meta_key: ""
  //   };
  //   listprofile(dispatch, input, setRecordvalue);
  // }, [])
  // const key_id_company = "company_info";
  //
  // useEffect(() => {
  //   const input = {
  //     keyid: key_id_company,
  //     meta_key: ""
  //   };
  //   listprofile(dispatch, input, setRecord);
  // }, [])
  // let customerFirstName = username;

  //
  //comapanyname, companyimage, bgimage,username, userlogo
  // recordvalue.forEach(item => {
  //   switch (item.meta_key) {
  //     case "customer_first_name":
  //       customerFirstName = item.meta_value;
  //       dispatch(setUsername(item.meta_value));
  //       break;
  //     case "customer_logo":
  //       customerLogo = item.meta_value;
  //       dispatch(setUserlogo(item.meta_value));
  //       break;
  //     default:
  //       break;
  //   }
  // });
  // record.forEach(item => {
  //   switch (item.meta_key) {
  //     case "companylogo":
  //       customercompanyLogo = item.meta_value;
  //       dispatch(setCompanylogo(item.meta_value))
  //       break;
  //       case "display_company_name":
  //         dispatch(setCompanyName(item.meta_value))
  //         break;
  //         case "display_comapany_name":
  //           dispatch(setCompanyName(item.meta_value))
  //           break;
  //     case "conversation-backgroundimage":
  //       backgroundlogo = item.meta_value;
  //       dispatch(setBackgroundlogo(item.meta_value))
  //       break;
  //     default:
  //       break;
  //   }
  // });

  return (
    <div>
      <Navbar
        className={
          tag_id === "all"
            ? "nav-height shadow desktopnav"
            : "nav-height shadow desktopnav sdale-nav"
        }
        collapseOnSelect
        expand="lg"
      >
        <div>
          <Icon
            className="menu-icon"
            icon="oi:menu"
            onClick={() => setToggled(!toggled)}
          />
        </div>
        <Navbar.Brand className="navImage">
          <div className="d-flex justify-content-around">
            <img
              src={navLogo}
              alt="nav-logo "
              className="navlogo cursor-pointer"
              onClick={() => {
                navigateFn("/conversation");
              }}
            />
            <div class="vl"></div>
            {customercompanyLogo ? (
              <>
                <img
                  src={customercompanyLogo}
                  alt="nav-logo "
                  className="navlogo cursor-pointer"
                  style={{ padding: "6px" }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          {/* <span className="logo-peta">beta</span> */}
          {/* <Icon className="menu-icon-user" icon="uil:user" /> */}
        </Navbar.Brand>
        <NavDropdown
          title={<img src={""} alt="" className="chat-avator-img" />}
          id="basic-nav-dropdown"
          show={showDropdown}
          onToggle={handleDropdownToggle}
          className="navbar-user-dropdown"
        >
          <NavDropdown.Item onClick={handleInputClick}>
            <MentionInputField />
          </NavDropdown.Item>
          <NavDropdown.Item>
            <FaUser /> &nbsp; {user_details}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={() => logout()}>
            <span>
              <FaSignOutAlt /> &nbsp; Logout
            </span>
          </NavDropdown.Item>
        </NavDropdown>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <div></div>
          </Nav>

          <Nav>
            <Nav.Link>
              <MentionInputField />
            </Nav.Link>
            &nbsp;&nbsp;
            {/* <Nav.Link>
              <NavDropdown
                title={
                  <div>
                    <Icon
                      icon="ic:round-notifications"
                      className="nav-notification-bell"
                    />
                  </div>
                }
                className="nav-dropdown"
              >
                <NavDropdown.Item>
                  <Icon icon="icons8:share" className="share-icon" /> &nbsp;
                  Share
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Link> */}
            {/* <Nav.Link>
              {tag_id === "all" ? (
                <img src={ceoimg} alt="" className="chat-avator-img" />
              ) : (
                <Icon
                  icon="healthicons:ui-user-profile"
                  className="chat-avator-img"
                  style={{ color: "MainStyle.$theme-font-primary" }}
                />
              )}
            </Nav.Link> */}
            <Nav.Link>
              <NavDropdown
                title={
                  <div>
                    {/* <Icon
                      icon="healthicons:ui-user-profile"
                      className="chat-avator-img"
                      style={{ color: "MainStyle.$theme-font-primary" }}
                    /> */}
                    {customerLogo && customerFirstName ? (
                      <div>
                        <img
                          src={customerLogo}
                          alt=""
                          className="chat-avatar-img"
                        />
                      </div>
                    ) : (
                      <>
                        {customerFirstName ? (
                          <>
                            <Avatar>
                              {customerFirstName.charAt(0).toUpperCase()}
                            </Avatar>
                          </>
                        ) : (
                          <Avatar className="chat-avator-img">
                            {costomer_email.charAt(0).toUpperCase()}
                          </Avatar>
                        )}
                      </>
                    )}

                    {/* {tag_id === "all" ? (
                      <img src={ceoimg} alt="" className="chat-avator-img" />
                    ) : (
                      <Icon
                        icon="healthicons:ui-user-profile"
                        className="chat-avator-img"
                        style={{ color: "MainStyle.$theme-font-primary" }}
                      />
                    )} */}
                  </div>
                }
                id="basic-nav-dropdown"
                // className={{ position: "relative", right: "2.5rem" }}
                className="nav-dropdown"
                drop="right"
              >
                <NavDropdown.Item>
                  <FaUser /> &nbsp;{" "}
                  {customerFirstName ? (
                    <>{customerFirstName}</>
                  ) : (
                    <>{costomer_email}</>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Icon
                    className="user-icon"
                    icon="mingcute:user-question-fill"
                  />{" "}
                  &nbsp; {offer ? capitalizeWords(offer) : "User"}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {/* {offerstatus && ( */}
                <>
                  <NavDropdown.Item onClick={() => custom()}>
                    <span>
                      <Icon icon="ci:settings" /> &nbsp; Account Settings
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </>
                {/* )} */}

                <NavDropdown.Item onClick={() => logout()}>
                  <span>
                    <FaSignOutAlt /> &nbsp; Logout
                  </span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Link>
            {/* <Nav.Link>
              <NavDropdown
                title={
                  <div>
                    <Icon icon="ep:arrow-down" className="nav-drop-down-icon" />

                  </div>
                }
                id="basic-nav-dropdown"
                // className={{ position: "relative", right: "2.5rem" }}
                className="nav-dropdown"
              >
                <NavDropdown.Item>
                  <FaUser /> &nbsp; demo
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => logout()}>
                  <span>
                    <FaSignOutAlt /> &nbsp; Logout
                  </span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Sidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="all"
        className="costimize-sidebar sidebar-mobile"
      >
        <Menu>
          <MenuItem
            className={sidebarmenu === 1 && "active"}
            component={<Link to="/avatarhub/enterprise" />}
          >
            <Icon icon="gg:profile" id="library" className="DashboardNavicon" />
            {/* <Icon
              icon="mdi:library-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            {/* <span className="sidebar-content">ThoughtHub</span> */}
            <span className="sidebar-content">Avatar Hub</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 2 && "active"}
            component={<Link to="/dashboard" />}
          >
            {/* <Icon
              icon="ri:dashboard-line"
              id="dashboard"
              className="DashboardNavicon"
            /> */}
            <IoIosKeypad className="DashboardNavicon" />
            <span className="sidebar-content">Dashboard</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 3 && "active"}
            component={<Link to="/recent_prompts" />}
          >
            {/* <Icon
              icon="material-symbols:recent-actors-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            {/* <Icon
              icon="ooui:recent-changes-ltr"
              id="library"
              className="DashboardNavicon"
            /> */}
            <IoShuffleOutline className="DashboardNavicon" />

            <span className="sidebar-content">Recent Prompts</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 4 && "active"}
            component={<Link to="/history" />}
          >
            {/* <Icon
              icon="mdi:library-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            {/* <Icon
              icon="material-symbols:history"
              className="DashboardNavicon"
            /> */}
            <IoIosTimer className="DashboardNavicon" />
            <span className="sidebar-content">History</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 5 && "active"}
            component={<Link to="/favorites" />}
          >
            {/* <Icon
              icon="mdi:library-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            <Icon icon="uil:favorite" className="DashboardNavicon" />
            <span className="sidebar-content">Favorites</span>
          </MenuItem>
        </Menu>
        <div className="sidebar-footer-body">
          <div className="sidebar-footer">
            <div className="d-flex justify-content-between">
              <div>AIVolveX Credits </div>
              <div>
                <img src={aivolvex} alt="logo" className="token-footer-logo" />{" "}
                {token}
              </div>
            </div>
          </div>
          <div className="sidebar-footer-version">V 0.8.1</div>
        </div>
      </Sidebar>
    </div>
  );
}

export default NavBar;
