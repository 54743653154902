import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { publicMonthlyCustCount } from "../../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import preloader from "../../../assets/loader/pre_loader.gif";
import { Col } from "antd";
import MainStyle from "../../../MainStyle.scss";

const Promptandcountchart = () => {
  const fontcolor = MainStyle["theme-font-primary"];

  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const { publicdashdate } = useSelector((state) => state.chat);
  const date = new Date(publicdashdate);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  //
  const chartData = {
    series: [
      {
        name: "Customer",
        type: "area",
        // data: [14, 55, 41, 67, 22, 43, 71, 90],
        data: data,
        color: "#72DDF7",
      },
    ],
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: "#42445f",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        // categories: [
        //   "Nov-17",
        //   "Nov-18",
        //   "Nov-19",
        //   "Nov-20",
        //   "Nov-21",
        //   "Nov-22",
        //   "Nov-23",
        //   "Nov-24",
        // ],

        categories: categories,
        labels: {
          style: {
            fontSize: "7px",
            colors: fontcolor,
          },
        },
        // formatter: (num) => {
        //   const monthNamesShort = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        //   ];

        //   const date = new Date(dashbaorddate);
        //   const day = date.getDate();
        //   const month = monthNamesShort[date.getMonth()];
        //   const current_month_text = `${num}-${month}`;
        //   return current_month_text;
        // },
      },
      yaxis: {
        labels: {
          style: {
            colors: fontcolor,
          },
        },
      },

      plotOptions: {
        area: {
          fillTo: "origin",
          opacity: 1,
        },
      },
      fill: {
        type: "solid",
        // colors: ["#72DDF7", 'transparent'],
        opacity: [0.35, 1],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        markers: {
          size: 12,
          strokeWidth: 0,
        },
        labels: {
          colors: fontcolor,
        },
      },
    },
  };

  const { id } = useParams();
  const dispatch = useDispatch();

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    if (isNaN(dateObject.getTime())) {
      return "Invalid Date";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[dateObject.getMonth()];
    const day = ("0" + dateObject.getDate()).slice(-2);
    const formattedDate = `${month}-${day}`;
    return formattedDate;
  }

  useEffect(() => {
    setPreloaderStatus(true);
    dispatch(
      publicMonthlyCustCount({
        avatar_id: id,
        month: month.toString(),
      })
    )
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        //
        const startDate = new Date(year, month - 1, 1); // month is 0-indexed
        const endDate = new Date(year, month, 0);
        // const options = chartData.options;
        const datesInMonth = [];
        const dataForMonth = [];
        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // month is 0-indexed
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          datesInMonth.push(formatDate(formattedDate));
          dataForMonth.push(value[formattedDate] || 0);
        }
        console.log(datesInMonth)
        setCategories(datesInMonth);
        setData(dataForMonth);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        setPreloaderStatus(false);
      });
  }, [id, month, year]);
  return (
    <div>
      <div className="chatbot-dashboard-card-chart-header">
        Customer Count by Day
      </div>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={300}
          />
        </>
      )}
    </div>
  );
};

export default Promptandcountchart;
