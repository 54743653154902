import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import startSound from "../../../assets/audio/conversaction/start.mp3";
import stopSound from "../../../assets/audio/conversaction/stop.mp3";
import { useSelector } from "react-redux";

const AudioToText = (props) => {
  const { agentmiclanguage } = useSelector((state) => state.chat);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({
    onStart: () => {
      playAudio(startSound);
    },
    onStop: () => {
      playAudio(stopSound);
    },
  });
  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      if (props.micvisiblestatus) {
        props.setMicVisibleStatus(false);
      }
    }
  }, []);
  useEffect(() => {
    props.setMessage(transcript);
  }, [transcript]);

  const playAudio = (audioFile) => {
    const audio = new Audio(audioFile);
    audio.play();
  };
  const startListeningTamil = () => {
    // Set the language to Tamil (ta-IN)
    SpeechRecognition.startListening({ language: agentmiclanguage });
  };
  const toggleListening = () => {
    if (!browserSupportsSpeechRecognition) {
      props.setMessage("Browser doesn't support speech recognition.");
    } else {
      if (listening) {
        SpeechRecognition.stopListening();
      } else {
        startListeningTamil();
      }
    }
  };

  return (
    <>
      <label className="custom-file-upload d-flex justify-content-center align-items-center">
        <Icon
          icon="material-symbols:mic"
          className={`speech-to-text-container ${
            listening ? "recording-icon" : "recording-icon-active"
          }`}
          onClick={toggleListening}
        />
      </label>
    </>
  );
};
export default AudioToText;
