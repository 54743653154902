import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Space,
  Tooltip,
  Row,
  Col,
  Divider,
  Button,
  Pagination,
} from "antd";
import { Icon } from "@iconify/react";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const DocumentViewSource = (props) => {
  //
  const record = props.sourcerecords;
  //
  const s3 = new AWS.S3();
  const [tablerecords, setTableRecords] = useState(props.sourcerecords);
  const navigate = useNavigate();
  const handleCancel = () => {
    //
    props.setOpen(false);
  };
  //   useEffect(() => {
  //
  //   }, [props]);
  const maxLines = 10;

  const style = {
    maxHeight: `${maxLines * 1.3}em`,
    overflow: "hidden",
  };

  const columns = [
    {
      title: "Document Name",
      dataIndex: "docuemnt_name",
      key: "docuemnt_name",
      width: "20%",
      render: (text, index) => {
        return (
          <>
            {text.length > 50 ? (
              <Tooltip placement="bottom" title={text}>
                <p
                  onClick={() => {
                    window.open(index.presigned_url, "_blank");
                  }}
                  className="cursor-pointer"
                >
                  {text.substring(0, 50)}...
                </p>
              </Tooltip>
            ) : (
              <p
                onClick={() => {
                  window.open(index.presigned_url, "_blank");
                }}
                className="cursor-pointer"
              >
                {text}
              </p>
            )}
          </>
        );
        //
        //
        // <Space size="middle">
        //   <a>
        //     <Icon
        //       icon="fa-solid:file-pdf"
        //       style={{ fontSize: "25px", color: "#ad0b00" }}
        //   onClick={() => {
        //     window.open(text, "_blank");
        //   }}
        //     />
        //   </a>
        // </Space>
      },
      // ...getColumnSearchProps("pod"),
    },
    {
      title: "Page No",
      dataIndex: "page_no",
      key: "page_no",
      width: "10%",
      // ...getColumnSearchProps("avatar"),
    },
    {
      title: "Location",
      dataIndex: "answer",
      key: "answer",
      width: "60%",
      render: (text, index) => {
        return (
          <>
            {text.length > 600 ? (
              <Tooltip placement="bottom" title={text}>
                <p
                  onClick={() => {
                    window.open(index.presigned_url, "_blank");
                  }}
                  className="cursor-pointer"
                >
                  {text.substring(0, 600)}...
                </p>
              </Tooltip>
            ) : (
              <p
                onClick={() => {
                  window.open(index.presigned_url, "_blank");
                }}
                className="cursor-pointer"
              >
                {text}
              </p>
            )}
          </>
        );
      },
      // ...getColumnSearchProps("pod"),
    },

    {
      title: "Actions",
      dataIndex: "presigned_url",
      key: "presigned_url",
      width: "10%",
      align: "center",
      render: (text) => (
        <Space size="middle">
          <a>
            <Icon
              icon="vscode-icons:file-type-pdf2"
              style={{ fontSize: "25px" }}
              onClick={() => {
                window.open(text, "_blank");
              }}
            />
            {/* <Icon
              icon="fa-solid:file-pdf"
              style={{ fontSize: "25px", color: "#ad0b00" }}
              onClick={() => {
                window.open(text, "_blank");  
              }}
            /> */}
          </a>
        </Space>
      ),
    },
  ];

  const paginationConfig = {
    pageSize: 4, // Set the page size (limit) here
  };

  // const getImageURL = (url) => {
  //   const params = {
  //     Bucket: "pgvector-documents",
  //     Key: "finvizor-bank/Electric Power & Gas Sector FY23 Outlook.pdf",
  //     Expires: 3600,
  //   };

  //   const url = s3.getSignedUrl("getObject", params);
  //   return fetchPdfUrl(url);
  // };

  // const fetchPdfUrl = async (url) => {
  //   try {
  //     const response = await fetch(url);
  //     if (response.ok) {
  //       const url = await response.text();
  //       return url;
  //     } else {
  //       console.error("Error fetching PDF URL");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching PDF URL:", error);
  //   }
  // };

  return (
    <>
      <Modal
        title="Source document"
        open={props.open}
        onCancel={handleCancel}
        footer={false}
        width="70%"
        className="recent-prompts-table"
      >
        <Row className="view-source">
          {record.map((data) => (
            <div key={data.id}>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  xl={6}
                  // span={6}
                  className="view-header-txt view-body-txt"
                >
                  {data.docuemnt_name}
                  <Row>
                    <Col>
                      <Button
                        className="his-button"
                        onClick={() => {
                          window.open(data.presigned_url, "_blank");
                        }}
                      >
                        <Icon
                          icon="vscode-icons:file-type-pdf2"
                          style={{ fontSize: "25px" }}
                        />
                        <span className="view-txt">View PDF</span>
                      </Button>
                    </Col>
                    <Col className="view-txt view-body-txt">
                      Page No.{data.page_no}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={18}
                  lg={18}
                  xl={18}
                  // span={18}
                  className="view-body-txt view-location"
                >
                  <Row className="view-txt view-txt-header-right">Location</Row>
                  <Row>
                    <Col style={style}>{data.answer}</Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
            </div>
          ))}
        </Row>

        {/* <Table
          // className="mt-3 ms-2 source-bootstrap-table"
          className="mt-3 ms-2 costimize-bootstrap-table"
          dataSource={tablerecords}
          columns={columns}
          pagination={paginationConfig}
        /> */}
      </Modal>
    </>
  );
};

export default DocumentViewSource;
