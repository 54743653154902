import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { publicSentiment } from "../../api/accountAction";
import { Col } from "antd";
import preloader from "../../../assets/loader/pre_loader.gif";
const SentimentPioChart = () => {
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [chartData, setChartData] = useState({
    series: [0, 0, 0],
    options: {
      chart: {
        width: 450,
        type: "pie",
      },
      stroke: {
        show: false, // Set to false to hide line strokes
      },
      legend: {
        show: true,
        labels: {
          useSeriesColors: true,
        },
      },
      plotOptions: {
        pie: {
          //   startAngle: 0,
          //   endAngle: 360,
          //   expandOnClick: true,
          //   offsetX: 0,
          //   offsetY: 0,
          //   customScale: 1,
          //   dataLabels: {
          //     offset: 0,
          //     minAngleToShowLabel: 10,
          //   },
        },
      },
      colors: ["#2FB14C", "#F7941E", "#FF2441"],
      labels: ["Positive", "Neutral", "Negative"],
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
    },
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const { publicdashdate } = useSelector((state) => state.chat);
  const date = new Date(publicdashdate);
  const month = date.getMonth() + 1;

  useEffect(() => {
    setPreloaderStatus(true);
    dispatch(
      publicSentiment({
        avatar_id: id,
        month: month.toString(),
      })
    )
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const options = chartData.options;
        setChartData({
          series: [
            value.positive_count || 0,
            value.neutral_count || 0,
            value.negative_count || 0,
          ],
          options: options,
        });
        setPreloaderStatus(false);
      })
      .catch((err) => {
        setPreloaderStatus(false);
      });
  }, [id, month]);
  return (
    <div>
      <div className="chatbot-dashboard-card-chart-header">
        Sentiment (Month)
      </div>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="pie"
            height={450}
          />
        </>
      )}
    </div>
  );
};
export default SentimentPioChart;
