import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input, Alert } from "antd";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import aivolvex from "../../assets/ui/X Icon SVG.svg";
import aivolvexlogo from "../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import ForgotPassword from "./ForgotPassword";

const SignIn = ({
  handleSignUpClick,
  emailidVerification,
  onFinishAzureAd,
  email,
  setEmail,
  password,
  setPassword,
  setIsSubmitting,
  isSubmitting,
  azureerrorstatus,
  setAzureErrorStatus,
  onFinishForgotPasswordSubmit,
  validatePassword,
  onFinishForgotPasswordOTP,
  forgotemail,
  setForgotEmail,
  setOpenForgotPassword,
  openforgotpassword,
  formforgotstatus,
  setFormForgotStatus,
  azureaderrormessage,
  setFormForgotChangeStatus,
  formforgotchangestatus,
}) => {
  const navigateFn = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const hanldesignup = () => {
    navigateFn("./signup");
  };




  return (
    <>
      <div className="main-container">
        <div className="login-padding mobileView">
          <div className="text-center"></div>
          <div className="mobileHeader">
            <div className="mt-2 sign-in-subheader mobile-logo">
              <img
                src={aivolvexlogo}
                alt="logo"
                className="mobile_signin_logo"
              />
            </div>
            <div className="mt-2 sign-in-subheader desktop-logo">
              <img src={aivolvex} alt="logo" className="signin_logo" />
            </div>
            <div className="mt-1">
              <p className="signin-header aivolvex-font"> Let's Get Started </p>
            </div>
          </div>
          <div className="mt-4">
            <Button
              type="button"
              htmlType="submit"
              className="me-1 plat-login-azure-btn"
              onClick={() => setOpen(true)}
            >
              <Icon
                icon="logos:microsoft-icon"
                style={{ marginRight: "4px" }}
              />{" "}
              Microsoft
            </Button>
          </div>
          <div className="mt-4"></div>
          <p className="separator-line pt-3 mt-3">
            <span className="text-dark">OR</span>
          </p>
          <div className="d-lg-flex w-100 mt-3 socials-div-mobile">
            <div className="linkedInButtonDiv margin-right1 linkedInButtonDiv-mobile"></div>
          </div>
          <div>
            <div className="mb-3 mt-2 pt-2">
              <label className="pb-1 signIn-font"> Email ID</label>
              <input
                type="text"
                name="email"
                className="form-control signin"
                placeholder="Enter Mail ID"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label className="pb-1 signIn-font">Password</label>
              <div className="d-flex">
                <input
                  type="password"
                  name="password"
                  className="form-control signin border-0"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="custom-control custom-checkbox d-flex">
                <div className="d-flex-remember-w"></div>
                <div className="forgotPasswordMobileView w-50">
                  <p
                    className="forgot-password text-right float-end cursor-pointer signIn-font-remeber aivolvex-font"
                    onClick={() => {
                      setOpenForgotPassword(true);
                      setFormForgotStatus(true);
                      const records = {
                        email: "",
                      };
                      form.setFieldsValue(records);
                    }}
                  >
                    Forgot password?
                  </p>
                </div>
              </div>
            </div>
            <div className="d-grid pt-2">
              <Button
                type="submit"
                className="button-primary btn btn-primary-new fontSize10"
                loading={isSubmitting}
                onClick={emailidVerification}
              >
                SIGN IN
              </Button>



            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Login With Microsoft"
        open={open}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        {azureerrorstatus && (
          <Alert message={azureaderrormessage} type="error" closable />
        )}

        <Form onFinish={onFinishAzureAd} className="mt-5" form={form}>
          <Form.Item
            name="email"
            label="Email"
            className="lable-white"
            rules={[
              { required: true, message: "Please enter your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              className="plat-login-button"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* forgot password */}
      <ForgotPassword
        openforgotpassword={openforgotpassword}
        setOpenForgotPassword={setOpenForgotPassword}
        formforgotstatus={formforgotstatus}
        onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
        validatePassword={validatePassword}
        isSubmitting={isSubmitting}
        onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
        setFormForgotChangeStatus={setFormForgotChangeStatus}
        formforgotchangestatus={formforgotchangestatus}
      />
    </>
  );
};

export default SignIn;
