import { getPageination, getRecentPrompts } from "../../api/accountAction";
export const getdatacount = async (dispatch, input, setCount) => {
  try {
    const response = await dispatch(getPageination(input)).unwrap();
    const value = JSON.parse(response.data.aivolvex_control_plane)[0];
    setCount(value);
  } catch (error) {
    console.error(error);
  }
};

export const getdatarecord = async (
  dispatch,
  input,
  setPreloaderStatus,
  setRecord
) => {
  //
  //
  try {
    const response = await dispatch(getRecentPrompts(input)).unwrap();

    const value = JSON.parse(response.data.aivolvex_control_plane);

    setPreloaderStatus(false);
    const table = value.map((datas) => {
      return {
        pod: datas.pod,
        dateandtime: datas.timestamp,
        avatar_name: datas.avatar_name,
        prompt: datas.question,
        token: datas.token_used,
        avatar_id: datas.avatar_id,
        session_id: datas.session_id,
      };
    });

    setRecord(table);
  } catch (error) {
    console.error(error);
  }
};
