import React from "react";
import { useSelector } from "react-redux";

const CustomerSummary = (props) => {
    const { ClientTicketValue } = useSelector((state) => state.chat);
   

    const handleCheckboxChange = (event) => {

        const { value, checked } = event.target;
        props.setCheckedItems((prevState) => {
            if (checked) {
                return [...prevState, value];
            } else {
                return prevState.filter((item) => item !== value);
            }
        });
    };

    const splitValues = (arr) => {
        if (!Array.isArray(arr)) return [];

        return [...new Set(
            arr
                .filter(message => message != null && message !== "")
                .flatMap(message => typeof message === 'string' ? message.split(',') : message)
                .map(item => item.trim())
                .filter(item => item !== "")
        )];
    };

    const processedMessages = splitValues(ClientTicketValue);

    return (
        <div>
            {processedMessages.map((message, index) => (
                <div key={index}>
                    <label>
                        <input
                            type="checkbox"
                            className="styled-checkbox"
                            value={message}
                            checked={props.checkedItems.includes(message)}
                            onChange={handleCheckboxChange}
                        />
                        <span className="checkbox-message">{message}</span>
                    </label>
                </div>
            ))}
        </div>
    );
};

export default CustomerSummary;
