import React, { useState } from "react";
import { Modal, message } from "antd";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});

const HelpKeyModel = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const s3 = new AWS.S3();
  const handleCancel = () => {
    props.setOpen(false);
  };
  // const handleAWSFileUpload = async (params) => {
  //
  //   await s3.upload(params).promise();
  //   message.success("Your file has been successfully uploaded.");
  //   setConfirmLoading(false);
  //   props.setOpen(false);
  // };
  const handleAWSFileUpload = async (params) => {
    //

    try {
      const uploadPromises = params.map(async (param) => {
        await s3.upload(param).promise();
      });
      message.success("Your files have been successfully uploaded.");
      setConfirmLoading(false);
      props.setOpen(false);
    } catch (error) {
      console.error("Error uploading files to S3:", error);
    }
  };

  const handleOk = () => {
    setConfirmLoading(true);
    const params = props.filedetails.map((fileDetail) => ({
      Bucket: "platform-kendra-bucket",
      Key: `AIVolveXTitan/${props.sessionid}/${fileDetail.file.name}`,
      Body: fileDetail.file.originFileObj,
    }));
    handleAWSFileUpload(params);
  };
  return (
    <>
      <Modal
        title=""
        open={props.open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to upload this file?</p>
      </Modal>
    </>
  );
};

export default HelpKeyModel;
