import { Dropdown, Row, Col, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { azureAdEmailCheck, getUserController } from "../../api/accountAction";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import aivolvexdemo from "../../../assets/ui/testlogo.jpg";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { getUserControllerlist, getAzureemail } from "../Auth/HeaderAuth";
import { capitalizeWords } from "../../customhook/CustomHoom";
import profile_image from "../../../assets/profile.png";
import { listprofile } from "../../Profile/Auth/Profileauth";
import {
  setUsername,
  setUserlogo,
  setBackgroundlogo,
} from "../../Slice/reducer/chatReducer";

const Navbarmobile = () => {
  const navigateFn = useNavigate();
  const { agentname } = useSelector((state) => state.chat);
  const [offer, setOffer] = useState();
  const dispatch = useDispatch();
  const costomer_email = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const customerLogo = localStorage.getItem("customer_logo");
  const customercompanyLogo = localStorage.getItem("companyimage");
  const customerFirstName = atob(localStorage.getItem("customer_first_name"));

  useEffect(() => {
    const input = {
      company: companyName,
      user_id: atob(
        localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
      ),
    };
    getUserControllerlist(dispatch, input, setOffer);
    // dispatch(getUserController(input))
    //     .unwrap()
    //     .then(({ data }) => {
    //         const records = JSON.parse(data.aivolvex_control_plane);
    //         setOffer(records.offering);
    //     })
    //     .catch((err) => {
    //
    //     });
  }, [companyName]);
  const logout = () => {
    const account_type = atob(
      localStorage.getItem("AIVolveXIdentityServiceProvider.logtype")
    );
    if (account_type === "azure") {
      const email = costomer_email;
      getAzureemail(dispatch, email);
      //   dispatch(azureAdEmailCheck(email))
      //     .unwrap()
      //     .then(({ data }) => {
      //       const records = JSON.parse(data.company_check);
      //       const client_id = records.azureclient_id;
      //       const tenantid = records.tenant_id;
      //       const cognito_client_id = records.cognitoclient_id;
      //       localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      //       localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      //       localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      //       localStorage.removeItem("AIVolveXIdentityServiceProvider.store");
      //
      //         "https://login.microsoftonline.com/" +
      //         tenantid +
      //         "/oauth2/logout?post_logout_redirect_uri=http://localhost:3000/"
      //       );
      //
      //         "https://azureaivolex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
      //         cognito_client_id +
      //         "&logout_uri=http://localhost:3000/"
      //       );

      //       window.location.href =
      //         "https://login.microsoftonline.com/" +
      //         tenantid +
      //         "/oauth2/logout?post_logout_redirect_uri=https://sandbox-azure-aivolvex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
      //         cognito_client_id +
      //         "&redirect_uri=http://localhost:3000/";
      //     })
      //     .catch((err) => {
      //
      //     });
    } else {
      localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      window.location.href = "/";
    }
  };

  const key_id = "profile_info";
  const [recordvalue, setRecordvalue] = useState([]);
  // useEffect(() => {
  //   const input = {
  //     keyid: key_id,
  //     meta_key: ""
  //   };
  //   listprofile(dispatch, input, setRecordvalue);
  // }, [])
  // const key_id_company = "company_info";
  // const [record, setRecord] = useState([]);
  // useEffect(() => {
  //   const input = {
  //     keyid: key_id_company,
  //     meta_key: ""
  //   };
  //   listprofile(dispatch, input, setRecord);
  // }, [])
  // let customerFirstName;
  // let customerLogo;
  // let customercompanyLogo;
  // let backgroundlogo;

  // recordvalue.forEach(item => {
  //   switch (item.meta_key) {
  //     case "customer_first_name":
  //       customerFirstName = item.meta_value;
  //       dispatch(setUsername(item.meta_value));
  //       break;
  //     case "customer_logo":
  //       customerLogo = item.meta_value;
  //       dispatch(setUserlogo(item.meta_value));
  //       break;
  //     default:
  //       break;
  //   }
  // });
  // record.forEach(item => {
  //   switch (item.meta_key) {
  //     case "companylogo":
  //       customercompanyLogo = item.meta_value;
  //       break;
  //     case "conversation-backgroundimage":
  //       backgroundlogo = item.meta_value;
  //       dispatch(setBackgroundlogo(item.meta_value))
  //       break;
  //     default:
  //       break;
  //   }
  // });
  const custom = () => {
    navigateFn("/profile");
  };
  const items = [
    {
      key: "1",
      label: (
        <>
          <span className="d-flex justify-content-start align-items-center mobile-logout-dropdown-font">
            {" "}
            <FaUser /> &nbsp;{" "}
            {customerFirstName ? (
              <>{customerFirstName}</>
            ) : (
              <>{costomer_email}</>
            )}
          </span>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <span className="d-flex justify-content-start align-items-center mobile-logout-dropdown-font">
            <Icon icon="mingcute:user-question-fill" /> &nbsp;{" "}
            {offer ? capitalizeWords(offer) : "User"}
          </span>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <span
            className="d-flex justify-content-start align-items-center mobile-logout-dropdown-font"
            onClick={() => custom()}
          >
            <Icon icon="ci:settings" /> &nbsp; Account Settings
          </span>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          {" "}
          <span
            className="d-flex justify-content-start align-items-center mobile-logout-dropdown-font"
            onClick={() => logout()}
          >
            <FaSignOutAlt /> &nbsp; Logout
          </span>{" "}
        </>
      ),
    },
  ];

  return (
    <>
      <Row className="chat-header-part">
        {/* <Col span={12} className="chat-header-logo-title d-flex justify-content-start">{agentname} </Col> */}
        {/* <Col span={10} className="d-flex justify-content-end"> */}
        <Col span={10}>
          <div className="d-flex justify-content-start">
            <img
              src={navLogo}
              alt="nav-logo "
              className="navlogo cursor-pointer"
            />
          </div>
        </Col>
        <Col span={10}>
          <div className="d-flex justify-content-end">
            {/* <img
              src={aivolvexdemo}
              alt="nav-logo "
              className="navcompanylogo cursor-pointer"
            /> */}
            {customercompanyLogo ? (
              <>
                <img
                  src={customercompanyLogo}
                  alt="nav-logo "
                  className="navcompanylogo cursor-pointer"
                />
              </>
            ) : (
              <>
                <img
                  src={aivolvexdemo}
                  alt="nav-logo "
                  className="navcompanylogo cursor-pointer"
                />
              </>
            )}
          </div>
        </Col>

        <Col
          span={2}
          className="d-flex justify-content-center align-items-center"
        >
          <Dropdown
            className="log-out-dropdown"
            menu={{
              items,
            }}
            placement="bottomRight"
          >
            <div>
              {/* <img src={customerLogo} alt="" className="chat-avatar-img" /> */}
              {customerLogo && customerFirstName ? (
                // <div>
                <img src={customerLogo} alt="" className="chat-avatar-img" />
              ) : (
                // </div>
                <>
                  {customerFirstName ? (
                    <Avatar>{customerFirstName.charAt(0).toUpperCase()}</Avatar>
                  ) : (
                    <Avatar className="chat-avator-img">
                      {costomer_email.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                </>
              )}
            </div>
            {/* <img src={profile_image} alt="" className="chat-avator-img" /> */}
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};
export default Navbarmobile;
