import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
// import ThreewayDesktop from "./Desktop/../ThreewayDesktop";
import LatestThreeWayDesktop from "./LatestThreeWayDesktop";
// import RecordMobileboard from "./Mobile/RecordMobileboard";
const ChatBotMainFile = () => {
  return (
    <>
      <div className="conversation-desktop">
        <LatestThreeWayDesktop />
      </div>
      {/* <div className="conversation-mobile">
        <RecordMobileboard />
      </div> */}
    </>
  );
};
export default ChatBotMainFile;
