import { Col, Row, Button, Drawer, Input, Tooltip, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
import Profilemainmenu from "../Desktop/Profilemainmenu";
const MobileProfile = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Navbarmobile />
        </Col>
      </Row>
      <Row className="mobile-background">
        <Col
          span={24}
          className="white-color aivolvex-font d-flex justify-content-center p-1"
        >
          <h4>Account Settings</h4>
        </Col>
      </Row>
      <Row className="recent-mobile-body mobile-background m-1">
        <Col span={24}>
          <Profilemainmenu />
        </Col>
      </Row>
      <Row className="chat-bottom-section">
        <Col span={24}>
          <SidemenuMobile />
        </Col>
      </Row>
    </>
  );
};
export default MobileProfile;
