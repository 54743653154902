import {
  memo,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Modal, Avatar } from "antd";
import ChatListScroll from "../Desktop/ChatListScroll";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
// import ceoimg from "../../../assets/ceo.png";
// import preloader from "../../../assets/loader/pre_loader.gif";
import MarkdownToJSX from "markdown-to-jsx";
import DocumentViewSource from "../Desktop/DocumentViewSource";
import preloader from "../../../assets/loader/pre_loader.gif";
import { formatCurrentTime } from "../../customhook/CustomHoom";
import Chatsubitems from "../Desktop/Chatsubitems";
import SideScroll from "./Mobilescroll";
import profile_image from "../../../assets/profile.png";
import {
  setLatestavatar,
  setStreamingRecord,
} from "../../Slice/reducer/chatReducer";

// start streaming
const id = process.env.REACT_APP_API_ID;
const region = process.env.REACT_APP_API_REGION;

const host = id + ".appsync-api." + region + ".amazonaws.com";
const url =
  "wss://" + id + ".appsync-realtime-api." + region + ".amazonaws.com";
var streamingstopstatus = false;
// end streaming

const MobileChartBody = forwardRef((props, ref) => {
  // const contentRef = useRef();
  const fontlevel = localStorage.getItem("AIVolvexConversation");
  const chatfont = `${fontlevel}px`;
  const { chatmessage } = useSelector((state) => state.chat);
  const currentTime = formatCurrentTime();
  const [sourcemodel, setSourceModel] = useState(false);
  const [sourcerecords, setSourceRecords] = useState([]);
  const parse = require("html-react-parser");
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { username, userlogo } = useSelector((state) => state.chat);
  const customerLogo = localStorage.getItem("customer_logo");
  const customerFirstName = atob(localStorage.getItem("customer_first_name"));
  const [streamingloader, setStreamingLoader] = useState(false);
  const [streaming_id, setStreamingID] = useState(0);
  const [bodydata, setData] = useState("");
  const [completedstatus, setCompletedStatus] = useState({
    status: false,
    records: [],
  });
  const dispatch = useDispatch();
  function isMarkdownTableFormat(text) {
    const markdownTableRegex = /^\s*\|.*\|\s*$/m;
    return markdownTableRegex.test(text);
  }

  const contentRef = useRef(null);
  // start streaming
  useImperativeHandle(ref, () => ({
    openWebsocket,
    tempStopStreaming,
    setStreamingLoader,
    streamingloader,
  }));

  const openWebsocket = (last_message_id, uniqueid, randomUUID) => {
    console.log("in side");
    setStreamingLoader(true);
    props.setParentState(true);
    setStreamingID(last_message_id);

    const api_header = {
      host: host,
      Authorization: localStorage.getItem("_token"),
    };

    const payload = {};
    const base64_api_header = btoa(JSON.stringify(api_header));
    const base64_payload = btoa(JSON.stringify(payload));
    const appsync_url =
      url + "?header=" + base64_api_header + "&payload=" + base64_payload;

    var socket = new WebSocket(appsync_url, ["graphql-ws"]);

    socket.onopen = (e) => {
      socket.send(JSON.stringify({ type: "connection_init" }));
    };

    socket.onmessage = (e) => {
      const receivedData = JSON.parse(e.data);
      // console.log(receivedData);
      if (receivedData.type === "connection_ack") {
        setData("");
        const query = JSON.stringify({
          query: `subscription MySubscription {
            on_aivolvex_control_plane
          }`,
        });

        const queryStr = JSON.stringify({
          id: randomUUID,
          type: "start",
          payload: {
            data: query,
            extensions: {
              authorization: {
                host: host,
                Authorization: localStorage.getItem("_token"),
              },
            },
          },
        });
        streamingstopstatus = true;
        // setStreamingStopStatus(true);
        socket.send(queryStr);
      } else if (
        receivedData.type === "data" &&
        receivedData.id === randomUUID
      ) {
        const streaming_responce = JSON.parse(
          receivedData.payload.data.on_aivolvex_control_plane
        );
        if (uniqueid === streaming_responce.user_id) {
          const find_id_record = chatmessage.find(
            (item) => item.id === last_message_id
          );
          setCompletedStatus({
            status: false,
            records: streaming_responce,
          });
          if (streaming_responce.stream_status === "completed") {
            socket.close();
            if (streamingstopstatus) {
              setCompletedStatus({
                status: true,
                records: streaming_responce,
              });
            }
          } else {
            if (streamingstopstatus) {
              setData((item) => item + streaming_responce.response);
            } else {
              socket.close();
            }
          }
        }
      }
    };
    return socket;
  };

  const tempStopStreaming = () => {
    streamingstopstatus = false;
    setCompletedStatus({
      status: true,
      records: completedstatus.records,
    });
  };

  useEffect(() => {
    if (completedstatus.status) {
      // setCompletedStatus(false);
      const input = {
        id: streaming_id,
        text: bodydata,
        conversection_id: Number(completedstatus.records.inserted_id),
        message_list: [],
      };
      dispatch(setStreamingRecord(input));
      // setStreamingStopStatus(false);
      setStreamingLoader(false);
      props.setParentState(false);
      streamingstopstatus = false;
      props.setMessageProcessStatus(true);
    }
  }, [completedstatus]);

  // end streaming
  useEffect(() => {
    function findLastAvatarText(dataArray) {
      for (let i = dataArray.length - 1; i >= 0; i--) {
        if (dataArray[i].type === "avatar") {
          dispatch(setLatestavatar(dataArray[i].text));
          return;
        }
      }
    }
    findLastAvatarText(chatmessage);
  }, []);
  const handleClick = () => {
    contentRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  useEffect(() => {
    if (chatmessage.length) {
      contentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [chatmessage]);
  return (
    <>
      {chatmessage.length === 0 && id ? (
        <div className="d-flex justify-content-center align-items-center loader-center">
          <img src={preloader} width="30%" alt="Loading..." />
        </div>
      ) : (
        // <ChatListScroll>
        <>
          {id && (
            <span className="down-arrow-icon" onClick={handleClick}>
              {" "}
              <Icon
                icon="mdi:arrow-down-bold-box-outline"
                className="scroll-icon"
              />
            </span>
          )}

          <div ref={contentRef} className="chat-text-body">
            {chatmessage.map((data, index) => (
              <>
                {data.type === "avatar" ? (
                  <>
                    <Col span={24} className="mt-2 ">
                      <div className="d-flex justify-content-center title-prompt ">
                        <Col>
                          <span className="margin-right-0-5 ">
                            <img
                              src={data.image}
                              alt="avatar-img"
                              className="volvex-chat-avatar-logo title-prompt"
                            />
                          </span>
                        </Col>
                        <Col>
                          <Row>
                            <label className="volvex-new-avatar-mobile">
                              {" "}
                              {data.text}{" "}
                            </label>{" "}
                          </Row>
                          <Row className="d-flex justify-content-center footer-chat-time-title-mobile">
                            {" "}
                            {data.date ? data.date : currentTime}
                          </Row>
                        </Col>
                      </div>
                    </Col>
                  </>
                ) : data.type === "loader" ? (
                  <>
                    <Col span={24} className="mt-4">
                      <div className="d-flex justify-content-between volvex-ans-chat-body">
                        <div className="text-center">
                          <img src={preloader} width="15%" />
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {data.user ? (
                      <>
                        <Col span={24} className="mt-4">
                          <div className="d-flex justify-content-end ">
                            <div className="volvex-question-chat-body-mobile volvex-question-chat ">
                              <div>
                                <pre
                                  className="conversation-responce-pre aivolvex-font pre-text-source"
                                  style={{ fontSize: chatfont }}
                                >
                                  <div
                                    className="volvex-new-font-mobile "
                                    dangerouslySetInnerHTML={{
                                      __html: data.text,
                                    }}
                                  />
                                </pre>
                              </div>
                              <div className="text-right">
                                <label className="footer-chat-time-title">
                                  {data.date}
                                </label>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              {/* <img
                                src={profile_image}
                                alt="avatar-img"
                                className="volvex-chat-avatar-logo"
                              /> */}
                              {customerLogo && customerFirstName ? (
                                <div>
                                  <img
                                    src={customerLogo}
                                    alt="avatar-img"
                                    className="volvex-chat-avatar-logo"
                                  />
                                </div>
                              ) : (
                                <>
                                  {customerFirstName ? (
                                    <Avatar>
                                      {customerFirstName
                                        .charAt(0)
                                        .toUpperCase()}
                                    </Avatar>
                                  ) : (
                                    <img
                                      src={profile_image}
                                      alt="avatar-img"
                                      className="volvex-chat-avatar-logo"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24} className="mt-4">
                          <div className="d-flex justify-content-end">
                            <div className="volvex-chat-avatar-img-left">
                              <img
                                src={data.image}
                                alt="avatar-img"
                                className="volvex-chat-avatar-logo"
                              />
                            </div>
                            {/* start answer */}
                            <div className="d-flex justify-content-between volvex-ans-chat-body-mobile">
                              {/* <div className="volvex-chat-avatar-img-right"></div> */}
                              <div className="volvex-question-chat d-flex justify-content-start">
                                {/* <div className="d-flex justify-content-start"> */}
                                {/* <div className="volvex-chat-avatar-img-right">
                                                            <img
                                                                src={data.image}
                                                                alt="avatar-img"
                                                                className="volvex-chat-avatar-logo"
                                                            />
                                                        </div> */}
                                <div
                                  className="text-source"
                                  style={{ fontSize: chatfont }}
                                >
                                  <pre
                                    className="conversation-responce-pre aivolvex-font message-background"
                                    name={`content-to-convert-${index}m`}
                                  >
                                    {streaming_id == data.id ? (
                                      <>
                                        {parse(bodydata)}
                                        {streamingloader && (
                                          <div className="blinking_cursor">
                                            _
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>{parse(data.text)}</>
                                    )}
                                  </pre>
                                  {!streamingloader && (
                                    <>
                                      <div className="text-left">
                                        {data.message_list?.length > 0 && (
                                          <>
                                            <div className="d-flex justify-content-between ">
                                              <label className="footer-chat-time-title-mobile pt-2">
                                                {data.date}
                                              </label>
                                              <p
                                                className="cursor-pointer app-secondary-color volvex-view-source-font-mobile"
                                                onClick={() => {
                                                  setSourceModel(true);
                                                  setSourceRecords(
                                                    data.message_list
                                                  );
                                                }}
                                              >
                                                View Source
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* </div> */}
                              </div>
                              <div className="volvex-feedback-body">
                                {!streamingloader && (
                                  <Chatsubitems
                                    index={index}
                                    data={data}
                                    type={"mobile"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    )}

                    {index < chatmessage.length - 1 &&
                      chatmessage[index + 1].type === "avatar" && (
                        <Col span={24}>
                          <hr className="text-line" />
                        </Col>
                      )}
                  </>
                )}
              </>
            ))}
          </div>
        </>
        // </ChatListScroll>
      )}

      {sourcemodel && (
        <DocumentViewSource
          open={sourcemodel}
          setOpen={setSourceModel}
          sourcerecords={sourcerecords}
        />
      )}
    </>
  );
});
export default MobileChartBody;
