import { memo, useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import PodList from "../Desktop/PodList";

const MobilePodList = (props) => {
  const { agentname } = useSelector((state) => state.chat);
  return (
    <>
      <Row>
        <Col
          span={24}
          className="white-color aivolvex-font d-flex justify-content-center p-1"
        >
          <h4>{agentname}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <PodList />
        </Col>
      </Row>
    </>
  );
};
export default MobilePodList;
