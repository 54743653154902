import React, { useState } from "react";


const TooltipSentiment = ({ children, title }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && <div className="tooltip-title">{title}</div>}
    </div>
  );
};

export default TooltipSentiment;
