import React, { useState, useEffect } from "react";
import { Input } from "antd";

function AgentInputFunction(props) {
  //
  const [initalvalue, setinputvalue] = useState(props.initalvalue);

  const handleInput = (e) => {
    const newValue = e.target.value;
    if (/^[1-9]\d*$/.test(newValue)) {
      setinputvalue(newValue);
      const index = props.index;
      props.handlegetvalue(newValue, index);
    }
  };
  const onChange = (value) => {
    //
  };

  return (
    <Input
      value={initalvalue}
      onChange={handleInput}
      onClick={(value, e) => {
        handleInput(value);
        onChange(value);
      }}
    />
  );
}

export default AgentInputFunction;
