import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { publicTodayChatCount } from "../../api/accountAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainStyle from "../../../MainStyle.scss";
const Conversationchart = () => {
  const fontcolor = MainStyle["theme-font-primary"];
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Type",
        data: [0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: "#42445f",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          columnWidth: "55%",
          endingShape: "rounded",
          borderRadius: 10,
          borderRadiusWhenStacked: "last",
          borderRadiusApplication: "end",
          barHeight: "50%",
        },
      },
      colors: ["#8AC926", "#1FAEEB", "#EB1FD7"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Voice", "Chat", "Mail"],
        labels: {
          style: {
            colors: fontcolor,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: fontcolor,
          },
        },
      },
    },
  });

  const { id } = useParams();
  const dispatch = useDispatch();

  const { publicdashdate } = useSelector((state) => state.chat);
  const date = new Date(publicdashdate);
  const month = date.getMonth() + 1;

  useEffect(() => {
    // setPreloaderStatus(true);
    dispatch(
      publicTodayChatCount({
        avatar_id: id,
        month: month.toString(),
      })
    )
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const options = chartData.options;
        setChartData({
          series: [
            {
              name: "Type",
              data: [
                value.voice_count || 0,
                value.chat_count || 0,
                value.email_count || 0,
              ],
            },
          ],
          options: options,
        });
      })
      .catch((err) => {});
  }, [id, month]);
  return (
    <div>
      <div className="chatbot-dashboard-card-chart-header">
        Today Type of conversation
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={200}
      />
    </div>
  );
};
export default Conversationchart;
