import { Col, Row } from "antd";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
import Recordmainboardmobile from "./Recordmainbodymobile";
import { useDispatch, useSelector } from "react-redux";
const RecordMobileboard = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Navbarmobile />
                </Col>
            </Row>
            <Row className="dashboard-mobile-body">
                <Col span={24}>
                    <Recordmainboardmobile />
                </Col>
            </Row>
            <Row className="chat-bottom-section">
                <Col span={24}>
                    <SidemenuMobile />
                </Col>
            </Row>
        </>
    )
}
export default RecordMobileboard;