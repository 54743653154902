import { Auth } from "@aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signUp = createAsyncThunk(
  // action type string
  "auth/signup",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const { user } = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          name: data.firstname,
          family_name: data.lastname,
        },
      });
      return user;
    } catch (error) {
      if (error.code === "UsernameExistsException") {
        return rejectWithValue(
          "An account with the given email already exists."
        );
      }
    }
  }
);

export const cognitoSignIn = createAsyncThunk(
  // action type string
  "auth/signin",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      //
      const user = await Auth.signIn(data.user_id, data.password);
      //
      return user;
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        return rejectWithValue("Incorrect username or password");
      } else if (e.code === "UserNotConfirmedException") {
        return rejectWithValue("User is not confirmed.");
      } else {
        return rejectWithValue("Incorrect username or password");
      }
    }
  }
);

export const getCorrentSession = createAsyncThunk(
  // action type string
  "auth/session",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const data = await Auth.currentSession();

      //
      return data;
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        return rejectWithValue("Incorrect username or password");
      } else if (e.code === "UserNotConfirmedException") {
        return rejectWithValue("User is not confirmed.");
      } else {
        return rejectWithValue("Incorrect username or password");
      }
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  // action type string
  "auth/verification",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.resendSignUp(data.email);
      //
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const VerificationEamil = createAsyncThunk(
  // action type string
  "auth/verification",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.confirmSignUp(data.email, data.email_otp);
      //
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  // action type string
  "auth/forgotpassword",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.forgotPassword(data.emailid);
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        return rejectWithValue("Invalid Email Address");
      } else {
        return rejectWithValue("Invalid Email Address");
      }
    }
  }
);

export const submitForgotPassword = createAsyncThunk(
  // action type string
  "auth/forgotpassword",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.forgotPasswordSubmit(
        data.emailid,
        data.otpvalue,
        data.newpassword
      );
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);
