import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  avatardetails: [],
  chatmessage: [],
  agentmiclanguage: "en",
  libraryquestion: "",
  chat_session_id: "",
  temp_chat_session_id: "",
  pinnedstatus: false,
  offerstatus: "",
  agentname: "Agent Hub",
  remainingcredits: "",
  chatpreloader: false,
  creditstokenstatus: false,
  dashbaorddate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  publicdashdate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  username: "",
  userlogo: "",
  bgimage: "",
  companyname: "",
  companyimage: "",
  latestavatar: "",
  WebPhoneNumberValue: "",
  AgentAvailable: "",
  WebSocketID: "",
  ConversationID: "",
  Question: "",
  AgentConnectionId: "",
  AcceptCoversationID: "",
  CustomerUserName: "",
  CustomerMobileNumber: "",
  CustomerEmailID: "",
  ClientTicketValue: [],
  PreviousTicketRaise: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    avatarDetails: (state, { payload }) => {
      state.avatardetails = payload;
    },
    setStreamingRecord: (state, { payload }) => {
      const find_id_record = state.chatmessage.find(
        (item) => item.id === payload.id
      );
      if (find_id_record) {
        find_id_record.text = payload.text;
        find_id_record.conversection_id = payload.conversection_id;
        find_id_record.message_list = payload.message_list;
      }
    },
  
    setChatMessage: (state, { payload }) => {
      state.chatmessage = [...state.chatmessage, payload];
    },
    deleteChatMessage: (state) => {
      state.chatmessage = state.chatmessage.slice(0, -1);
    },
    removeHistoryChatMessage: (state) => {
      state.chatmessage = [];
    },
    setLibraryQuestion: (state, { payload }) => {
      state.libraryquestion = payload;
    },
    setChatSessionID: (state, { payload }) => {
      state.chat_session_id = payload;
    },
    setTempChatSessionID: (state, { payload }) => {
      state.temp_chat_session_id = payload;
    },
    setPinnedStatus: (state, { payload }) => {
      state.pinnedstatus = payload;
    },
    setOfferStatus: (state, { payload }) => {
      state.offerstatus = payload;
    },
    setRemaingCredits: (state, { payload }) => {
      state.remainingcredits = payload;
    },
    setChatPreLoader: (state, { payload }) => {
      state.chatpreloader = payload;
    },
    setDashboardDate: (state, { payload }) => {
      state.dashbaorddate = payload;
    },
    setPublicDashDate: (state, { payload }) => {
      state.publicdashdate = payload;
    },
    setCreditsTokenStatus: (state, { payload }) => {
      state.creditstokenstatus = payload;
    },
    setAgentName: (state, { payload }) => {
      state.agentname = payload;
    },
    setAgentMicLanguage: (state, { payload }) => {
      state.agentmiclanguage = payload;
    },
    setUsername: (state, { payload }) => {
      state.username = payload;
    },
    setUserlogo: (state, { payload }) => {
      state.userlogo = payload;
    },
    setCompanylogo: (state, { payload }) => {
      state.companyimage = payload;
    },
    setBackgroundlogo: (state, { payload }) => {
      state.bgimage = payload;
    },
    setCompanyName: (state, { payload }) => {
      state.companyname = payload;
    },
    setLatestavatar: (state, { payload }) => {
      state.latestavatar = payload;
    },
    setWebPhoneNumber: (state, { payload }) => {
      state.WebPhoneNumberValue = payload;
    },
    setAgentAvailable: (state, { payload }) => {
      state.AgentAvailable = payload;
    },
    setWebsocketID: (state, { payload }) => {
      state.WebSocketID = payload;
    },


    setConversationID: (state, { payload }) => {
      state.ConversationID = payload;
    },
    setQuestion: (state, { payload }) => {
      state.Question = payload;
    },
  //   setQuestion: (state, { payload }) => {
  //    state.Question.push(payload);
  // },
    setAgentConnectionId: (state, { payload }) => {
      state.AgentConnectionId = payload;
    },

    setAcceptConversationID : (state ,{payload}) =>{
      state.AcceptCoversationID = payload;
    },
    setCustomerUserName : (state ,{payload}) =>{
      state.CustomerUserName = payload;
    },
    setCustomerMobileNumber : (state ,{payload}) =>{
      state.CustomerMobileNumber = payload;
    },
    setCustomerEmailID : (state ,{payload}) =>{
      state.CustomerEmailID = payload;
    },
    setClientTicketRaise: (state, { payload }) => {
      state.ClientTicketValue.push(payload);
    },
    setPreviousTicketRaise: (state, { payload }) => {
      state.PreviousTicketRaise.push(payload);
    },
   
  },
});

export default chatSlice.reducer;
export const {
  avatarDetails,
  setChatMessage,
  deleteChatMessage,
  setLibraryQuestion,
  setChatSessionID,
  setTempChatSessionID,
  removeHistoryChatMessage,
  setPinnedStatus,
  setOfferStatus,
  setChatPreLoader,
  setDashboardDate,
  setPublicDashDate,
  setCreditsTokenStatus,
  setRemaingCredits,
  setAgentName,
  setAgentMicLanguage,
  setUsername,
  setUserlogo,
  setBackgroundlogo,
  setCompanylogo,
  setCompanyName,
  setLatestavatar,
  setWebPhoneNumber,
  setStreamingRecord,
  setAgentAvailable,
  setWebsocketID,
  setConversationID,
  setQuestion,
  setAgentConnectionId,
  setAcceptConversationID,
  setCustomerUserName,
	setCustomerMobileNumber,
	setCustomerEmailID,
  setClientTicketRaise,
  setPreviousTicketRaise

} = chatSlice.actions;
