import React from "react";
import { Col, Row, Tabs } from "antd";

import PositiveImg from "../../Images/positive.svg";
import NegativeImg from "../../Images/negative.svg";
import NeutralImg from "../../Images/neutral.svg";


const PCAProfile = (props) => {
  const CustomerProfile = (props) => {
 
    return (
      <div className="">
        <div>
          <Row className="pca-df mt-2">
            <Col span={8} className="topic-bold">
              CustomerName
            </Col>
            <Col span={16} className="text-white text-break">
              :&nbsp;{props.user_details["user_name"]}
            </Col>
          </Row>
          <Row className="pca-df mt-2">
            <Col span={8} className="topic-bold">
              Phone Number
            </Col>
            <Col span={16} className="text-white">
              :&nbsp;{props.user_details["mobile_number"]}
            </Col>
          </Row>
          <Row className="pca-df mt-2">
            <Col span={8} className="topic-bold">
              Email
            </Col>
            <Col span={16} className="text-white">
              :&nbsp;{props.user_details["email_id"]}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  const items = [
    {
      key: "1",
      label: "Customer Profile",
      children: <CustomerProfile user_details={props.record_prop} />,
    },
  ];

  return (
    <>
      <div className="aivolvex-dashboard-outline full-width">
        <div className="Dashboard_graph_value Dashboard_head_box_shadow dash-height">
          <h4 className="dashboard-credit aivolvex-font font-size-title">
            Generative AI Insights
          </h4>
          <div className="overall-height">
            <div className="profileheight">
              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Summary:
                </Col>
                <Col span={16} className="text-white d-flex justify-content-center">
               
                
                  <p>:</p><p className="ms-1">{props.record_prop["summary"]}</p>
                </Col>
              </Row>
              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Topic
                </Col>
                <Col span={16} className="text-white">
                  :&nbsp;{props.record_prop["title"]}
                </Col>
              </Row>
              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Product
                </Col>
                <Col span={16} className="text-white">
                  :&nbsp;{props.record_prop["product"]}
                </Col>
              </Row>
              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Opportunity Status
                </Col>
                <Col span={16} className="text-white">
                  :&nbsp;
                  {props.record_prop && props.record_prop["opportunity_status"] ? (
                    props.record_prop["opportunity_status"].toLowerCase() === 'positive' ? (
                      <span className="success-tag">Positive</span>
                    ) : props.record_prop["opportunity_status"].toLowerCase() === 'negative' ? (
                      <span className="issue-tag">Negative</span>
                    ) : (
                      props.record_prop["opportunity_status"]
                    )
                  ) : (
                    'N/A'
                  )}
                </Col>
              </Row>

              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Sentiment Status
                </Col>
                <Col span={16} className="text-white">
                  :&nbsp;
                  {props.record_prop && props.record_prop["sentiment_status"] ? (
                    props.record_prop["sentiment_status"].toLowerCase() === 'positive' ? (
                      <span className="success-tag">Positive</span>
                    ) : props.record_prop["sentiment_status"].toLowerCase() === 'negative' ? (
                      <span className="issue-tag">Negative</span>
                    ) : props.record_prop["sentiment_status"].toLowerCase() === 'neutral' ? (
                      <span className="neutral-tag">Neutral</span>
                    ) : (
                      props.record_prop["sentiment_status"]
                    )
                  ) : (
                    'N/A'
                  )}
                </Col>
              </Row>

              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Resolved
                </Col>
                <Col span={16} className="text-white">
                  :&nbsp;{props.record_prop["issue_resolved"]}
                </Col>
              </Row>
              <Row className="pca-df mt-2">
                <Col span={8} className="topic-bold">
                  Connect Back
                </Col>
                <Col span={16} className="text-white">
                  :&nbsp;{props.record_prop["callback"]}
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="1"
              items={items}
              className="pca-call-summary-tab"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PCAProfile;
