import React, { useEffect, useState } from "react";
import { Col, Row, Input, Form, Upload, Button, message } from "antd";
import { insertprofile, listprofile } from "../Auth/Profileauth";
import { useDispatch, useSelector } from "react-redux";
import preloader from "../../../assets/loader/pre_loader.gif";
import { imageChecking } from "../../customhook/CustomHoom";
import AWS from "aws-sdk";
import { multifileuploads3 } from "../../api/accountAction";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: process.env.REACT_APP_AWS_SIGNATURE_VERSION,
});

const validate_image = {
  beforeUpload: (file) => {
    const isPNG =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml";

    const maxSize = 2 * 1024 * 1024;
    const isSizeValid = file.size <= maxSize;

    if (!isPNG) {
      message.error(
        `Oops! It seems you've uploaded an unsupported file type. Please use Please use PNG,JPG or SVG.`
      );
    }

    if (!isSizeValid) {
      message.error(`File size must be less than 5MB.`);
    }
    const return_value = isPNG && isSizeValid ? true : false;
    return return_value || Upload.LIST_IGNORE;
  },
  onChange: (info) => {
    console.log(info.fileList);
  },
};
const Profileinfo = () => {
  const s3 = new AWS.S3();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [custlogo, setcustlogo] = useState("");
  const [oldrecord, setOldrecord] = useState([]);
  const [oldrecordimg, setOldrecordimg] = useState(true);
  const [recordvalue, setRecordvalue] = useState([]);
  const [imgfile, setimagefile] = useState();
  const [profilefilelist, setProfileFileList] = useState([]);
  const [updatedata, setupdatedata] = useState(true);
  const [loaderstatus, setLoaderStatus] = useState(true);
  const [profileoldimage, setProfileOldImage] = useState("");
  const key_id = "profile_info";
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const handleFileUpload = async (file, typeName) => {
    var fileNameWithoutSpaces = file.name.replace(/\s+/g, "-");
    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };
    const base64File = await convertFileToBase64(file);
    setOldrecordimg(false);
    setupdatedata(false);
    setcustlogo(`images/${typeName}/${fileNameWithoutSpaces}`);
    setimagefile(base64File);
  };
  useEffect(() => {
    const input = {
      keyid: key_id,
      meta_key: "",
      user_id: userName,
    };
    listprofile(dispatch, input, setRecordvalue);
    setLoaderStatus(false);
  }, []);
  useEffect(() => {
    var records = {
      customer_fname: "",
      email_id: userName,
      customer_logo: "",
      phone_number: "",
    };
    if (updatedata === true) {
      if (recordvalue.length > 0) {
        if (recordvalue) {
          recordvalue.forEach((item) => {
            switch (item.meta_key) {
              case "customer_first_name":
                records.customer_fname = item.meta_value;
                break;
              case "customer_logo":
                records.customer_logo = item.meta_value;
                break;
              case "email_address":
                records.email_id = userName;

                break;
              case "phone_number":
                records.phone_number = item.meta_value;
                break;
            }
          });
        }
        form.setFieldsValue(records);
      }
    }
    setProfileOldImage(records.customer_logo ? records.customer_logo : "");
  }, [recordvalue]);
  const onFinish = async (values) => {
    setLoaderStatus(true);
    let logourl = "";
    if (custlogo && imgfile) {
      // const params = {
      //   Bucket: "company-info-bucket",
      //   Key: custlogo,
      //   Body: imgfile,
      // };
      logourl = `https://company-info-bucket.s3.amazonaws.com/${custlogo}`;
      const params = {
        bucket_name: "company-info-bucket",
        file_name: custlogo,
        file_content: imgfile,
        content_type: "multipart/form-data",
      };
      try {
        const filegroup = {
          files: params,
        };
        dispatch(multifileuploads3(filegroup))
          .unwrap()
          .then(({ data }) => {
            const value = JSON.parse(data.uploadFile);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Error uploading file to S3:", error);

        return;
      }
    }
    const inputValues = {
      customer_fname: values.customer_fname,
      customerlogo: logourl,
      phone_number: values.phone_number,
    };
    var inputlist = [
      {
        key_id: key_id,
        meta_key: "customer_first_name",
        meta_value: inputValues.customer_fname,
        user_id: userName,
        type: "text",
      },
      {
        key_id: key_id,
        meta_key: "customer_logo",
        meta_value: inputValues.customerlogo,
        user_id: userName,
        type: "image",
      },
      {
        key_id: key_id,
        meta_key: "email_address",
        meta_value: userName,
        user_id: userName,
        type: "text",
      },
      {
        key_id: key_id,
        meta_key: "phone_number",
        meta_value: values.phone_number,
        user_id: userName,
        type: "text",
      },
    ];
    const final_list = [];
    inputlist.map((data) => {
      if (data.meta_value !== "" || data.meta_value === null) {
        final_list.push(data);
      }
    });
    insertprofile(dispatch, final_list);
    setupdatedata(true);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <>
      <Row className="pro-sub-main-body">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row className="pink-color">My Details</Row>
          <Row className="ash-color">
            <h6>Manage Your Profile details</h6>
          </Row>
          {!loaderstatus ? (
            <>
              <Form
                layout="vertical"
                form={form}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: 800,
                }}
              >
                <Form.Item
                  name="customer_fname"
                  label="Your Name"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    { max: 20, message: "Cannot Exceed 20 Characters" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="customer_logo" label="Your Photo">
                  <div className="d-flex justify-content-start">
                    <Upload
                      {...validate_image}
                      // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      name="avatar"
                      listType="picture-card"
                      className="d-flex align-items-center"
                      onPreview={onPreview}
                      maxCount={1}
                      customRequest={({ file, onSuccess, onError }) => {
                        const typeName = "customerlogo";
                        handleFileUpload(file, typeName)
                          .then(() => onSuccess())
                          .catch((error) => {
                            console.error(
                              "Error in custom file upload request:",
                              error
                            );
                            onError(error);
                          });
                      }}
                      onChange={(event) => {
                        setProfileFileList(event.fileList);
                      }}
                    >
                      {imageChecking(
                        oldrecordimg,
                        profilefilelist.length,
                        profileoldimage
                      )}
                    </Upload>
                  </div>
                </Form.Item>
                <Form.Item name="email_id" label="Email Address">
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  name="phone_number"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message:
                        "Oops! It seems like the phone number is incorrect. Please double-check and try again.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update Profile
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <Col span={24}>
                <div className="aivolvex-sub-loader h-18">
                  <img src={preloader} width="20%" />
                </div>
              </Col>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default Profileinfo;
