import { memo, useEffect, useState } from "react";
import DesktopProfile from "./Desktop/DesktopProfile";
import MobileProfile from "./Mobile/MobileProfile";
const Profile = () => {
  return (
    <>
      <div className="conversation-desktop">
        <DesktopProfile />
      </div>
      <div className="conversation-mobile mobile-background">
        <MobileProfile />
      </div>
    </>
  );
};
export default Profile;
