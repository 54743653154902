import React from "react";
import isoLanguages from "iso-639-1";
import { setAgentMicLanguage } from "../../Slice/reducer/chatReducer";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

const LanguageSelector = (props) => {
  const { agentmiclanguage } = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  const handleLanguageChange = (value) => {
    dispatch(setAgentMicLanguage(value));
  };

  const languageOptions = isoLanguages.getAllCodes().map((languageCode) =>
    // <option key={languageCode} value={languageCode}>
    //   {`${isoLanguages.getName(languageCode)}`}
    // </option>
    ({
      value: languageCode,
      label: isoLanguages.getName(languageCode),
    })
  );

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      showSearch
      placeholder="Select a person"
      optionFilterProp="children"
      onChange={handleLanguageChange}
      filterOption={filterOption}
      options={languageOptions}
      defaultValue={agentmiclanguage}
      className={props.subclass}
    />

    // <select
    //   id="language"
    //   value={agentmiclanguage}
    //   onChange={handleLanguageChange}
    // >
    //   {languageOptions}
    // </select>
  );
};

export default LanguageSelector;
