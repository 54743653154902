import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { publicChatType } from "../../api/accountAction";
import preloader from "../../../assets/loader/pre_loader.gif";
import { Col } from "antd";
import MainStyle from "../../../MainStyle.scss";

const ConversationPieChart = () => {
  const fontcolor = MainStyle["theme-font-primary"];
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [chartData, setChartData] = useState({
    series: [44, 55, 13],
    options: {
      chart: {
        width: 450,
        type: "donut",
      },
      stroke: {
        show: false, // Set to false to hide line strokes
      },
      legend: {
        show: true,
        labels: {
          useSeriesColors: true,
        },
      },
      plotOptions: {
        pie: {
          //   startAngle: 0,
          //   endAngle: 360,
          //   expandOnClick: true,
          //   offsetX: 0,
          //   offsetY: 0,
          //   customScale: 1,
          //   dataLabels: {
          //     offset: 0,
          //     minAngleToShowLabel: 10,
          //   },
          donut: {
            size: "65%",
            background: "transparent",
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "16px",
                fontWeight: 400,
                color: fontcolor,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                showAlways: false,
                label: "Total",
                fontSize: "22px",
                fontWeight: 600,
                color: fontcolor,
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      colors: ["#1FEB89", "#6530D8", "#EB1FD7"],
      labels: ["Voice", "Chat", "Mail"],
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
    },
  });

  const { id } = useParams();
  const dispatch = useDispatch();

  const { publicdashdate } = useSelector((state) => state.chat);
  const date = new Date(publicdashdate);
  const month = date.getMonth() + 1;

  useEffect(() => {
    setPreloaderStatus(true);
    dispatch(
      publicChatType({
        avatar_id: id,
        month: month.toString(),
      })
    )
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const options = chartData.options;
        setChartData({
          series: [
            value.voice_count || 0,
            value.chat_count || 0,
            value.email_count || 0,
          ],
          options: options,
        });
        setPreloaderStatus(false);
      })
      .catch((err) => {
        setPreloaderStatus(false);
      });
  }, [id, month]);
  return (
    <div>
      <div className="chatbot-dashboard-card-chart-header">
        Type of Conversation (Month)
      </div>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            height={450}
          />
        </>
      )}
    </div>
  );
};
export default ConversationPieChart;
