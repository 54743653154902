import { memo, useEffect, useState } from "react";
import NavBar from "../../Header/NavBar";
import { Col, Row } from "antd";
import PodList from "./PodList";
import ChatPart from "./ChatPart";
import Sidemenu from "../../Sidemenu/Sidemenu";
import QSearchBar from "../../QSearchBar/QSearchBar";
import { useParams } from "react-router-dom";
import {
  getAvatarDetails,
  getAvatarChatHistory,
} from "../../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import {
  avatarDetails,
  setChatMessage,
  removeHistoryChatMessage,
  setChatSessionID,
  setTempChatSessionID,
  setChatPreLoader,
} from "../../Slice/reducer/chatReducer";
import ChatRightBar from "./ChatRightBar";
import { setCookie, getCookie } from "../../customhook/CustomHoom";

const DesktopConversationBody = () => {
  const newImageUrl = localStorage.getItem("companyloaderimage");

  const { id, conversationId } = useParams();
  const [avatardetailslist, setAvatarDetailsList] = useState([]);
  const [historyavatardetails, setHistoryAvatar] = useState({
    avatar_count: 0,
    history_list: [],
  });
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const { pinnedstatus, chatmessage } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const user_details = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.store")
  );

  useEffect(() => {
    const input = {
      id: id,
      user_id: userName,
    };
    dispatch(setChatPreLoader(true));
    dispatch(getAvatarDetails(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(props.temprendingtest);
        dispatch(avatarDetails(JSON.parse(data.aivolvex_control_plane)[0]));
        var avatar_details = JSON.parse(data.aivolvex_control_plane)[0];

        // console.log(avatar_details);
        // console.log(chatmessage);
        var avatar_chat_details = {
          text: avatar_details.avatar_name || "",
          image: avatar_details.avatar_image || "",
          user: false,
          type: "avatar",
        };
        const conversation_start = getCookie("conversation_start");
        if (chatmessage.length > 0 || conversation_start) {
          const lastRecord = chatmessage[chatmessage.length - 1];
          const lastRecordsAvatarName = lastRecord.text;
          if (lastRecordsAvatarName !== avatar_details.avatar_name) {
            dispatch(setChatMessage(avatar_chat_details));
          }
        } else {
          setCookie("conversation_start", true, 1);
          dispatch(setChatMessage(avatar_chat_details));
        }
        dispatch(setChatPreLoader(false));
      })
      .catch((err) => {});
  }, [id, pinnedstatus]);

  const singleAvatarDetails = (avatar_id) => {
    const input = {
      id: avatar_id,
      user_id: userName,
    };
    dispatch(getAvatarDetails(input))
      .unwrap()
      .then(({ data }) => {
        setAvatarDetailsList((records) => [
          ...records,
          JSON.parse(data.aivolvex_control_plane)[0],
        ]);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (avatardetailslist.length === historyavatardetails.avatar_count) {
      dispatch(removeHistoryChatMessage());
      var last_chat_avatar_id = "";
      historyavatardetails.history_list.map((history_avatar_details) => {
        if (last_chat_avatar_id === history_avatar_details.avatar_id) {
          last_chat_avatar_id = history_avatar_details.avatar_id;
          const filter_records = avatardetailslist.filter(
            (data_check) => data_check.id === history_avatar_details.avatar_id
          );
          //que
          const avatar_chat_details_user = {
            text: history_avatar_details.question,
            user: true,
            date: formatDate(new Date(history_avatar_details.timestamp)),
            type: "message",
          };
          //
          dispatch(setChatMessage(avatar_chat_details_user));
          //ans
          const avatar_chat_details_ans = {
            text: history_avatar_details.answer,
            user: false,
            date: formatDate(new Date(history_avatar_details.timestamp)),
            type: "message",
            source: false,
            image: filter_records[0] ? filter_records[0].avatar_image : " ",
            message_list: history_avatar_details.document.document,
            conversection_id: history_avatar_details.id,
          };
          dispatch(setChatMessage(avatar_chat_details_ans));
        } else {
          last_chat_avatar_id = history_avatar_details.avatar_id;
          const filter_records = avatardetailslist.filter(
            (data_check) => data_check.id === history_avatar_details.avatar_id
          );
          dispatch(
            setTempChatSessionID(history_avatar_details.temp_session_id)
          );
          //avatar
          const avatar_chat_details = {
            text: filter_records[0] ? filter_records[0].avatar_name : "",
            image: filter_records[0] ? filter_records[0].avatar_image : " ",
            user: false,
            type: "avatar",
            date: formatDate(new Date(history_avatar_details.timestamp)),
          };
          //
          dispatch(setChatMessage(avatar_chat_details));
          //que
          const avatar_chat_details_user = {
            text: history_avatar_details.question,
            user: true,
            date: formatDate(new Date(history_avatar_details.timestamp)),
            type: "message",
          };

          dispatch(setChatMessage(avatar_chat_details_user));
          //ans
          const avatar_chat_details_ans = {
            text: history_avatar_details.answer,
            user: false,
            date: formatDate(new Date(history_avatar_details.timestamp)),
            type: "message",
            source: false,
            image: filter_records[0] ? filter_records[0].avatar_image : " ",
            message_list: history_avatar_details.document.document,
            conversection_id: history_avatar_details.id,
          };
          dispatch(setChatMessage(avatar_chat_details_ans));
        }
      });
    }
  }, [avatardetailslist]);

  function formatDate(date) {
    //
    const options = {
      weekday: "short",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat("ist", options);
    const currentDate = new Date();
    const diffInDays = Math.floor((currentDate - date) / (24 * 60 * 60 * 1000));

    if (diffInDays === 0) {
      // If the date is today, display it with time
      return formatter.format(date);
    } else if (diffInDays <= 7) {
      // If the date is within the past week, display the weekday with time
      return formatter.format(date).replace(",", ""); // Remove the comma after the weekday
    } else {
      // If the date is more than a week ago, display just the date
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      const dateFormatter = new Intl.DateTimeFormat("ist", options);
      return dateFormatter.format(date);
    }
  }

  useEffect(() => {
    setAvatarDetailsList([]);
    if (conversationId !== undefined) {
      dispatch(setChatSessionID(conversationId));
      const input = {
        conversationid: conversationId,
        companyname: companyName,
      };
      //
      dispatch(getAvatarChatHistory(input))
        .unwrap()
        .then(({ data }) => {
          const records = JSON.parse(data.aivolvex_control_plane);
          console.log(records);
          if (records.length > 0) {
            var avatar_list = records.map((v) => v.avatar_id);
            avatar_list = new Set([...avatar_list]);
            avatar_list = [...avatar_list];
            var avatar_list_details = [];

            avatar_list.map((data) => {
              avatar_list_details.push(singleAvatarDetails(data));
            });
            setHistoryAvatar({
              avatar_count: avatar_list.length,
              history_list: records,
            });
          }
        })
        .catch((err) => {
          // message.error(err);
        });
    }
  }, [conversationId]);
  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row className="desktop-conversaction-body">
          {/* start sidemenu */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={2}
            xl={2}
            className="body-margin-top body-margin-bottom-mobile"
          >
            <Sidemenu />
          </Col>
          {/* end sidemenu */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={6}
            className="volvex-avatar-list-body"
          >
            <PodList />
          </Col>

          {id ? (
            <>
              {id === "qsearch" ? (
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={16}
                    xl={16}
                    className="volvex-chat-body"
                    style={{ backgroundImage: `url(${newImageUrl})` }}

                    // <div style={{backgroundImage: `url(${img})`}}>Test</div>
                  >
                    <QSearchBar />
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={15}
                    xl={15}
                    className="volvex-chat-body"
                    style={{ backgroundImage: `url(${newImageUrl})` }}

                    // <div style={{backgroundImage: `url(${img})`}}>Test</div>
                  >
                    <ChatPart />
                  </Col>
                  {id !== "qsearch" && (
                    <Col xs={24} sm={24} md={24} lg={1} xl={1}>
                      <ChatRightBar />
                    </Col>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={16}
                xl={16}
                style={{ backgroundImage: `url(${newImageUrl})` }}
                className="volvex-chat-body"
              ></Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
};
export default memo(DesktopConversationBody);
