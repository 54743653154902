import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Col, Row, Button, Drawer, Input, Tooltip, message } from "antd";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import navLogo from "../../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import SidemenuMobile from "../../Sidemenu/SidemenuMobile";
import MobilePodList from "./MobilePodList";
import Mobilebottminfo from "./Mobilebottominfo";
import Mobilebottominfo from "./Mobilebottominfo";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import MobileChatpart from "./MobileChatpart";
import { useNavigate } from "react-router-dom";
import { pinnedAvatar } from "../../api/accountAction";
import { simplifyNumber } from "../../customhook/CustomHoom";
import {
  setChatSessionID,
  setTempChatSessionID,
  removeHistoryChatMessage,
  setRemaingCredits,
  setPinnedStatus,
} from "../../Slice/reducer/chatReducer";
import ChatListScroll from "../Desktop/ChatListScroll";
import { useParams } from "react-router-dom";
import Navbarmobile from "../../Header/Mobile/Navbarmobile";
import { getpinnedavatar } from "../Auth/ConversationAuth";
import LanguageSelector from "../Desktop/LanguageSelector";
const MobileConversationBody = () => {
  const {
    avatardetails,
    libraryquestion,
    chat_session_id,
    temp_chat_session_id,
    pinnedstatus,
  } = useSelector((state) => state.chat);
  const { id } = useParams();
  const [draweropen, setdrawerOpen] = useState(false);
  const [infoname, setInfoname] = useState("");
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const newImageUrl = localStorage.getItem("companyloaderimage");
  const [drawerbottomopen, setdrawerbottomOpen] = useState(false);
  const { remainingcredits, latestavatar } = useSelector((state) => state.chat);
  const [pinnedavatarstatus, setPinnedAvatarStatus] = useState(false);
  const onClose = () => {
    setdrawerOpen(false);
  };
  const onbottomClose = () => {
    setdrawerbottomOpen(false);
  };
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  useEffect(() => {
    if (id) {
      setdrawerOpen(false);
    }
  }, [id]);

  const setPinnedAvatar = () => {
    var pin_status = "pin";
    var pinmessage = "Agent Pinned";
    if (avatardetails.pinned_status === "pinned") {
      pin_status = "unpin";
      pinmessage = "Agent Unpinned";
    }
    const input = {
      avatar_id: id,
      company: companyName,
      user_id: userName,
      pin_status: pin_status,
      type: "private",
    };
    getpinnedavatar(dispatch, input, pinnedstatus, pinmessage);
  };
  const contentRef = useRef(null);
  return (
    <>
      <div className="chat-mobile-main conversation-mobile">
        <Row>
          <Col span={24}>
            <Navbarmobile />
          </Col>
        </Row>
        <Row className="chat-header-sub-part">
          <Col className="chat-icon-button d-flex align-items-center" span={3}>
            <Button
              size="large"
              className="chat-cancel-icon"
              onClick={() => setdrawerOpen(!draweropen)}
            >
              {draweropen ? (
                <Icon icon="octicon:x-16" className="chat-icon-inner-button" />
              ) : (
                <Icon
                  icon="ph:dots-three-bold"
                  className="chat-icon-inner-button"
                />
              )}
            </Button>
          </Col>
          <Col className="chat-header-mobile-text" span={18}>
            {remainingcredits ? (
              <>
                {" "}
                {avatardetails.avatar_name ? (
                  <>
                    <div className="chat-agent-name d-flex justify-content-center">
                      {latestavatar ? latestavatar : avatardetails.avatar_name}
                      <div>
                        <Icon
                          onClick={() => {
                            setPinnedAvatar();
                          }}
                          icon="fluent-mdl2:pinned"
                          className={
                            avatardetails.pinned_status === "pinned"
                              ? "volvex-chat-header-icon cursor-pointer pinned-color"
                              : "volvex-chat-header-icon cursor-pointer"
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="chat-agent-name d-flex justify-content-center">
                      <br /> <br />
                    </div>
                  </>
                )}
                {avatardetails.avatar_name ? (
                  <>
                    <Row>
                      {remainingcredits && (
                        <>
                          <Col
                            className="chat-agent-sub-name d-flex align-items-center justify-content-start px-1"
                            // md={11}
                            // sm={24}
                            span={11}
                          >
                            AIVolvex Credits :{" "}
                            {simplifyNumber(remainingcredits)}
                          </Col>
                          <Col
                            // md={13}
                            // sm={24}
                            span={13}
                            className="d-flex align-items-start justify-content-end px-1"
                          >
                            <label className="white-color">
                              <span>Mic : </span>
                              <LanguageSelector subclass="mic-language" />
                            </label>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                ) : (
                  <>
                    <div className="chat-agent-sub-name ">
                      <br />
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col className="chat-icon-button d-flex align-items-center" span={3}>
            <Button
              size="large"
              className="chat-add-icon"
              onClick={() => {
                dispatch(setChatSessionID(""));
                dispatch(setTempChatSessionID(""));
                dispatch(removeHistoryChatMessage());
                navigateFn("/conversation");
                dispatch(setRemaingCredits(""));
              }}
            >
              <Icon icon="octicon:plus-16" className="chat-icon-inner-button" />
            </Button>
          </Col>
        </Row>

        <Row
          className="chart-part-mobile-body volvex-chat-body-mobile"
          style={{ backgroundImage: `url(${newImageUrl})` }}
        >
          <Col span={24}>
            <MobileChatpart
              setdrawerbottomOpen={setdrawerbottomOpen}
              setInfoname={setInfoname}
              // contentRef={contentRef}
            />
          </Col>
        </Row>
        <Row className="chat-bottom-section">
          <Col span={24}>
            <SidemenuMobile />
          </Col>
        </Row>
        <div className="chat-mobile-pod-drawer conversation-mobile">
          <Drawer
            className="chat-mobile-pod-drawer"
            placement="left"
            onClose={onClose}
            open={draweropen}
          >
            <MobilePodList />
          </Drawer>
        </div>
        <div className="chat-mobile-bottom-draw conversation-mobile">
          <Drawer
            lassName="chat-mobile-bottom-draw  conversation-mobile"
            placement="bottom"
            onClose={onbottomClose}
            open={drawerbottomopen}
          >
            <Mobilebottominfo
              infoname={infoname}
              setdrawerbottomOpen={setdrawerbottomOpen}
            />
          </Drawer>
        </div>
      </div>
    </>
  );
};
export default MobileConversationBody;
