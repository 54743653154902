import { memo, useEffect } from "react";
import LoginBanner from "./LoginBanner";
import "../banners/SignInBanner.scss";
import "./login.scss";
import SignIn from "./SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import SignUp from "./SignUp";
import {
  loginEmailcheck,
  azureAdEmailCheck,
  profilelistdata,AcceptCall,UpdateWebsocket
} from "../../components/api/accountAction";
import MobileSignin from "../Mobile/MobileSignin";
import MobileSignup from "../Mobile/MobileSignup";
import { useDispatch } from "react-redux";
import mobilebanner from "../../assets/mobile-banner.png";
import mobilelogo from "../../assets/mobilelogo.svg";
import { Button, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  cognitoSignIn,
  forgotPassword,
  submitForgotPassword,
  getCorrentSession,
} from "../Amplify/auth-config";
import { listprofile } from "../Profile/Auth/Profileauth";
import {
  setUsername,
  setUserlogo,
  setBackgroundlogo,
  setCompanylogo,
  setCompanyName,
  setWebsocketID
} from "../Slice/reducer/chatReducer";



const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
: "";

const Login = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [recordvalue, setRecordvalue] = useState([]);
  const [record, setRecord] = useState([]);
  const [signinstatus, setSigninstatus] = useState(false);
  const [signupstatus, setSignupstatus] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const [azureerrorstatus, setAzureErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openforgotpassword, setOpenForgotPassword] = useState(false);
  const [forgotemail, setForgotEmail] = useState("");
  const [formforgotstatus, setFormForgotStatus] = useState(true);
  const [formforgotchangestatus, setFormForgotChangeStatus] = useState(false);
  const [azureaderrormessage, setAzureErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMobileSignin, setShowMobileSignin] = useState(false);
  const [profilestatus, setProfilestatus] = useState(false);
  const handleSignUpClick = (e) => {
    if (showSignIn) {
      setShowSignUp(true);
      setShowSignIn(false);
      localStorage.setItem("signUpShow", "signUp");
    } else {
      setShowSignUp(false);
      setShowSignIn(true);
      localStorage.setItem("signUpShow", "");
    }
  };
  const handleSignin = () => {
    // setSigninstatus(true)
    setButtonLoader(false);
    setShowMobileSignin(true);
  };

  const handleSignup = () => {
    navigate("./signup");
    // setSignupstatus(true)
  };
  const onFinishAzureAd = (values) => {
    setIsSubmitting(true);
    //
    let email = values.email;

    dispatch(azureAdEmailCheck(email))
      .unwrap()
      .then(({ data }) => {
        setIsSubmitting(false);
        const records = JSON.parse(data.company_check);
        //
        if (records.error) {
          setAzureErrorStatus(true);
          setAzureErrorMessage(records.error);
        } else {
          //
          const client_id = records.azureclient_id;
          const tenantid = records.tenant_id;
          const cognito_client_id = records.cognitoclient_id;
          //
          //   "https://launcher.myapps.microsoft.com/api/signin/" +
          //     client_id +
          //     "?tenantId=" +
          //     tenantid
          // );
          window.location.href =
            "https://launcher.myapps.microsoft.com/api/signin/" +
            client_id +
            "?tenantId=" +
            tenantid;
        }
      })
      .catch((err) => {
        //
        setIsSubmitting(false);
        setAzureErrorStatus(true);
      });
  };
  const emailidVerification = () => {
    setIsSubmitting(true);
    setButtonLoader(true);
    var input = {
      user_id: email,
      password: password,
    };
    var new_email = email;

    dispatch(cognitoSignIn(input))
      .unwrap()
      .then((signindata) => {
        //
        localStorage.setItem(
          "_token",
          signindata.signInUserSession.accessToken.jwtToken
        );
        dispatch(loginEmailcheck(input))
          .unwrap()
          .then(({ data }) => {
            setIsSubmitting(false);
            const encodedString = btoa(
              JSON.parse(data.aivolvex_control_plane)[0].company_name
            );
            const user_details = JSON.parse(data.aivolvex_control_plane)[0];
            //
            //
            const encodedEmail = btoa(new_email);
            const normalencodedString = btoa("normal");
            const store_user_details = btoa(
              user_details.first_name ? user_details.first_name : new_email
            );

            const input = {
              keyid: "profile_info",
              meta_key: "",
              user_id: new_email,
            };
            //
            dispatch(profilelistdata(input))
              .unwrap()
              .then(({ data }) => {
                const response = JSON.parse(data.aivolvex_control_plane);
                //
                // const encodedValue = btoa(response[0].meta_value);
                // localStorage.setItem("customer_first_name", encodedValue);
                response.forEach((item) => {
                  switch (item.meta_key) {
                    case "customer_first_name":
                      const encodedValue = btoa(item.meta_value);
                      localStorage.setItem("customer_first_name", encodedValue);
                      break;
                    case "customer_logo":
                      localStorage.setItem("customer_logo", item.meta_value);
                      break;
                    default:
                      break;
                  }
                });
                const inputlist = {
                  keyid: "company_info",
                  meta_key: "",
                  user_id: "",
                };
                dispatch(profilelistdata(inputlist))
                  .unwrap()
                  .then(({ data }) => {
                    const response = JSON.parse(data.aivolvex_control_plane);
                    console.log(response);
                    if (response.length > 0) {
                      response.forEach((item) => {
                        switch (item.meta_key) {
                          case "company_logo":
                            localStorage.setItem(
                              "companyimage",
                              item.meta_value
                            );
                            break;
                          case "conversation_backgroundimage":
                            localStorage.setItem(
                              "companyloaderimage",
                              item.meta_value
                            );
                            break;
                          case "conversation_fontsize":
                            //
                            localStorage.setItem(
                              "AIVolvexConversation",
                              item.meta_value
                            );
                            break;
                          default:
                            break;
                        }
                      });
                    }
                    localStorage.setItem(
                      "AIVolveXIdentityServiceProvider.com",
                      encodedString
                    );
                    localStorage.setItem(
                      "AIVolveXIdentityServiceProvider.logtype",
                      normalencodedString
                    );
                    localStorage.setItem(
                      "AIVolveXIdentityServiceProvider.store",
                      store_user_details
                    );
                    localStorage.setItem(
                      "AIVolveXIdentityServiceProvider.auth",
                      encodedEmail
                    );

                    window.location.href = "/conversation";
                    // openWebsocket()
                  })
                  .catch((err) => {});

                const input = {
                  event_type:"accept_agent",
                  email_id: new_email,
                  calltype:"available",
                };
                console.log(input,"inputttt")
                dispatch(AcceptCall(input))
                    .unwrap()
                    .then(({ data }) => {
                    console.log(data,"response for acceee")
                  
                    })
                    .catch((err) => {
                    console.log(err);
                    });
            
              })
              .catch((err) => {});
              
          })
          .catch((err) => {
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        message.error(err);
      });
  };


  const openWebsocket = () => {
    const socket = new WebSocket(
        "wss://wduoawp287.execute-api.ap-south-1.amazonaws.com/production/"
    );

    socket.onopen = (e) => {
        socket.send(JSON.stringify({ type: "connection_init" }));
    };

    socket.onmessage = (e) => {
        const chatmessage = JSON.parse(e.data);

        if (chatmessage && chatmessage.message === "Forbidden") {
            dispatch(setWebsocketID(chatmessage.connectionId))
            InsertConnection(chatmessage.connectionId)            
            console.log(chatmessage.connectionId,"chatmsssg")
            // dispatch(setAgentAvailable(chatmessage.connectionId));
            // HandleRingConnection(receivedData.connectionId);
        } else if (chatmessage) {
            const value = chatmessage;
            console.log(value, "value for messagee")

            return socket;
        }
    };
    socket.onerror = (e) => {
        console.error('WebSocket error:', e);

    };
    socket.onclose = (e) => {
        // console.log('WebSocket connection closed:', e);
        // setCallopen(false);
        //   openWebsocket()
    };
    return socket;
};

const InsertConnection = (connectionIdValue) => {
  const input = {
      event_type:"chat_connection",
      connection_id: connectionIdValue,
      email_id: userName,
  };

  dispatch(UpdateWebsocket(input))
      .unwrap()
      .then(({ data }) => {
      console.log(data,"dataconnectionupdate")
      })
      .catch((err) => {
          console.log(err);
      });
};


  const onFinishForgotPasswordOTP = (val) => {
    const input = {
      emailid: val.email,
    };
    setForgotEmail(val.email);
    dispatch(forgotPassword(input))
      .unwrap()
      .then((data) => {
        setFormForgotStatus(false);
        setOpenForgotPassword(false);
        setFormForgotChangeStatus(true);
        message.success("Verification Code Sent Your Mail ID");
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const validatePassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two passwords do not match!");
    },
  });

  const onFinishForgotPasswordSubmit = (val) => {
    const input = {
      emailid: forgotemail,
      otpvalue: val.verification_code,
      newpassword: val.password,
    };
    dispatch(submitForgotPassword(input))
      .unwrap()
      .then((data) => {
        message.success("Password changed successfully done");
        setOpenForgotPassword(false);
        setFormForgotChangeStatus(false);
        setFormForgotStatus(true);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <>
      <div className="main-container d-flex h-screen">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 signin-banner singinbanner last-screen">
          <LoginBanner />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
          <div className="container signincontainer">
            <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            >
              {showSignIn && (
                <SignIn
                  handleSignUpClick={handleSignUpClick}
                  emailidVerification={emailidVerification}
                  onFinishAzureAd={onFinishAzureAd}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  azureerrorstatus={azureerrorstatus}
                  setAzureErrorStatus={setAzureErrorStatus}
                  onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
                  validatePassword={validatePassword}
                  onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
                  setOpenForgotPassword={setOpenForgotPassword}
                  openforgotpassword={openforgotpassword}
                  formforgotstatus={formforgotstatus}
                  setFormForgotStatus={setFormForgotStatus}
                  azureaderrormessage={azureaderrormessage}
                  setFormForgotChangeStatus={setFormForgotChangeStatus}
                  formforgotchangestatus={formforgotchangestatus}
                />
              )}

              {/* {showSignUp && <SignUp handleSignUpClick={handleSignUpClick} />} */}
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
      {/* For Mobile responsive */}
      <div>
        {/* {showMobileSignin && ( */}
        <MobileSignin
          emailidVerification={emailidVerification}
          onFinishAzureAd={onFinishAzureAd}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          azureerrorstatus={azureerrorstatus}
          setAzureErrorStatus={setAzureErrorStatus}
          onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
          validatePassword={validatePassword}
          onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
          setOpenForgotPassword={setOpenForgotPassword}
          openforgotpassword={openforgotpassword}
          setShowMobileSignin={setShowMobileSignin}
          formforgotstatus={formforgotstatus}
          setFormForgotStatus={setFormForgotStatus}
          setFormForgotChangeStatus={setFormForgotChangeStatus}
          formforgotchangestatus={formforgotchangestatus}
        />
        {/* )} */}
      </div>
    </>
  );
};

export default memo(Login);
