import { Col, Row, Button, Modal, Input, Upload, Dropdown } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import AudioToText from "../../Conversation/Desktop/AudioToText";
import { useParams } from "react-router-dom";
import Chatsubitems from "../../Conversation/Desktop/Chatsubitems";
import data_img from "../../../assets/profile.png";
const Podchatmain = () => {
  const {
    avatardetails,
    libraryquestion,
    chat_session_id,
    temp_chat_session_id,
    pinnedstatus,
  } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const handleDropdownVisibleChange = (visible) => {
    setIsDropdownOpen(visible);
  };
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const items = [
    {
      key: "1",
      label: (
        <>
          <div
          // onClick={() => handleClick('info')}
          >
            <Icon icon="ph:info" style={{ fontSize: "24px" }} />
          </div>
          <div>Info</div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div
          //  onClick={() => handleClick('library')}
          >
            <Icon icon="bi:bookmark" style={{ fontSize: "24px" }} />
          </div>
          <div>Library</div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <div
          // onClick={() => handleClick('trending')}
          >
            <Icon
              icon="streamline:interface-content-fire-lit-flame-torch-trending"
              style={{ fontSize: "24px" }}
            />
          </div>
          <div>Trending</div>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <div
          // onClick={() => handleClick('history')}
          >
            <Icon icon="solar:history-bold" style={{ fontSize: "24px" }} />
          </div>
          <div>History</div>
        </>
      ),
    },

    {
      key: "6",
      label: (
        <>
          {avatardetails.type === "kendra" ||
            avatardetails.type === "image" ||
            avatardetails.type === "pgvector" ? (
            <>
              <div
              // onClick={() => handledocClick('document')}
              >
                <Icon icon="ph:folder-duotone" style={{ fontSize: "24px" }} />
              </div>
              <div>Doc</div>
            </>
          ) : null}
        </>
      ),
    },
  ];
  const handleKeyPress = (event) => {
    if (message.length > 0) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    }
  };
  return (
    <>
      <div className="chat-mobile-public-conversation">
        <Row className="d-flex justify-content-center title-prompt ">
          <Col>
            <span className="margin-right-0-5 ">
              <img
                src={data_img}
                alt="avatar-img"
                className="volvex-chat-avatar-logo title-prompt"
              />
            </span>
          </Col>
          <Col>
            <Row>
              <label className="volvex-new-avatar-mobile"> Health </label>{" "}
            </Row>
            <Row className="d-flex justify-content-center footer-chat-time-title-mobile">
              {" "}
              Tue 2.23 PM
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-2 ">
            <div className="d-flex justify-content-end p-1">
              <div className="d-flex justify-content-end align-items-center">
                <img
                  src={data_img}
                  alt="avatar-img"
                  className="volvex-chat-avatar-logo"
                />
              </div>
              <div className="volvex-question-chat-body-mobile">
                <div>
                  <pre className="conversation-responce-pre aivolvex-font pre-text-source"  >
                    <div className="volvex-new-font-mobile white">
                      What are the key feature of the health insurance policy?
                    </div>
                  </pre>
                </div>
                <div className="text-right">
                  <label className="footer-chat-time-title">Wed 2.11PM</label>
                </div>
              </div>
            </div>
          </Col>
          <Col span={24} className="mt-4">
            <div className="d-flex justify-content-end align-items-center p-1">
              <div className="volvex-chat-avatar-img-left ">
                <img
                  src={data_img}
                  alt="avatar-img"
                  className="volvex-chat-avatar-logo"
                />
              </div>
              <div className="d-flex justify-content-between volvex-ans-chat-body-mobile">
                <div className="volvex-question-chat d-flex justify-content-start">
                  <div className="text-source">
                    <pre
                      className="conversation-responce-pre volvex-new-font-mobile"
                    // name={`content-to-convert-${index}`}
                    >
                      The key features of a health insurance policy based on the
                      provided contents are: It provides reimbursement for
                      expenses incurred during hospitalization or domiciliary
                      hospitalization for illness, disease or accidental injury.
                    </pre>
                    <div className="text-right">
                      <label className="footer-chat-time-title">
                        Wed 2.11PM
                      </label>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                {/* <div className="volvex-feedback-body"> */}
                {/* <Chatsubitems /> */}
                {/* </div> */}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="chat-fixed-bottom-pod d-flex align-items-end chat-mobilebottom-public">
          <Col md={23} sm={21} xs={21}>
            <div className="chat-input-type">
              <Input.TextArea
                value={message}
                onChange={(e) => {
                  // setMessage(e.target.value);
                }}
                autoSize={{ minRows: 1 }}
                className="chat-input-group"
                onKeyPress={handleKeyPress}
              />
              {avatardetails.type === "prometheus" ||
                avatardetails.type === "image" ? (
                <label
                  htmlFor="file-input"
                  className="custom-file-upload d-flex align-items-center"
                >
                  <span
                    className="upload-icon "
                  // onClick={() => setFileModalOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 35 35"
                      fill="none"
                    >
                      <path
                        d="M9.39779 12.8066H12.9398V23.3141C12.9398 24.3802 13.8042 25.2435 14.8692 25.2435H20.1355C21.2004 25.2435 22.0649 24.3803 22.0649 23.3141V12.8066H25.6057C26.1923 12.8066 26.7196 12.4534 26.9452 11.9113C27.1684 11.3715 27.0442 10.7473 26.6298 10.3329L18.5262 2.22831C17.9602 1.66348 17.0433 1.66348 16.4761 2.22831L8.37391 10.3329C7.95937 10.7473 7.83517 11.3704 8.0596 11.9113C8.28502 12.4524 8.81119 12.8066 9.39779 12.8066Z"
                        fill="white"
                      />
                      <path
                        d="M33.2597 16.7449C32.2995 16.7449 31.5206 17.5239 31.5206 18.4851V25.7617C31.5206 27.9415 29.7461 29.7148 27.5662 29.7148H7.43365C5.25376 29.7148 3.48049 27.9415 3.48049 25.7617V18.4851C3.48049 17.5239 2.70036 16.7449 1.74024 16.7449C0.778989 16.7449 0 17.5239 0 18.4852V25.7618C0 29.8606 3.33467 33.1954 7.43365 33.1954H27.5662C31.6652 33.1954 35 29.8607 35 25.7618V18.4852C35 17.5239 34.2209 16.7449 33.2597 16.7449Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </label>
              ) : (
                <></>
              )}
              <AudioToText setMessage={setMessage} />
              <button
                // onClick={() => getStartConversation()}
                className="chat-input-group"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 41 41"
                  fill="none"
                  className="volvex-submit-btn-icon"
                >
                  <path
                    d="M3.84375 35.875L37.1562 20.5L3.84375 5.125V17.0833L27.707 20.5L3.84375 23.9167V35.875Z"
                    fill="white"
                  />
                </svg>
              </button>

              <div> </div>
            </div>
          </Col>
          <Col md={1} sm={3} xs={3} className="chat-bottom-drop px-2">
            {id ? (
              <>
                <Dropdown
                  className="chat-bottom-drop"
                  menu={{
                    items,
                  }}
                  placement="topRight"
                  onVisibleChange={handleDropdownVisibleChange}
                  trigger={["click"]}
                >
                  {isDropdownOpen ? (
                    <Button
                      size="small"
                      className="chat-add-icon-mobile chat-bottom-add chat-bottom-button "
                    >
                      <Icon icon="ph:x" className="chat-icon-inner-button" />
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      className="chat-cancel-icon chat-bottom-add chat-bottom-button "
                    >
                      <Icon
                        icon="octicon:plus-16"
                        className="chat-icon-inner-button"
                      />
                    </Button>
                  )}
                </Dropdown>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  className="chat-cancel-icon chat-bottom-add chat-bottom-button "
                >
                  <Icon
                    icon="octicon:plus-16"
                    className="chat-icon-inner-button"
                  />
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Podchatmain;
