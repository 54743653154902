import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getAvatarHistoryTitle,
  updatehistory,
  deletehistory,
} from "../api/accountAction";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import PromptsAboutScroll from "./PromptsAboutScroll";
import { GrEdit } from "react-icons/gr";
import { IoTrashBin } from "react-icons/io5";
import { BsChatRight } from "react-icons/bs";
import { setSessionId } from "../Slice/slice";
import { Input, Popconfirm } from "antd";
import preloader from "../../assets/loader/pre_loader.gif";

const HistoryPromptsList = (props) => {
  //
  const navigateFn = useNavigate();
  const dispatch = useDispatch();
  const [promptslist, setPromptsList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [update, setUpdate] = useState(false);
  const [editstatus, setEditstatus] = useState(
    Array(promptslist.length).fill(false)
  );
  const [preloaderstatus, setPreloaderStatus] = useState(true);
  // const [editstatus, setEditstatus] = useState([]);
  const contentRef = useRef(null);
  const handleScroll = (scrollAmount) => {
    if (contentRef.current) {
      contentRef.current.scrollTop += scrollAmount;
    }
  };
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const cancel = (e) => {};

  useEffect(() => {
    setPreloaderStatus(true);
    const input = {
      id: props.avatar_id,
      user_id: userName,
      companyname: companyName,
    };
    dispatch(getAvatarHistoryTitle(input))
      .unwrap()
      .then(({ data }) => {
        setPreloaderStatus(false);
        const records = JSON.parse(data.aivolvex_control_plane);
        if (records.length > 0) {
          setPromptsList(records);
        } else {
          setPromptsList([]);
        }
      })
      .catch((err) => {
        // message.error(err);
      });
  }, [props.avatar_id, update]);
  const getPromptsContent = (conversation_id) => {
    //
    navigateFn("/chat/" + props.avatar_id + "/" + conversation_id);
  };

  const [editIndex, setEditIndex] = useState(-1);
  useEffect(() => {
    if (editIndex !== -1) {
      setInputValue(promptslist[editIndex].title);
    }
  }, [editIndex, promptslist]);
  const handleEditClick = (index) => {
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = true;
    setEditstatus(updatedEditStatus);
    setEditIndex(index);
  };

  const handleApplyClick = (index, inputValue, id) => {
    //
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = false;
    setEditstatus(updatedEditStatus);
    setEditIndex(-1);
    const newData = [...promptslist];
    newData[index].title = inputValue;
    const input = {
      user_id: userName,
      company: companyName,
      user_session: id,
      avatar_id: props.avatar_id,
      title: inputValue,
    };

    // setPromptsList(newData);
    dispatch(updatehistory(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setUpdate(true);
      })
      .catch((err) => {
        // message.error(err);
      });
  };
  const handleCancelClick = (index) => {
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = false;
    setEditstatus(updatedEditStatus);
    setEditIndex(-1);
  };
  const handleDeleteClick = (conversationId) => {
    //
    // const updatedPromptsList = promptslist.filter(
    //   (data) => data.conversation_id !== conversationId
    // );
    // setPromptsList(updatedPromptsList);
    const input = {
      user_id: userName,
      company: companyName,
      user_session: conversationId,
    };
    dispatch(deletehistory(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_control_plane);
        setUpdate(true);
      })
      .catch((err) => {
        // message.error(err);
      });
  };
  const filteredPrompts = promptslist.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="aivolvex-font">
        <div className="history-search">
          <Input
            placeholder="Search here"
            prefix={
              <Icon
                icon="material-symbols:search"
                className="lib-search-icon"
              />
            }
            className="lib-history"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <div className="side-scroll-container-prompts">
          {preloaderstatus ? (
            <>
              <div className="aivolvex-sub-loader">
                <img src={preloader} width="20%" />
              </div>
            </>
          ) : (
            <>
              {filteredPrompts.map((data, index) => {
                const currentInputValue =
                  editIndex === index ? inputValue : data.title;
                return (
                  <>
                    <div key={index}>
                      <button
                        class="button-primary-questions  btn btn-primary fontSize10"
                        role="button"
                      >
                        <div className="btn-main-box d-flex justify-content-between">
                          <div
                            className="btn-prompts-content"
                            onClick={() => {
                              getPromptsContent(data.conversation_id);
                            }}
                          >
                            <span>
                              <BsChatRight className="prompts-history-icon" />
                            </span>
                            {editIndex === index ? (
                              <Input
                                value={currentInputValue}
                                bordered={false}
                                className="edit-input-filed"
                                onChange={(e) => setInputValue(e.target.value)}
                              />
                            ) : (
                              <>
                                {data.title?.length > 70 ? (
                                  <>{data.title?.substring(0, 70)}... </>
                                ) : (
                                  <>{data.title}</>
                                )}
                                {/* {currentInputValue ? (
                              <>{currentInputValue}</>
                            ) : (
                              <>
                                {data.title?.length > 70 ? (
                                  <>{data.title?.substring(0, 70)}... </>
                                ) : (
                                  <>{data.title}</>
                                )}
                              </>
                            )} */}
                              </>
                            )}
                          </div>
                          <div className="btn-prompts-token">
                            <span className="d-flex justify-content-between">
                              {editIndex === index ? (
                                <>
                                  <span>
                                    <Icon
                                      icon="charm:tick"
                                      className="prompts-history-icon"
                                      onClick={() =>
                                        handleApplyClick(
                                          index,
                                          inputValue,
                                          data.conversation_id
                                        )
                                      }
                                    />
                                  </span>
                                  <span>
                                    <Icon
                                      icon="ph:x-bold"
                                      className="prompts-history-icon"
                                      onClick={() => handleCancelClick(index)}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span>
                                    <Icon
                                      icon="wpf:edit"
                                      className="prompts-history-icon"
                                      onClick={() => handleEditClick(index)}
                                    />
                                  </span>
                                  <span className="test-popup">
                                    <Popconfirm
                                      title="Are you sure you want to delete this item?"
                                      onConfirm={() =>
                                        handleDeleteClick(data.conversation_id)
                                      }
                                      onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                      className="history-delete-modal"
                                    >
                                      <IoTrashBin className="prompts-history-icon" />
                                    </Popconfirm>
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </button>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HistoryPromptsList;
