import { Col, Row, Space, Table, Button } from "antd";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useRef } from "react";
import Input from "rc-input";
import { SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
import MainStyle from "../../MainStyle.scss";

const FavoritesPrompts = () => {
  const fontcolor = MainStyle["theme-font-primary"];
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //     close,
  //   }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: "block",
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({
  //               closeDropdown: false,
  //             });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close();
  //           }}
  //         >
  //           close
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? "#1677ff" : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: "#ffc069",
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });
  const paginationConfig = {
    pageSize: 5, // Set the page size (limit) here
    // Additional pagination options can be added here, if needed
  };
  const columns = [
    {
      title: "Pod",
      dataIndex: "pod",
      key: "pod",
      width: "20%",
      // ...getColumnSearchProps("pod"),
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      width: "20%",
      // ...getColumnSearchProps("avatar"),
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      key: "Prompt",
      // ...getColumnSearchProps("prompt"),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: "15%",

      render: (text) => (
        <Space size="middle">
          <a>
            <Icon
              icon="gg:link"
              style={{
                fontSize: "25px",
                color: fontcolor,
              }}
            />
          </a>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      pod: "Customer Operation",
      avatar: "Customer Enquiry",
      prompt:
        "What is the estimated project duration for MYSQL to MSSQL Migration?",
      token: 100,
    },
    {
      key: "2",
      pod: "Pre Sales Operation	",
      avatar: "SOW Generator",
      prompt: "Generate me an SOW for CMACGM App Development",
      token: 100,
    },
    {
      key: "3",
      pod: "IT Operations",
      avatar: "CloudShifu",
      prompt: "Get me the cost for the current month",
      token: 100,
    },
    {
      key: "4",
      pod: "IT Operations",
      avatar: "Research Analyst",
      prompt: "Latest updates in AWS RDS Service",
      token: 100,
    },
  ];

  return (
    <>
      <Table
        className="mt-3 ms-2 costimize-bootstrap-table"
        dataSource={data}
        columns={columns}
        pagination={paginationConfig}
      />
    </>
  );
};

export default FavoritesPrompts;
