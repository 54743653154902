import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Card } from "antd";

const GoogleOauth = () => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setToken(tokenResponse);
    },
  }); 
  const [token, setToken] = useState();
  return (
    <div>
      <Button onClick={() => login()}>Sign in with Google 🚀</Button>
      {token && <Card>{token.access_token}</Card>}
    </div>
  );
};

export default GoogleOauth;
